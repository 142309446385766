<template>
	<div
		id="authcodemodal"
		class="
			contactmodal
			modal-dialog
		"
	>
		<div class="modal-content">
			<h2 class="text-center pt-3">Auth Code</h2>
			<div class="modal-body">
				<div class="container-fluid text-center" v-if="loading">
					<div class="loader loader__sm">
						<div
							class="spinner-grow text-success"
							style="width: 2rem; height: 2rem"
							role="status"
						></div>
						<p class="text-primary">Loading...</p>
					</div>
				</div>
				<div class="container-fluid text-center fs-5" v-else>
					<div v-if="message" class="message">
						{{message}}
					</div>
					<div v-else-if="authcode">
						Your auth code: <span class="authcode">{{authcode}}</span>
						<span class="ms-3 success copy" @click="copyAuthCode"><i class="pi pi-file-o"></i>{{copytext}}</span>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
					Close
				</button>
			</div>
		</div>
	</div>
</template>
		
<script>
import DomainsSource from "@/js/backend/domains";
import { Modal } from "bootstrap";
import { createToaster } from "@meforma/vue-toaster";
import { ref, onMounted } from "vue";
export default {
	props: {},

	components: {},

	setup(props, { emit }) {
		name: "AuthCode";

		const toaster = createToaster({ position: "top-left" });
		const theModal = ref();
		const authcode = ref("");
		const message = ref("");
		const loading = ref(false);
		const copytext = ref("Copy");

		onMounted(() => {
			theModal.value = new Modal(document.getElementById("authCodeModal"));
		});

		const getAuthCode = (coid) => {
			message.value = "";
			authcode.value = "";
			loading.value = true;
			DomainsSource.getAuthCode(coid)
				.then((res) => {
					if (!res || !res.data) {
						toaster.error("Cannot get Auth Code: ");
					} 
					else if (res.data.Error) {
						toaster.error(`Error: ${res.data.Error}`);
					} 
					else {
						console.log(res.data)
						if (res.data.authcode && res.data.authcode != "") {
							authcode.value = res.data.authcode;
						} 
						else if (res.data.Message && res.data.Message != "") {
							message.value = res.data.Message;
						} 
						else return [];
					}
				})
				.catch((error) => {
					toaster.error(error, "Error getting Contactlist");
				})
				.finally(() => {
					loading.value = false;
				});
		};

		const copyAuthCode = () => {
			console.log(authcode.value);
			if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
				copytext.value = "Copied";
				setTimeout(function(){
					copytext.value = "Copy"; 
				}, 2000);//wait 2 seconds
				return navigator.clipboard.writeText(authcode.value);
			}
			
			
			// const el = document.createElement("textarea");
			// el.value = authcode.value;
			// document.body.appendChild(el);
			// el.select();
			// var successful = document.execCommand("copy");
			// var msg = successful ? "successful" : "unsuccessful";
			// document.body.removeChild(el);
			// theModal.value.hide();
			return Promise.reject('The Clipboard API is not available.');
		};

		return {
			message,
			authcode,
			loading,
			copytext,
			getAuthCode,
			copyAuthCode,
		};
	},
};
</script>

<style lang="scss">
@import "../../assets/scss/main.scss";
#authcodemodal {
	.modal-content {
		padding: 5px;
	}
	
	.authcode {
		font-weight:bold;
		color:$gray-800;
	}
	
	.copy {
		border:1px solid $color-success;
		color: $color-success;
		border-radius:.2rem;
		padding:.3rem;
		&:hover {
			cursor: pointer;
			color: $color-success-hover;
			border:1px solid $color-success-hover;
		}
	}
	
}
</style>
