// eslint-disable-next-line
import API from "./axios";
export default {
	// eslint-disable-next-line
	getHostingAmount(params) {
		return API.get("/admin/pbn/hosting/amount",{params: params});
	},
	getHostingList(params) {
		return API.get("/admin/pbn/hosting/list",{params: params});
	},
	getHostingTodos(params) {
		return API.get("/admin/pbn/hosting/todos",{params: params});
	},
	getHosting(id) {
		return API.get("/admin/pbn/hosting/single/" + id);
	},
	editHosting(data) {
		return API.post("/admin/pbn/hosting/edit", data);
	},
	addHosting(data) {
		return API.post("/admin/pbn/hosting/add", data);
	},
	deleteHosting(hid) {
		return API.post("/admin/pbn/hosting/delete", {id: hid});
	},
	getServerList() {
		return API.get("/admin/pbn/server/all");
	},
	getServerQueryList(params) {
		return API.get("/admin/pbn/server/list",{params: params});
	},
	getServer(id) {
		return API.get("/admin/pbn/server/single/" + id);
	},
	getServerAnalysis() {
		return API.get("/admin/pbn/server/analysis");
	},
	editServer(data) {
		return API.post("/admin/pbn/server/edit", data);
	},
	addServer(data) {
		return API.post("/admin/pbn/server/add", data);
	},
	deleteServer(mid) {
		return API.post("/admin/pbn/server/delete", {id: mid});
	},
	getIPShortList() {
		return API.get("/admin/pbn/server/iplist");
	},
	getHostShortList() {
		return API.get("/admin/pbn/hosts/shortlist");
	},
	getHostsList() {
		return API.get("/admin/pbn/hosts/list");
	},
	getHost(id) {
		return API.get("/admin/pbn/hosts/single/" + id);
	},
	editHost(data) {
		return API.post("/admin/pbn/hosts/edit", data);
	},
	addHost(data) {
		return API.post("/admin/pbn/hosts/add", data);
	},
	deleteHost(hid) {
		return API.post("/admin/pbn/hosts/delete", {id: hid});
	},
	getDnsList() {
		return API.get("/admin/pbn/dns/list");
	},
	getDnsShortList() {
		return API.get("/admin/pbn/dns/shortlist");
	},
	getDns(id) {
		return API.get("/admin/pbn/dns/single/" + id);
	},
	editDns(data) {
		return API.post("/admin/pbn/dns/edit", data);
	},
	addDns(data) {
		return API.post("/admin/pbn/dns/add", data);
	},
	deleteDns(hid) {
		return API.post("/admin/pbn/dns/delete", {id: hid});
	},
	getClientShortList() {
		return API.get("/admin/pbn/clients/shortlist");
	},
	
};
