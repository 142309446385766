import Dropdown from "primevue/dropdown";
import { createToaster } from "@meforma/vue-toaster";
import Toggle from '@vueform/toggle'
import ContactsSource from "@/js/backend/domains";
import { Calendar, DatePicker } from 'v-calendar';
import { useStore } from "vuex"; //eslint-disable-line no-unused-vars
// import { defineRule } from 'vee-validate';
import useVuelidate from '@vuelidate/core'
import { required, email, integer, helpers } from '@vuelidate/validators'
import { useRouter, useRoute } from 'vue-router'
import * as dayjs from 'dayjs'
import handleModal from "./handleModal.vue"
import countries from "@/js/countries";
import providers from "@/js/providers";
import { ref, reactive, computed, watch, onBeforeMount, onMounted, defineProps } from "vue";
import 'v-calendar/dist/style.css';

export default {
	props: {
		add: String,
	},

	components: {
		Dropdown,
		DatePicker,
		Calendar,
		handleModal,
		Toggle
	},

	setup(props, { emit }) {
		name: "ContactAdd";
		
		const store = useStore()
		const toaster = createToaster({ position: 'top' });
		const router = useRouter()
		const route = useRoute()

		const matrix = {
			CO_CLID:0,
			CO_PROVIDER:0,
			CO_HANDLE: "",
			CO_INTERNAL_ID:"0",
			CO_TYPE:"P",
			CO_FIRSTNAME:"",
			CO_LASTNAME:"",
			CO_COMPANY:"",
			CO_TEL:"",
			CO_FAX:"",
			CO_EMAIL:"",
			CO_STREET:"",
			CO_ZIP:"",
			CO_CITY:"",
			CO_STATE:"",
			CO_COUNTRY:"",
			CO_BIRTHDAY:"",
			CO_AUTH_CODE:"",
			CO_PERSID:"",
			CO_PERS_ISSUED:"",
			CO_VATID:"",
			CO_ISOWNER:1,
			CO_CLIENTBASEACCOUNT: 0
		};

		const dummy = {
			CO_CLID:1,
			CO_PROVIDER:1,
			CO_HANDLE: "",
			CO_INTERNAL_ID:"",
			CO_TYPE:"P",
			CO_FIRSTNAME:"Hans",
			CO_LASTNAME:"Wurst",
			CO_COMPANY:"Grosses Tennis LTD",
			CO_TEL:"+49-255-2556698",
			CO_FAX:"+49-255-2556699",
			CO_EMAIL:"hans@tennisgross.net",
			CO_STREET:"Muellkippe 33",
			CO_ZIP:"12345",
			CO_CITY:"Vergessen",
			CO_STATE:"NDS",
			CO_COUNTRY:"DE",
			CO_BIRTHDAY:"2000-01-01",
			CO_AUTH_CODE:"frev723@xdsw",
			CO_PERSID:"",
			CO_PERS_ISSUED:"",
			CO_VATID:"",
			CO_ISOWNER:1,
			CO_CLIENTBASEACCOUNT: 0
		};

		const types = ref([
			{"NAME": "Person", "ID": "P"},
			{"NAME": "Organisation", "ID": "O"}
		])
		
		const itemid = ref(null)
		const loading = ref(false);
		const item = ref();
		const statuslist = ref([
			{ 'STATUS_TEXT': 'Inactive', 'STATUS_ID': 0 },
			{ 'STATUS_TEXT': 'Active', 'STATUS_ID': 1 },
		]);
		const datePickerAttributes = ref([{ 
			title: "MM YYYY" 
		}]);
		
		const datePickerModelConfig = ref([{
			type: 'string',
			mask: 'YYYY-MM-DD'
		}]);


		const mustLargerThanZero = (value) => value != '0';
		const isOptionalDate = (value) => !helpers.req(value) || /\d{4}-\d{2}-\d{2}/.test(value);
		const isPhoneNumber = (value) => /^\+\d{1,4}-\d{1,5}-\d{3,12}$/.test(value)
		const isFaxNumber = (value) => !helpers.req(value) || /^\+\d{1,4}-\d{1,5}-\d{3,12}$/.test(value)
		const IdCardDate = (value) => !helpers.req(value) || /^\+\d{1,4}-\d{1,5}-\d{3,12}$/.test(value)

		let submitLabel = ref("Add");

		const rules = {
			CO_PROVIDER: { required, integer, mustLargerThanZero },
			CO_FIRSTNAME: { required },
			CO_LASTNAME: { required },
			CO_TYPE: { required },
			CO_TEL: { required, isPhoneNumber: helpers.withMessage("Must be in the format +XX-XXX-XXXXX", isPhoneNumber) },
			CO_FAX: { isFaxNumber: helpers.withMessage("Must be in the format +XX-XXX-XXXXX", isFaxNumber) },
			CO_EMAIL: { required, email },
			CO_CLID: { required, integer, mustLargerThanZero },
			CO_STREET: { required },
			CO_ZIP: { required },
			CO_CITY: { required },
			CO_COUNTRY: { required },
			CO_BIRTHDAY: { isOptionalDate: helpers.withMessage("Must be a valid date", isOptionalDate) },
			CO_PERS_ISSUED: { IdCardDate: helpers.withMessage("Must be a valid date", IdCardDate) },
			CO_CLIENTBASEACCOUNT: { required, integer },	
		}

		const v$ = useVuelidate(rules, item, { $lazy: true })
		
		const clients = computed(() => store.state.accounting.clients);

		onBeforeMount(() => {
			store.dispatch("CLIENTS", true);
			store.dispatch("DOMAINS_DEFAULT_NAMESERVERS", false);
			item.value =JSON.parse(JSON.stringify(matrix));
		})

		const resetData = () => {
			item.value = matrix;
			console.log("itemn has been reset to " , item.value)
			submitLabel.value = "Add"
			v$.value.$reset();
		}
		
		const addContact = async () => {
			console.log("Adding Contact")
			const result = await(v$.value.$validate());
			console.log("Test result: ", result)
			if(result == false) {
				console.log("something is happening")
				console.log(v$.value.$errors);
				return false;
			}
			ContactsSource.addContact(item.value)
				.then((response) => {
					console.log(response.data)
					if (response.data.Error) {
						toaster.error(
							"Could not add Contact: \n<br/>" + response.data.Error
						);
						console.error(response.data.Error);
					} else {
						toaster.success("Contact added");
						if(response.data.CO_HANDLE) {
							item.value.CO_HANDLE = response.data.CO_HANDLE;
							item.value.CO_INTERNAL_ID = response.data.CO_HANDLE;
						}
						if(response.data.CO_ID) {
							item.value.CO_ID = response.data.CO_ID;
						}
						item.value.CO_DISPLAY = (item.value.CO_COMPANY != '' && item.value.CO_COMPANY != '0') ? `${item.value.CO_COMPANY}, ` : "";
						item.value.CO_DISPLAY += `${item.value.CO_FIRSTNAME} ${item.value.CO_LASTNAME}`;
						
						const cldata = clients.value.find( ({ CL_ID }) => CL_ID ===  item.value.CO_CL_ID);
						item.value.CLIENT = cldata.NAME
						
						
						if(item.value.CO_PROVIDER == 3) {
							let newlist = JSON.parse(JSON.stringify(item.value));
							newlist.CO_ISOWNER = (newlist.CO_ISOWNER == 1) ? 0 : 1;
						}
						emit('addToList');
						router.push({
							path: "/domains/contacts/list",
						});
					}
				})
				.catch((error) => {
					toaster.error("Could not add Server ", error);
				});
		}
		
		const setDummy = () => {
			item.value = JSON.parse(JSON.stringify(dummy));
		}
		
		const addToListTest = () => {
			emit('addToList')
			router.push({
				path: "/domains/contacts/list",
			});

		}
		
		return {
			item,
			submitLabel,
			loading,
			clients,
			types,
			statuslist,
			addContact,
			setDummy,
			addToListTest,
			resetData,
			Calendar, 
			DatePicker,
			providers,
			datePickerAttributes,
			datePickerModelConfig,
			countries,
			v$
		}
	}

};
