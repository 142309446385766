// import InvoicesDetails from "./ClientDetails.vue";
import { watch, ref, onBeforeMount } from "vue";
import * as dayjs from 'dayjs'
import vSelect from 'vue-select'

import StatisticsSource from '@/js/backend/statistics';

export default {
	components: {
		vSelect
	},

	setup() {
		const rows = ref(false);
		
		const month = ref("");
		const year = ref("");
		const item = ref("");
		const mm = ref([
			{"label": "all", "value": "all"},
			{"label": "01", "value": "01"},
			{"label": "02", "value": "02"},
			{"label": "03", "value": "03"},
			{"label": "04", "value": "04"},
			{"label": "05", "value": "05"},
			{"label": "06", "value": "06"},
			{"label": "07", "value": "07"},
			{"label": "08", "value": "08"},
			{"label": "09", "value": "09"},
			{"label": "10", "value": "10"},
			{"label": "11", "value": "11"},
			{"label": "12", "value": "12"},
		]);
		const yyyy = ref([]);
		
		onBeforeMount(() => {
			yyyy.value = [
				dayjs().format('YYYY'),
				dayjs().add(1, 'year').format('YYYY'),
				dayjs().add(2, 'year').format('YYYY')
			]
			month.value = {'value': dayjs().add(1, 'month').format('MM')}
			year.value = dayjs().add(1, 'month').format('YYYY')
		});
		
		
		
		function showDetails(month,year,item) {
			StatisticsSource.getBilanceDetails({"month": month.value, "year": year.value, "item": item.value}).then((response) => {
				rows.value = response.data.rows;
			});
		}
		
		// load items is what brings back the rows from server
		function loadBilance() {
			StatisticsSource.getBilance({"month": month.value.label, "year": year.value}).then((response) => {
				rows.value = response.data.rows;
				console.log(rows.value)
			})			
			.catch((error) => {
				console.error(error, "Error setting Transfers");
			})
			.finally(() => {
			});	

		}
		
		function showcurrent() {
			console.log("get now ", month.value)
		}

		return {
			mm,
			yyyy,
			rows,
			month,
			year,
			item,
			showDetails,
			loadBilance,
			showcurrent
		}
		
	}
}

