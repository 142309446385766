<template>
	<div class="pbn">
		<h2 v-if="item.DNS_ID > 0">DNS #{{ item.DNS_ID }}</h2>
		<h2 v-else>Add DNS</h2>
		<div class="container-fluid" v-if="loading">
			<div class="loader">
				<div class="spinner-grow text-success" style="width: 6rem; height: 6rem;" role="status"></div>
				<p class="text-primary">Loading...</p>
			</div>
		</div>
		<div class="container-fluid details" v-else>
			<form @submit.prevent="editDns">
				<div class="row">
					<div class="col col-12">
						<label for="hostname" class="form-label">Name</label>
						<input type="text" class="form-control" id="hostname" v-model="item.DNS_NAME" />
					</div>
				</div>
				<div class="row">
					<div class="col col-9">
						<label for="DNS_ADMIN_URL" class="control-label">Login URL</label>
						<input type="text" class="form-control" name="DNS_ADMIN_URL" id="DNS_ADMIN_URL" v-model="item.DNS_ADMIN_URL" />
					</div>
					<div class="col col-3">
						<p/>
						<a class="btn btn-success" :href="item.DNS_ADMIN_URL" target="new">Login</a>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<label for="DNS_ADMIN_USER" class="control-label">User</label>
						<input type="text" class="form-control" name="DNS_ADMIN_USER" id="DNS_ADMIN_USER" v-model="item.DNS_ADMIN_USER" />
					</div>
					<div class="col col-6">
						<label for="DNS_ADMIN_PWD" class="control-label">Password</label>
						<input type="text" class="form-control" name="DNS_ADMIN_PWD" id="DNS_ADMIN_PWD" v-model="item.DNS_ADMIN_PWD" />
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<label for="DNS_NS1" class="control-label">NS1</label>
						<input type="text" class="form-control" name="DNS_NS1" id="DNS_NS1" v-model="item.DNS_NS1" />
					</div>
					<div class="col col-6">
						<label for="DNS_NS_IP1" class="control-label">NS1 IP</label>
						<input type="text" class="form-control" name="DNS_NS_IP1" id="DNS_NS_IP1" v-model="item.DNS_NS_IP1" />
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<label for="DNS_NS2" class="control-label">NS2</label>
						<input type="text" class="form-control" name="DNS_NS2" id="DNS_NS2" v-model="item.DNS_NS2" />
					</div>
					<div class="col col-6">
						<label for="DNS_NS_IP2" class="control-label">NS2 IP</label>
						<input type="text" class="form-control" name="DNS_NS_IP2" id="DNS_NS_IP2" v-model="item.DNS_NS_IP2" />
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<label for="DNS_NS3" class="control-label">NS3</label>
						<input type="text" class="form-control" name="DNS_NS3" id="DNS_NS3" v-model="item.DNS_NS3" />
					</div>
					<div class="col col-6">
						<label for="DNS_NS_IP3" class="control-label">NS3 IP</label>
						<input type="text" class="form-control" name="DNS_NS_IP3" id="DNS_NS_IP3" v-model="item.DNS_NS_IP3" />
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<label for="DNS_NS4" class="control-label">NS4</label>
						<input type="text" class="form-control" name="DNS_NS4" id="DNS_NS4" v-model="item.DNS_NS4" />
					</div>
					<div class="col col-6">
			<label for="DNS_NS_IP4" class="control-label">NS4 IP</label>
						<input type="text" class="form-control" name="DNS_NS_IP4" id="DNS_NS_IP4" v-model="item.DNS_NS_IP4" />
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<label for="DNS_NS5" class="control-label">NS5</label>
						<input type="text" class="form-control" name="DNS_NS5" id="DNS_NS5" v-model="item.DNS_NS5" />
					</div>
					<div class="col col-6">
						<label for="DNS_NS_IP5" class="control-label">NS5 IP</label>
						<input type="text" class="form-control" name="DNS_NS_IP5" id="DNS_NS_IP5" v-model="item.DNS_NS_IP5" />
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<label for="DNS_API_ACCOUNT" class="control-label">API Account</label>
						<input type="text" class="form-control" name="DNS_API_ACCOUNT" id="DNS_API_ACCOUNT" v-model="item.DNS_API_ACCOUNT" />
					</div>
					<div class="col col-6">
						<label for="DNS_API_ENDPOINT" class="control-label">API Endpoint</label>
						<input type="text" class="form-control" name="DNS_API_ENDPOINT" id="DNS_API_ENDPOINT" v-model="item.DNS_API_ENDPOINT" />
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<label for="DNS_API_USERNAME" class="control-label">API Username</label>
						<input type="text" class="form-control" name="DNS_API_USERNAME" id="DNS_API_USERNAME" v-model="item.DNS_API_USERNAME" />
					</div>
					<div class="col col-6">
						<label for="DNS_API_PWD" class="control-label">API Password</label>
						<input type="text" class="form-control" name="DNS_API_PWD" id="DNS_API_PWD" v-model="item.DNS_API_PWD" />
					</div>
				</div>
				<div class="row">
					<div class="col col-12">
						<label for="DNS_REMARKS" class="control-label">Remarks</label>
						<textarea
							class="form-control"
							name="DNS_REMARKS"
							id="DNS_REMARKS"
							style="height:60px"
							v-model="item.DNS_REMARKS"
						></textarea>
					</div>
				</div>
				<div class="row">
					<div class="col col-4 text-center">
						<input class="btn btn-primary" type="submit" name="su" value="Edit" />
					</div>
					<div class="col col-4 text-center">
						<button type="button" class="btn btn-danger" @click="deleteDns">Delete</button>
					</div>
					<div class="col col-4 text-center" v-if="item.DNS_ID < 1">
						<button type="button" class="btn btn-warning" @click="setDummy">Dummy</button>
					</div>
					<div class="col col-4 text-center" v-else>
							{{ item.DNS_ID }} !!
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import PBNSource from "@/js/backend/pbn";
import { mapState } from "vuex"; //eslint-disable-line no-unused-vars

const matrix = {
	DNS_ID: 0,
	DNS_NAME: '',
	DNS_NS1: '',
	DNS_NS2: '',
	DNS_NS3: '',
	DNS_NS4: '',
	DNS_NS5: '',
	DNS_NS_IP1: '', 
	DNS_NS_IP2: '', 
	DNS_NS_IP3: '', 
	DNS_NS_IP4: '',
	DNS_NS_IP5: '',
	DNS_ADMIN_URL: '', 
	DNS_ADMIN_USER: '', 
	DNS_ADMIN_PWD: '', 
	DNS_API_ACCOUNT: '', 
	DNS_API_USERNAME: '', 
	DNS_API_PWD: '',
	DNS_API_ENDPOINT: '', 
	DNS_REMARKS: ''
};

const dummy = {
	DNS_NAME: 'Dummydns',
	DNS_NS1: 'ns1.dummy.io',
	DNS_NS2: 'ns2.dummy.io',
	DNS_NS3: 'ns3.dummy.io',
	DNS_NS4: '',
	DNS_NS5: '',
	DNS_NS_IP1: '127.0.0.1', 
	DNS_NS_IP2: '127.0.0.2', 
	DNS_NS_IP3: '', 
	DNS_NS_IP4: '',
	DNS_NS_IP5: '',
	DNS_ADMIN_URL: 'https://dummyns.io', 
	DNS_ADMIN_USER: 'idiot', 
	DNS_ADMIN_PWD: 'Passwort!123', 
	DNS_API_ACCOUNT: '', 
	DNS_API_USERNAME: '', 
	DNS_API_PWD: '',
	DNS_API_ENDPOINT: '', 
	DNS_REMARKS: '... Hammer, oder?'
};

export default {
	name: "PBNHostsDetails",
	props: {
		itemid: String,
	},
	components: {
	},
	watch: {
		itemid: {
			immediate: true,
			// eslint-disable-next-line no-unused-vars
			handler(newVal, oldVal) {
				// console.log("new id passed to details ", newVal, " old ", oldVal);
				if (newVal != null && newVal != 0) this.getDetails(newVal);
				else {
					this.item = matrix;
				}
			},
		},
	},
	computed: {
	},

	data() {
		return {
			host: null,
			loading: false,
			item: matrix,
		};
	},

	created() {
	},

	methods: {
		getDetails(id) {
			this.loading = true;
			PBNSource.getDns(id)
				.then((response) => {
					this.item = response.data;
					this.loading = false;
				})
				.catch((error) => {
					this.$toast.error("Could not fetch data ", error);
					this.loading = false;
				});
		},
		
		
		editDns() {
			console.log("SEND ", this.item);

			if (this.item.DNS_ID && this.item.DNS_ID > 0) {
				PBNSource.editDns(this.item)
					.then((response) => {
						console.log(response.data.Error);
						if (response.data.Error) {
							this.$toast.error("Could not edit DNS: \n<br/>" + response.data.Error);
							console.log(response.data.Error);
						} else {
							this.$toast.success("DNS updated");
							this.getDetails(this.item.DNS_ID);
						}
					})
					.catch((error) => {
						this.$toast.error("Could not edit Host ", error);
					});
			}
			
			else {
				PBNSource.addDns(this.item)
					.then((response) => {
						if (response.data.Error) {
							this.$toast.error("Could not add DNS: \n<br/>" + response.data.Error);
							console.log(response.data.Error);
						} else {
							console.log(response.data);
							
							this.$toast.success("DNS added");
							this.$router.push({ path: '/pbn/dns/list?id=' + response.data});
						}
					})
					.catch((error) => {
						this.$toast.error("Could not edit DNS ", error);
					});
			}

			// $dayjs
		},
		
		deleteDns() {
			if(confirm("Are you absolutely sure? The world will be a different place")) {
				PBNSource.deleteDns(this.item.DNS_ID)
					.then((response) => {
						console.log(response.data.Error);
						if (response.data.Error) {
							this.$toast.error("Could not delete DNS: \n<br/>" + response.data.Error);
							console.log(response.data.Error);
						} else {
							this.$toast.success("Host delete");
							this.item = matrix
							this.$router.push({ path: '/pbn/dns/list'});
						}
					})
					.catch((error) => {
						this.$toast.error("Could not delete DNS ", error);
					});
			}
		},
		
		setDummy() {
			this.item = dummy;
		}
	},
};
</script>
