<template>
	<div>
		<vue-good-table
			mode="remote"
			:totalRows="totalRecords"
			:columns="columns"
			:rows="rows"
			:pagination-options="{
				enabled: true,
				mode: 'records',
				perPage: 20,
				position: 'both',
				perPageDropdown: [10, 20, 50],
				dropdownAllowAll: false,
				setCurrentPage: 1,
				nextLabel: 'next',
				prevLabel: 'prev',
				rowsPerPageLabel: 'Rows per page',
				ofLabel: 'of',
				pageLabel: 'page', // for 'pages' mode
				allLabel: 'All',
			}"
			v-on:page-change="onPageChange"
			v-on:sort-change="onSortChange"
			v-on:column-filter="onColumnFilter"
			v-on:per-page-change="onPerPageChange"
			styleClass="vgt-table striped"
		>
			>
			<template #table-row="props">
				<span v-if="props.column.field == 'DOM_CANCEL'">
					<button
						v-if="props.row.DOM_CANCEL == 0" 
						class="mt-3 btn btn-success btn-xs" 
						@click="setDomainCancelStatus(props.row)">
						X
					</button>
					<button
						v-if="props.row.DOM_CANCEL == 1" 
						class="mt-3 btn btn-danger btn-xs" 
						@click="setDomainCancelStatus(props.row)">
						+
					</button>
				</span>
				<span v-if="props.column.field == 'Action'">
					<router-link
						class="btn btn-primary btn-sm"
						:to="'/domains/domains/single/' + props.row.DOM_ID"
					>
						Details
					</router-link>
					<button 
						class="ms-3 btn btn-outline-primary btn-sm" 
						data-bs-toggle="modal"
						data-bs-target="#authCodeModal"
						@click="getAuthcode(props.row.DOM_ID)">
						Auth Code
					</button>
				</span>
				<span v-else>
					{{ props.formattedRow[props.column.field] }}
				</span>
			</template>
		</vue-good-table>
		<div class="modal fade" id="authCodeModal" tabindex="-1" aria-labelledby="authCodeModal" aria-hidden="true">
			<authCodeModal 
				ref="authcodemodalwindow" 
			/>
		</div>
	</div>
</template>

<script>
import { VueGoodTable } from "vue-good-table-next";
import { ref, onBeforeMount } from "vue";
import { createToaster } from "@meforma/vue-toaster";
import DomainsSource from "@/js/backend/domains";
import authCodeModal from "./authCodeModal.vue"

export default {
	components: {
		VueGoodTable,
		authCodeModal
	},

	setup() {
		const authcodemodalwindow = ref();
		const toaster = createToaster({ position: 'top' });

		const rows = ref([]);
		const columns = ref([
			{
				label: "",
				field: "DOM_CANCEL",
				sortable: false,
				width: "14px",
				thClass: "cancelled",
				tdClass: cancelledClassFunc,
			},
			{ label: "ID", field: "DOM_ID", type: "number" },
			{
				label: "Domain",
				field: "DOM_NAME",
				filterOptions: {
					styleClass: "class1", // class to be added to the parent th element
					enabled: true, // enable filter for this column
				},
			},
			{
				label: "Client",
				field: "CLIENT",
				filterOptions: {
					styleClass: "class1", // class to be added to the parent th element
					enabled: true, // enable filter for this column
				},
			},
			{
				label: "Due",
				field: "DOM_DUE",
				filterOptions: {
					styleClass: "class1", // class to be added to the parent th element
					enabled: true, // enable filter for this column
				},
			},
			{
				label: "Auto",
				field: "DOM_AUTORENEW",
				thClass: "cancelled",
				tdClass: autorenewClassFunc,
			},
			{ label: "Action", field: "Action" },
		]);
		const totalRecords = ref(0);
		const serverParams = ref ({
			// a map of column filters example: {name: 'john', age: '20'}
			columnFilters: {},
			sort: [
				{
					field: "DOM_NAME", // example: 'name'
					type: "asc", // 'asc' or 'desc'
				},
			],

			page: 1, // what page I want to show
			perPage: 20, // how many items I'm showing per page
		});

		onBeforeMount(() => {
			loadItems();
		});

		const getAuthcode = (domid) => {
			authcodemodalwindow.value.getAuthCode(domid)
		}

		function updateParams(newProps) {
			serverParams.value = Object.assign({}, serverParams.value, newProps);
			console.log("Filter changed", serverParams.value);
		}

		function onPageChange(params) {
			updateParams({ page: params.currentPage });
			loadItems();
		}

		function onPerPageChange(params) {
			updateParams({ perPage: params.currentPerPage });
			loadItems();
		}

		function onSortChange(params) {
			updateParams({
				sort: [
					{
						type: params[0].type,
						field: params[0].field,
					},
				],
				page: 1,
			});
			loadItems();
		}

		function onColumnFilter(params) {
			updateParams(params);
			loadItems();
		}

		// load items is what brings back the rows from server
		function loadItems() {
			DomainsSource.getDomainsList(serverParams.value).then((response) => {
				rows.value = response.data.rows;
				totalRecords.value = response.data.totalRecords;
				console.log(totalRecords.value + " records");
			});
		}

		function cancelledClassFunc(row) {
			if (row.DOM_CANCEL == 0) {
				return "cancelled cancelled__on";
			} 
			return "cancelled cancelled__off";
		}

		function autorenewClassFunc(row) {
			if (row.DOM_AUTORENEW == 0) {
				return "cancelled cancelled__off";
			} 
			return "cancelled cancelled__on";
		}

		function updateList(data) {
			console.log("arrived in the list with ", data)
			for (const obj of rows.value) {
				if (obj.DOM_ID === data.DOM_ID) {
					console.log("MATCH ", obj.DOM_CLIENTID)
					obj.DOM_CLIENTID = data.DOM_CLIENTID,
					obj.DOM_NAME = data.DOM_NAME,
					obj.DOM_CANCEL = data.DOM_CANCEL,
					obj.DOM_DUE = data.DOM_DUE
					break;
				}
			}
			return true;
		}
		
		function setDomainCancelStatus(data) {
			data.DOM_CANCEL= (data.DOM_CANCEL == 1) ? 0 : 1;
			DomainsSource.setDomainCancelStatus(data.DOM_ID, data.DOM_CANCEL)
				.then((response) => {
					if (response.data.Error) {
						toaster.error(
							"Could not set Cancel Status: \n<br/>" + response.data.Error
						);
					} else {
						updateList(data)
						toaster.success("Cancel Status changed");
					}
				})
				.catch((error) => {
					toaster.error("Could not set Cancel Status ", error);
				});
		}
		
		function addToList() {
			onSortChange([{"type": "desc", "field": "DOM_ID"}]);
			loadItems();
			
			// rows.value.push(data)
		}
		
		function removeFromList(id) {
			rows.value.splice(rows.value.findIndex(v => v.DOM_ID === id), 1);
			console.log("removed ID ", id)
		}
		
		return {
			rows,
			totalRecords,
			columns,
			updateList,
			addToList,
			removeFromList,
			getAuthcode,
			setDomainCancelStatus,
			authcodemodalwindow,
			cancelledClassFunc,
			autorenewClassFunc,
			onColumnFilter,
			onSortChange,
			onPerPageChange,
			onPageChange
		}
		
	}
}

</script>