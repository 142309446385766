<template>
	<div class="container-fluid pbn">
		<div class="row">
			<h1 class="text-center text-3xl font-extrabold leading-9 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
				Bilance
			</h1>
		</div>
		<div class="row">
			<div class="col-auto">
				<label for="mmp" class="col-form-label">Month</label>
			</div>
			<div class="col-3">
				<v-select :options="mm" v-model="month" @input="showcurrent"></v-select>
			</div>
			<div class="col-auto">
				<label for="mmp" class="col-form-label">Year</label>
			</div>
			<div class="col-3"><v-select :options="yyyy" v-model="year"></v-select></div>
			<div class="col-auto">
				<button class="btn btn-primary" type="button" @click="loadBilance">show</button>
			</div>
		</div>
		<div class="row content" id="list" v-if="rows">
			<table class="table table-striped table-bordered" v-if="month.value == 'all'">
				<thead>
					<tr>
						<th>Month</th>
						<th colspan="4">In</th>
						<th colspan="4">Out</th>
						<th colspan="5">Bilance</th>
					</tr>
					<tr>
						<th></th>
						<th>Domains</th>
						<th>Hosting</th>
						<th>Custom</th>
						<th>Total</th>
						<th>Domains</th>
						<th>Hosting</th>
						<th>PBN Hosting</th>
						<th>Total</th>
						<th>Domains</th>
						<th>Hosting</th>
						<th>PBN Hosting</th>
						<th>Custom</th>
						<th>Total</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, key, idx) in rows" v-bind:key="idx">
						<td>{{key}}</td>
						<td>{{item.In.Domains}}</td>
						<td>{{item.In.Hosting}}</td>
						<td>{{item.In.Custom}}</td>
						<td>{{item.In.Total}}</td>
						<td>{{item.Out.Domains}}</td>
						<td>{{item.Out.Hosting}}</td>
						<td>{{item.Out.PBNHosting}}</td>
						<td>{{item.Out.Total}}</td>
						<td>{{item.Bilance.Domains}}</td>
						<td>{{item.Bilance.Hosting}}</td>
						<td>{{item.Bilance.PBNHosting}}</td>
						<td>{{item.Bilance.Custom}}</td>
						<td>{{item.Bilance.Total}}</td>
					</tr>
				</tbody>
			</table>
			
			
			<table class="table table-striped table-bordered" v-else>
				<thead>
					<tr>
						<th></th>
						<th class="text-end">Domains</th>
						<th class="text-end">Hosting</th>
						<th class="text-end">PBN Hosting</th>
						<th class="text-end">Custom</th>
						<th class="text-end">Total</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>In</td>
						<td class="text-end">{{rows.In.Domains}}</td>
						<td class="text-end">{{rows.In.Hosting}}</td>
						<td></td>
						<td class="text-end">{{rows.In.Custom}}</td>
						<td class="text-end">{{rows.In.Total}}</td>
					</tr>
					<tr>
						<td>Out</td>
						<td class="text-end">{{rows.Out.Domains}}</td>
						<td class="text-end">{{rows.Out.Hosting}}</td>
						<td class="text-end">{{rows.Out.PBNHosting}}</td>
						<td></td>
						<td class="text-end">{{rows.Out.Total}}</td>
					</tr>
				</tbody>
				<tfoot>
					<tr>
						<td>Bilance</td>
						<td class="text-end">{{rows.Bilance.Domains}}</td>
						<td class="text-end">{{rows.Bilance.Hosting}}</td>
						<td class="text-end">{{rows.Bilance.PBNHosting}}</td>
						<td class="text-end">{{rows.Bilance.Custom}}</td>
						<td class="text-end">{{rows.Bilance.Total}}</td>
					</tr>
				</tfoot>
			</table>
			
		</div>
	</div>
</template>

<script src="./Bilance.js"></script>
