<template>
	<div class="accordion mh-100" id="submenu">
		<div v-for="(entry,id) in menuitems" v-bind:key="id" class="accordion-item">
			<h2 class="accordion-header" id="headingOne">
				<button
					class="accordion-button collapsed"

					data-bs-toggle="collapse"
					:data-bs-target="'#collapse' + id"
					:aria-expanded="expanded == id"
					:aria-controls="'collapse' + id"
					v-html="entry.topic"
				>
				</button>
			</h2>
			<div
				:id="'collapse' + id"
				:class="'accordion-collapse collapse ' + getshowclass(id)"
				aria-labelledby="headingOne"
				data-bs-parent="#submenu"
			>
				<div :class="{'accordion-body': true, 'active': item.url == $route.fullPath}" v-for="(item,idx) in entry.entries" v-bind:key="idx">
					<router-link :to="item.url">{{ item.name }}</router-link>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
export default {
	props: {
		menuitems: Object,
	},
	data() {
		return {
			expanded: 0
		};
	},
	
	beforeRouteUpdate(to, from, next) {  // eslint-disable no-unused-vars
		this.showActive(to.path)
		next();
	},
	
	created() {
		this.showActive(this.$route.path);
	},
	
	methods: {
		getshowclass(id) {
			if(parseInt(id) == this.expanded) { 
				return 'show';
			}
			return false; 
		},
		
		showActive(currentPath) {
			for(let i in this.menuitems) {
				for(let x in this.menuitems[i].entries) {
					if(this.menuitems[i].entries[x].url == currentPath) {
						// console.log(this.menuitems[i].entries[x].url, " == ",currentPath)
						this.expanded = i;
					}
				}
			}
		}
		
	},
};
</script>
