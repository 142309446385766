// eslint-disable-next-line
import API from "./axios";
export default {
	// eslint-disable-next-line
	list(params) {
		return API.get("/admin/customarticles/list",{params: params});
	},
	single(id) {
		return API.get(`/admin/customarticles/single/${id}`);
	},
	getClientShortList(params) {
		return API.get("/admin/clients/shortlist",{params: params});
	},
	editArticle(data) {
		return API.post("/admin/customarticles/edit", data);
	},
	addArticle(data) {
		return API.post("/admin/customarticles/add", data);
	},
	deleteArticle(hid) {
		return API.post("/admin/customarticles/delete", {id: hid});
	},
	setValue(id,key,value) {
		return API.post("/admin/customarticles/setvalue", {id: id, key: key, value: value});
	},
	
};
