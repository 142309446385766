// import InvoicesDetails from "./ClientDetails.vue";
import { VueGoodTable } from 'vue-good-table-next';
import { ref, onBeforeMount } from "vue";
import { onBeforeRouteUpdate, useRoute } from 'vue-router'

import { createToaster } from "@meforma/vue-toaster";
import InvoiceDetails from "./InvoiceDetails.vue";
import InvoiceAdd from "./InvoiceAdd.vue";
import InvoicesSource from '@/js/backend/invoices';

export default {
	components: {
		VueGoodTable,
		InvoiceDetails,
		InvoiceAdd
	},

	setup() {
		const toaster = createToaster({ position: 'top' });
		const route = useRoute()
		const	detailurl = ref('');
		const inid = ref(0);
		const active = ref('list');

		const rows = ref([]);
		const columns = ref([
			{ label: 'ID', field: 'IN_ID', type: 'number' },
			{ label: 'No.', field: 'IN_NUMBER' },
			{
				label: 'Client',
				field: 'CLIENT',
				filterOptions: {
					styleClass: 'class1', // class to be added to the parent th element
					enabled: true, // enable filter for this column
				}
			},
			{
				label: 'Sent',
				field: 'IN_SENT',
			},
			{
				label: 'Due',
				field: 'IN_DUE',
			},
			{
				label: 'Net',
				field: 'IN_AMOUNT',
				type: 'number'
			},
			{
				label: 'Gross',
				field: 'IN_TOTAL',
				type: 'number'
			},
			{ label: 'Status', field: 'IN_STATUS', sortable: false	},
			{ label: 'Details', field: 'Details', sortable: false },
			{ label: 'Delete', field: 'Delete', sortable: false },
			{ label: 'Set Paid', field: 'SetPaid', sortable: false },
			{ label: 'PDF', field: 'PDF', sortable: false},
			{ label: 'Clone', field: 'Clone', sortable: false },
		]);
		
		
		const totalRecords = ref(0);
		const serverParams = ref ({
			// a map of column filters example: {name: 'john', age: '20'}
			columnFilters: {},
			sort: [
				{
					field: "IN_ID", // example: 'name'
					type: "desc", // 'asc' or 'desc'
				},
			],

			page: 1, // what page I want to show
			perPage: 20, // how many items I'm showing per page
		});

		onBeforeMount(() => {
			getTab(route);
			loadItems();
		});
		
		function showTab(which) {
			active.value = which;
			return which;
		}
		
		function showDetails(id) {
			inid.value = parseInt(id);
			console.log("set id to ", inid.value);
			showTab('details');
		}
		
		function getTab(addr) {
			console.log("Get tab called for ", addr)
			if(!addr) {
				showTab('list');
			}
			else if(addr.params?.id) {
				console.log("show details for ", addr.params.id)
				detailurl.value = addr.fullPath
				showDetails(addr.params.id)
			}
			else if((addr.tab && addr.tab == "add") || (addr.path && addr.path.match(/add/))) {
				showTab('add');
			}
			else {
				showTab('list');
			}
		}
		
		function updateParams(newProps) {
			serverParams.value = Object.assign({}, serverParams.value, newProps);
			console.log("Filter changed", serverParams.value);
		}

		function onPageChange(params) {
			updateParams({ page: params.currentPage });
			loadItems();
		}

		function onPerPageChange(params) {
			updateParams({ perPage: params.currentPerPage });
			loadItems();
		}

		function onSortChange(params) {
			updateParams({
				sort: [
					{
						type: params[0].type,
						field: params[0].field,
					},
				],
				page: 1,
			});
			loadItems();
		}

		function onColumnFilter(params) {
			updateParams(params);
			loadItems();
		}

		// load items is what brings back the rows from server
		function loadItems() {
			InvoicesSource.getInvoicesList(serverParams.value).then((response) => {
				rows.value = response.data.rows;
				totalRecords.value = response.data.totalRecords;
				console.log(totalRecords.value + " records");
			});
		}

		function prepareAdd() {
			showTab('add');
	}

		function updateList(data) {
			console.log("arrived in the list with ", data)
			for (const obj of rows.value) {
				if (obj.IN_ID === data.IN_ID) {
					console.log("MATCH ", obj.IN_ID)
					obj.IN_DUE = data.IN_DUE;
					obj.IN_SENT = data.IN_SENT;
					obj.IN_AMOUNT = data.IN_AMOUNT;
					obj.IN_TOTAL = data.IN_TOTAL;
					obj.CL_COMPANY = data.CL_COMPANY;
					break;
				}
			}
			return true;
		}
		
		function deleteInvoice(id) {
			if(confirm("SURE??")) {
				InvoicesSource.deleteInvoice(id).then((response) => {
					if (response.data.Error) {
						toaster.error(
							"Could not delete invoice: \n<br/>" + response.data.Error
						);
						console.error(response.data.Error);
					} else {
						toaster.success("Invoice deleted");
						removeFromList(id);
					}
				})
				.catch((error) => {
					toaster.error("Could not delete invoice ", error);
				});
			}
		}
		
		function addToList() {
			onSortChange([{"type": "desc", "field": "IN_ID"}]);
			loadItems();
			
			// rows.value.push(data)
		}
		
		function removeFromList(id) {
			rows.value.splice(rows.value.findIndex(v => v.IN_ID === id), 1);
			console.log("removed ID ", id)
		}
		
		function togglePaid(idx) {
			console.log(idx, " is ", rows.value[idx]);
			rows.value[idx].IN_STATUS = (rows.value[idx].IN_STATUS == 1) ? 0 : 1;
			InvoicesSource.setInvoiceValue(rows.value[idx].IN_ID,'IN_STATUS',rows.value[idx].IN_STATUS).then((response) => {
				if (response.data.Error) {
					toaster.error(
						"Could not set Paid status: \n<br/>" + response.data.Error
					);
					console.error(response.data.Error);
				} else {
					toaster.success("Paid status set");
				}
			})
			.catch((error) => {
				toaster.error("Could not set Paid status ", error);
			});
		}
		
		function cloneInvoice(id) {
			InvoicesSource.cloneInvoice(id).then((response) => {
				if (response.data.Error) {
					toaster.error(
						"Could not clone invoice: \n<br/>" + response.data.Error
					);
					console.error(response.data.Error);
				} else {
					toaster.success("Invoice cloned");
					loadItems()
				}
			})
			.catch((error) => {
				toaster.error("Could not clone invoice ", error);
			});
		}
		
		function pdfInvoice(in_id, in_number) {
			let that = this;
			let invoiceno = (in_number == "") ? in_id : in_number;

			let dlname = "invoice_" + invoiceno + ".pdf";
			InvoicesSource
				.downloadpdf(in_id)
				.then(response => {
					let blob = new Blob([response.data], { type: "application/pdf" });
					let link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob);
					link.download = dlname;
					link.click();
				})
				.catch(() => {
					that.$toastr.Add({
						title: "Error", // Toast Title
						msg: "Could not download PDF " + dlname,
						position: "toast-top-center",
						type: "error"
					});
				});
			}
		
		function statusClassFunc(row) {
			if (row.IN_STATUS == 1) {
				return "status status__success";
			} 
			return "status status__error";
		}
		
		
		return {
			rows,
			totalRecords,
			columns,
			detailurl,
			active,
			prepareAdd,
			getTab,
			showTab,
			updateList,
			addToList,
			removeFromList,
			showDetails,
			onColumnFilter,
			onSortChange,
			onPerPageChange,
			onPageChange,
			statusClassFunc,
			togglePaid,
			deleteInvoice,
			cloneInvoice,
			pdfInvoice
		}
		
	}
}

