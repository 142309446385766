<template>
	<div class="client" id="clientdetails">
		<h2 v-if="item.CL_ID > 0">Client #{{ item.CL_ID }}</h2>
		<h2 v-else>Add Client</h2>
		<div class="container-fluid" v-if="loading">
			<div class="loader">
				<div
					class="spinner-grow text-success"
					style="width: 6rem; height: 6rem"
					role="status"
				></div>
				<p class="text-primary">Loading...</p>
			</div>
		</div>
		<div class="container-fluid details" v-else>
			<form @submit.prevent="editClient">
				<div class="row">
					<div class="col col-4">
						<div :class="{ error: v$.CL_FIRSTNAME.$errors.length }">
							<label for="servername" class="form-label">First Name</label>
							<input
								type="text"
								class="form-control"
								id="firstname"
								name="firstname"
								v-model="item.CL_FIRSTNAME"
							/>
							<div
								class="input-errors"
								v-for="error of v$.CL_FIRSTNAME.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					<div class="col col-4">
						<div :class="{ error: v$.CL_LASTNAME.$errors.length }">
							<label for="hostname" class="form-label">Last Name</label>
							<input
								type="text"
								class="form-control"
								id="lastname"
								v-model="item.CL_LASTNAME"
							/>
							<div
								class="input-errors"
								v-for="error of v$.CL_LASTNAME.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					<div class="col col-4">
						<label for="hostname" class="form-label">Company</label>
						<input
							type="text"
							class="form-control"
							id="company"
							v-model="item.CL_COMPANY"
						/>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<div :class="{ error: v$.CL_ADDRESS.$errors.length }">
							<label for="site_ip" class="control-label">Street</label>
							<input
								type="text"
								class="form-control"
								name="address"
								id="address"
								v-model="item.CL_ADDRESS"
							/>
							<div
								class="input-errors"
								v-for="error of v$.CL_ADDRESS.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					<div class="col col-2">
						<div :class="{ error: v$.CL_ZIP.$errors.length }">
							<label for="site_ram" class="control-label">ZIP</label>
							<input
								type="text"
								class="form-control"
								name="zip"
								id="zip"
								v-model="item.CL_ZIP"
							/>
							<div
								class="input-errors"
								v-for="error of v$.CL_ZIP.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					<div class="col col-4">
						<div :class="{ error: v$.CL_CITY.$errors.length }">
							<label for="site_ip" class="control-label">Street</label>
							<input
								type="text"
								class="form-control"
								name="city"
								id="city"
								v-model="item.CL_CITY"
							/>
							<div
								class="input-errors"
								v-for="error of v$.CL_CITY.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				
				<div class="row">
					<div class="col col-6">
						<label for="site_users" class="control-label">State</label>
						<input
							type="text"
							class="form-control"
							name="state"
							id="state"
							v-model="item.CL_STATE"
						/>
					</div>
					<div class="col col-6">
						<div :class="{ error: v$.CL_COUNTRY.$errors.length }">
							<label for="hostname" class="control-label">Country</label>
							<br /><Dropdown
								v-model="item.CL_COUNTRY"
								:options="countries"
								optionLabel="text"
								optionValue="id"
								placeholder="Select one"
							/>
							<div
								class="input-errors"
								v-for="error of v$.CL_COUNTRY.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				
				<div class="row">
					<div class="col col-3">
						<div :class="{ error: v$.CL_TEL.$errors.length }">
							<label for="tel" class="control-label">Phone</label>
							<input
								type="text"
								class="form-control"
								id="company"
								placeholder="Contact Last Name"
								v-model="item.CL_TEL"
							/>
							<div
								class="input-errors"
								v-for="error of v$.CL_TEL.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					<div class="col col-3">
						<div :class="{ error: v$.CL_FAX.$errors.length }">
							<label for="tel" class="control-label">Fax</label>
							<input
								type="text"
								class="form-control"
								id="company"
								placeholder="Contact Last Name"
								v-model="item.CL_FAX"
							/>
							<div
								class="input-errors"
								v-for="error of v$.CL_FAX.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					<div class="col col-6">
						<div :class="{ error: v$.CL_EMAIL.$errors.length }">
							<label for="email" class="control-label">
								Email:
							</label>
							<input
								type="text"
								class="form-control"
								id="email"
								v-model="item.CL_EMAIL"
							/>
							<div
								class="input-errors"
								v-for="error of v$.CL_EMAIL.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				
				

				<div class="row">
					<div class="col col-4">
						<label for="site_panel_url">EU VAT ID</label>
						<input
							type="text"
							class="form-control"
							name="CL_VIES"
							id="CL_VIES"
							v-model="item.CL_VIES"
						/>
					</div>

					<div class="col col-2">
						<label for="site_first_payment" class="control-label">VAT Free</label>
						<br />
						<Toggle
							v-model="item.CL_VATFREE"
							class="toggle-red"
							on-label="Yes"
							off-label="No"
							:falseValue="0"
							:trueValue="1"
						/>
					</div>

					<div class="col col-6">
						<label for="site_payment_interval" class="control-label"
							>Status</label
						>
						<br /><Dropdown
							v-model="item.CL_STATUS"
							:options="clientstatusselect"
							optionLabel="text"
							optionValue="id"
							placeholder="Select one"
						/>
					</div>
				</div>
				
				<div class="row">
					<div class="col col-6">
						<label for="site_panel_pwd" class="control-label"
							>Tier</label
						>
						<br/>
						<select class="form-select" aria-label="Tier" v-model="item.CL_TIER">
							<option v-for="(option,idx) in tiers" v-bind:key="idx" :value="option">
								{{ option}}
							</option>
						</select>
					</div>
					<div class="col col-6" v-if="!item.CL_ID">
						<label for="site_panel_url">Password</label>
						<input
							type="text"
							class="form-control"
							name="CL_PWD_CLEAR"
							id="CL_PWD_CLEAR"
							v-model="item.CL_PWD_CLEAR"
						/>

					</div>
				</div>

				<div class="row">
					<div class="col col-4 text-center">
						<input
							class="btn btn-primary"
							type="submit"
							name="su"
							:value="submitLabel"
						/>
					</div>
					<div class="col col-4 text-center">
					</div>
					<div class="col col-4 text-center" v-if="item.CL_ID < 1">
						<button type="button" class="btn btn-warning" @click="setDummy">
							Dummy
						</button>
					</div>
				</div>
			</form>
			
			<form @submit.prevent="setPassword">
				<div class="row">
					<div class="col col-4">
						<label for="site_panel_url">Password</label>
						<input
							type="text"
							class="form-control"
							name="password1"
							id="password1"
							v-model="password1"
						/>
					</div>
					<div class="col col-4">
						<label for="site_panel_url">Password (repeat)</label>
						<input
							type="text"
							class="form-control"
							name="password2"
							id="password2"
							v-model="password2"
						/>

					</div>
					<div class="col col-4">
						<br/>
						<input
							class="btn btn-primary"
							type="submit"
							name="su"
							value="Reset"
						/>
					</div>
				</div>
			</form>

		</div>
	</div>
</template>

<script src="./ClientDetails.js"></script>
<style lang="scss">
@import "../../assets/scss/main.scss";

#clientdetails {
	.collapse .container-fluid {
		padding-left: 0;
		.col {
			padding-left: 0;
		}
	}
	.toggle-red {
		.toggle {
			width: 90px !important;
			color: white;
			font-weight: bold;
			&.toggle-off {
				padding-right: 20px;
			}
			&.toggle-on {
				padding-left: 20px;
			}
		}
		--toggle-bg-off: #a00e0e;
		--toggle-border-off: #a00e0e;
		--toggle-bg-on: #{$color-success};
		--toggle-border-on: #{$color-success};
	}
}
</style>

<!--
						<div>
							<label for="P_NEXT_PAYMENT" class="control-label"
								>Next Pay:</label
							>
						</div>
						<Calendar
							id="P_NEXT_PAYMENT"
							v-model="item.P_NEXT_PAYMENT"
							dateFormat="yy-mm-dd"
						/>

						Host: <br /><Dropdown
							v-model="item.M_HID"
							:options="hosts"
							optionLabel="H_NAME"
							optionValue="H_ID"
							placeholder="Select one"
						/>
						
												<label for="M_SYS" class="control-label">System:</label>
						<select
							class="form-control"
							name="M_SYS"
							id="M_SYS"
							v-model="item.M_SYS"
						>
							<option value="">---</option>
							<option value="Vesta">Vesta</option>
							<option value="Hestia">Hestia</option>
							<option value="Webmin">Webmin</option>
						</select>

						
-->