<template>
	<vue-good-table
		:totalRows="totalRecords"
		:columns="columns"
		:rows="rows"
		:pagination-options="{
			enabled: true,
			mode: 'records',
			perPage: 20,
			position: 'both',
			perPageDropdown: [10, 20, 50],
			dropdownAllowAll: false,
			setCurrentPage: 1,
			nextLabel: 'next',
			prevLabel: 'prev',
			rowsPerPageLabel: 'Rows per page',
			ofLabel: 'of',
			pageLabel: 'page', // for 'pages' mode
			allLabel: 'All',
		}"
		v-on:page-change="onPageChange"
		v-on:sort-change="onSortChange"
		v-on:column-filter="onColumnFilter"
		v-on:per-page-change="onPerPageChange"
		styleClass="vgt-table striped"
	>
		>
		<template #table-row="props">
			<span v-if="props.column.field == 'HO_IP'">
				<router-link :to="'/pbn/server/list?id=' + props.row.HO_SERVERID">
					{{ props.row.HO_IP }}
				</router-link>
				<!-- <button class="btn btn-primary btn-sm" @click="showDetails(props.row)">
								Details
							</button> -->
			</span>
			<span v-else-if="props.column.field == 'Action'">
				<router-link
					class="btn btn-primary btn-sm"
					:to="'/pbn/hosting/single/' + props.row.HO_ID"
				>
					Details
				</router-link>
				<!-- <button class="btn btn-primary btn-sm" @click="showDetails(props.row)">
								Details
							</button> -->
			</span>
			<span v-else>
				{{ props.formattedRow[props.column.field] }}
			</span>
		</template>
	</vue-good-table>
</template>

<script>
import { VueGoodTable } from "vue-good-table-next";
import { ref, onBeforeMount } from "vue";
import PBNSource from "@/js/backend/pbn";

export default {
	components: {
		VueGoodTable,
	},

	setup() {

		const rows = ref([]);
		const columns = ref([
			{
				label: "",
				field: "HO_STATUS",
				sortable: false,
				width: "14px",
				thClass: "status",
				tdClass: statusClassFunc,
			},
			{ label: "ID", field: "HO_ID", type: "number" },
			{
				label: "Domain",
				field: "HO_DOMAIN",
				filterOptions: {
					styleClass: "class1", // class to be added to the parent th element
					enabled: true, // enable filter for this column
				},
			},
			{ label: "Client", field: "CLIENT" },
			{
				label: "IP",
				field: "HO_IP",
				filterOptions: {
					styleClass: "class1", // class to be added to the parent th element
					enabled: true, // enable filter for this column
				},
			},
			{ label: "DNS", field: "DNS_NAME" },
			{ label: "Backup", field: "HO_BACKUP_OK", type: "number" },
			{ label: "Action", field: "Action" },
		]);
		const totalRecords = ref(0);
		const serverParams = ref ({
			// a map of column filters example: {name: 'john', age: '20'}
			columnFilters: {},
			sort: [
				{
					field: "HO_DOMAIN", // example: 'name'
					type: "asc", // 'asc' or 'desc'
				},
			],

			page: 1, // what page I want to show
			perPage: 20, // how many items I'm showing per page
		});

		onBeforeMount(() => {
			loadItems();
		});


		function updateParams(newProps) {
			serverParams.value = Object.assign({}, serverParams.value, newProps);
			console.log("Filter changed", serverParams.value);
		}

		function onPageChange(params) {
			updateParams({ page: params.currentPage });
			loadItems();
		}

		function onPerPageChange(params) {
			updateParams({ perPage: params.currentPerPage });
			loadItems();
		}

		function onSortChange(params) {
			updateParams({
				sort: [
					{
						type: params[0].type,
						field: params[0].field,
					},
				],
				page: 1,
			});
			loadItems();
		}

		function onColumnFilter(params) {
			updateParams(params);
			loadItems();
		}

		// load items is what brings back the rows from server
		function loadItems() {
			PBNSource.getHostingList(serverParams.value).then((response) => {
				rows.value = response.data.rows;
				totalRecords.value = response.data.totalRecords;
				console.log(totalRecords.value + " records");
			});
		}

		function statusClassFunc(row) {
			if (row.HO_STATUS == "1") {
				return "status status__success";
			} 
			else if (row.HO_STATUS == "2") {
				return "status status__progress";
			}
			return "status status__error";
		}

		function updateList(data) {
			console.log("arrived in the list with ", data)
			for (const obj of rows.value) {
				if (obj.HO_ID === data.HO_ID) {
					console.log("MATCH ", obj.HO_CLID)
					obj.HO_CLID = data.HO_CLID,
					obj.HO_DOMAIN = data.HO_DOMAIN,
					obj.HO_IP = data.HO_IP,
					obj.DNS_NAME = data.DNS_NAME,
					obj.CLIENT = data.CLIENT,
					obj.HO_DNSID = data.HO_DNSID
					break;
				}
			}
			return true;
		}
		
		function addToList(data) {
			rows.value.push(data)
		}
		
		function removeFromList(id) {
			rows.value.splice(rows.value.findIndex(v => v.HO_ID === id), 1);
			console.log("removed ID ", id)
		}
		
		return {
			rows,
			totalRecords,
			columns,
			updateList,
			addToList,
			removeFromList,
			statusClassFunc,
			onColumnFilter,
			onSortChange,
			onPerPageChange,
			onPageChange
		}
		
	}
}

</script>