import HostingLayout from "@/Layouts/HostingLayout.vue";
import HostingServerDetails from "./ServerDetails.vue";
import { VueGoodTable } from 'vue-good-table-next';
import HostingSource from '@/js/backend/hosting';
export default {

	name: 'Home',

	components: {
		HostingLayout,
		HostingServerDetails,
		VueGoodTable
	},

	props: {
		msg: String
	},
	data() {
		return {
			baseurl: '',
			detailurl: '',
			//Good Table Stuff
			isLoading: false,
			columns: [
				{ label: 'ID', field: 'S_ID', type: 'number' },
				{
					label: 'Host',
					field: 'S_PROVIDER',
					filterOptions: {
						styleClass: 'class1', // class to be added to the parent th element
						enabled: true, // enable filter for this column
					}
				},
				{
					label: 'Target',
					field: 'S_TARGET',
					filterOptions: {
						styleClass: 'class1', // class to be added to the parent th element
						enabled: true, // enable filter for this column
					}
				},
				{
					label: 'IP',
					field: 'S_IP',
					type: 'number',
					filterOptions: {
						styleClass: 'class1', // class to be added to the parent th element
						enabled: true, // enable filter for this column
					}
				},
				{
					label: 'Hostname',
					field: 'S_NAME',
					filterOptions: {
						styleClass: 'class1', // class to be added to the parent th element
						enabled: true, // enable filter for this column
					}
				},
				{ label: 'Used', field: 'Used', type: 'number' },
				{ label: 'Avail.', field: 'S_MAXDOMAINS', type: 'number' },
				{ label: 'Sys', field: 'S_SYS' },
				{ label: 'CP', field: 'S_PANEL_URL' },
				{ label: 'Action', field: 'Action' }
			],
			rows: [
			],
			active: 'list',
			sid: 0
		}
	},

	created() {
		this.baseurl = this.$route.path;
		this.getTab(this.$route.query);
		HostingSource.getServerList().then(response => {
			this.rows = response.data
			// console.log("GOT ", this.rows.length, " NOW")
		})
			.catch(error => {
				this.$toast.error('Could not fetch data ', error)
			});
	},

	mounted() {
	},
	
	beforeRouteEnter (to, from, next) {
		next(vm => {
			console.log("new route here")
			vm.getTab(to)
		})
	},

	methods: {
		prepareAdd() {
			this.$refs.hostingserveradd.resetData();
			this.showTab('add');
		},
		
		getTab(addr) {
			console.log("called get Tab ")
			if(!addr) {
				this.showTab('list');
			}
			else if(addr.params?.id) {
				console.log("detail view for " + addr.params.id)
				this.detailurl = addr.fullPath
				this.showDetails(addr.params.id)
			}
			else if (addr.tab) {
				if (addr.tab == "add") {
					this.showTab('add');
				}
				else if (addr.tab == "analysis") {
					this.showTab('analysis');
				}
			}
			else if(addr.path && addr.path.match(/add/)) {
				this.showTab('add');
			}
			else {
				this.showTab('list');
			}

		},

		showTab(which) {
			this.active = which;
			return which;
		},
		showDetails(id) {
			this.sid = parseInt(id);
			console.log("set id to ", this.sid);
			this.showTab('details');
		},
		updateListHandler(data) {
			console.log("will now set ", data);
			for (const obj of this.rows) {
				if (obj.M_ID === data.M_ID) {
					obj.M_BASEIP = data.M_BASEIP,
					obj.M_SYS = data.M_SYS,
					obj.M_HOSTNAME = data.M_HOSTNAME,
					obj.M_MAXDOMAINS = data.M_MAXDOMAINS,
					obj.P_SUBSCRIPTION = data.P_SUBSCRIPTION
					break;
				}
			}
		},
		addListHandler(data) {
			console.log("add to list: ", data)
			this.rows.push(data);
		},
		
		removeFromListHandler(data) {
			console.log("Removeing from list: ", data)
			this.rows.splice(this.rows.findIndex(v => v.S_ID === data.S_ID), 1);
		},
		
		cpLogin(pwd) {
			const el = document.createElement("textarea");
			el.value = pwd;
			document.body.appendChild(el);
			el.select();
			var successful = document.execCommand("copy");
			var msg = successful ? "successful" : "unsuccessful";
			document.body.removeChild(el);
			console.log(msg);

		}

	}

}
