// eslint-disable-next-line
import API from "./axios";
export default {
	// eslint-disable-next-line
	getSubscriptionList(params) {
		return API.get("/admin/hosting/subscriptions/list",{params: params});
	},
	getSubscription(id) {
		return API.get("/admin/hosting/subscriptions/single/" + id);
	},
	editSubscription(data) {
		return API.post("/admin/hosting/subscriptions/edit", data);
	},
	addSubscription(data) {
		return API.post("/admin/hosting/subscriptions/add", data);
	},
	deleteSubscription(hid) {
		return API.post("/admin/hosting/subscriptions/delete", {id: hid});
	},
	getServerShortList() {
		return API.get("/admin/hosting/server/shortlist");
	},
	getServerList() {
		return API.get("/admin/hosting/server/all");
	},
	getServerQueryList(params) {
		return API.get("/admin/hosting/server/list",{params: params});
	},
	getServer(id) {
		return API.get("/admin/hosting/server/single/" + id);
	},
	editServer(data) {
		return API.post("/admin/hosting/server/edit", data);
	},
	addServer(data) {
		return API.post("/admin/hosting/server/add", data);
	},
	deleteServer(mid) {
		return API.post("/admin/hosting/server/delete", {id: mid});
	},
	getClientShortList() {
		return API.get("/admin/clients/shortlist");
	},
	
};
