<template>
	<div class="domains" id="domaindetails">
		<div class="d-flex justify-content-between">
			<div class="text-left">
				<h2>Domain #{{ item.DOM_ID }}</h2>
			</div>
			<div class="text-right">
				<button class="btn btn-outline-primary" @click="getFromRegistry">Get data from registry</button>
			</div>
		</div>
		<div class="container-fluid" v-if="loading">
			<div class="loader">
				<div
					class="spinner-grow text-success"
					style="width: 6rem; height: 6rem"
					role="status"
				></div>
				<p class="text-primary">Loading...</p>
			</div>
		</div>
		<div class="container-fluid details" v-else>
			<form @submit.prevent="editDomain">
				<div class="row">
					<div class="col col-3">
						Name <br/>
						<span class="fixedvalue">{{ item.DOM_NAME }}</span>
					</div>
					<div class="col col-1">
						TLD <br/>
						<span class="fixedvalue">{{ item.DOM_TLD }}</span>
					</div>
					<div class="col col-3">
						Client <br/>
						<span class="fixedvalue">{{ parsedValues.clientname }}</span>
					</div>
					<div class="col col-3">
						Provider: <br />
						<span class="fixedvalue">{{ parsedValues.provider }}  ({{ item.DOM_PROVIDER }})</span>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<div class="container-fluid subdetails">
							<div class="row">
								<div class="col col-12">
									<label for="hostname" class="control-label">IP</label>
									<input
										type="text"
										class="form-control"
										id="domain"
										v-model="item.DOM_IP"
									/>
								</div>
							</div>
							<div class="row">
								<div class="col col-12">
									Admin C: <br /><Dropdown
										:filter="true"
										v-model="item.DOM_ADMINC_ID"
										:options="handles"
										optionLabel="text"
										optionValue="value"
										placeholder="Select one"
									/>
								</div>
							</div>
							<div class="row">
								<div class="col col-12">
									Tech C: <br /><Dropdown
										:filter="true"
										v-model="item.DOM_TECHC_ID"
										:options="handles"
										optionLabel="text"
										optionValue="value"
										placeholder="Select one"
									/>
								</div>
							</div>
							<div class="row">
								<div class="col col-12">
									Zone C: <br /><Dropdown
										:filter="true"
										v-model="item.DOM_ZONEC_ID"
										:options="handles"
										optionLabel="text"
										optionValue="value"
										placeholder="Select one"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="col col-6 d-flex flex-column">
						<div :class="{ error: v$.DOM_DNS_TEXT.$errors.length }" class="form-group flex-grow-1 d-flex flex-column">
							<label for="hostname" class="control-label">
								Nameservers:
							</label>
							<textarea
								class="form-control flex-grow-1"
								id="domdns"
								v-model="item.DOM_DNS_TEXT"
								
							/>
							<div
								class="input-errors"
								v-for="error of v$.DOM_DNS_TEXT.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
												<br />
						<Toggle
							v-model="item.DOM_CANCEL"
							class="toggle-red"
							:trueValue="1"
							:falseValue="0"
							on-label="Cancelled"
							off-label="Active"
						/>

					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<label for="site_first_payment" class="control-label">Expires</label>
						<br/>
						<input
							class="form-control"
							type="text"
							v-model="item.DOM_EXPIRED"
						/>
					</div>
					<div class="col col-6">
							<label for="site_first_payment" class="control-label">Due</label>
							<br/>
							<DatePicker :attributes='datePickerAttributes'  :masks="{ title: 'MMM YYYY', L: 'YYYY-MM-DD'  }" v-model="item.DOM_DUE">
								<template v-slot="{ inputValue, inputEvents }">
									<input
										class="form-control"
										
										:value="inputValue"
										v-on="inputEvents"
									/>
								</template>
							</DatePicker>
					</div>
				</div>

				<div class="row">
					<div class="col col-4 text-center">
						<input
							class="btn btn-primary"
							type="submit"
							name="su"
							value="Edit"
						/>
					</div>
					<div class="col col-4 text-center">
						<button type="button" class="btn btn-danger" @click="deleteHosting">
							Delete
						</button>
					</div>
					<div class="col col-4 text-center" v-if="item.H_ID < 1">
						<button type="button" class="btn btn-warning" @click="setDummy">
							Dummy
						</button>
					</div>
				</div>
			</form>
			<form @submit.prevent="changeOwner">
				<div class="row">
					<div class="col col-6">
						Owner: <br /><Dropdown
							:filter="true"
							v-model="item.DOM_OWNER_ID"
							:options="owners"
								optionLabel="text"
								optionValue="value"
							placeholder="Select one"
						/>
					</div>
					<div class="col col-6 text-left pt-4">
						<input
							class="btn btn-primary"
							type="submit"
							name="su"
							value="Change Owner"
						/>
					</div>
				</div>
			</form>
		</div>
		<div class="container-fluid details" v-if="registryDetails">
			<div v-if="registryLoading">
				<div class="loader loader__sm">
					<div
						class="spinner-grow text-success"
						style="width: 2rem; height: 2rem"
						role="status"
					></div>
					<p class="text-primary">Loading...</p>
				</div>
			</div>
			<div class="border border-secondary p-3" v-else>
				<h3>Registry Data</h3>
				<div class="row">
					<div class="col col-6"><span class="article">Name:</span> {{ registryDetails.domain }}</div>
					<div class="col col-6"><span class="article">Status:</span> {{ registryDetails.status }}</div>
				</div>
				<div class="row">
					<div class="col col-3">
						<span class="article">Expires:</span> {{ registryDetails.expires }}
						<br/><button v-if="registryDetails.expires" type="button" class="btn btn-success btn-sm" @click="setValue('DOM_EXPIRED',registryDetails.expires)">Set</button>
					</div>
					<div class="col col-9">
						<span class="article">Nameservers:</span> {{ registryDetails.dns }}
						<br/><button type="button" class="btn btn-success btn-sm" @click="setValue('DOM_DNS',registryDetails.dns)">Set</button>
					</div>
				</div>
				<div class="row">
					<div class="col col-3" v-if="registryDetails.ownerc_id">
						<span class="article">Owner:</span> {{ registryDetails.ownerc_id }}
						<br/><button type="button" class="btn btn-success btn-sm" @click="setValue('DOM_OWNER_ID',registryDetails.ownerc_id)">Set</button>
					</div>
					<div class="col col-3">
						<span class="article">AdminC:</span> {{ registryDetails.adminc_id }}
						<br/><button type="button" class="btn btn-success btn-sm" @click="setValue('DOM_ADMINC_ID',registryDetails.adminc_id)">Set</button>
					</div>
					<div class="col col-3">
						<span class="article">TechC:</span> {{ registryDetails.techc_id }}
						<br/><button type="button" class="btn btn-success btn-sm" @click="setValue('DOM_TECHC_ID',registryDetails.techc_id)">Set</button>
					</div>
					<div class="col col-3" v-if="registryDetails.zonec_id">
						<span class="article">ZoneC:</span> {{ registryDetails.zonec_id }}
						<br/><button type="button" class="btn btn-success btn-sm" @click="setValue('DOM_ZONEC_ID',registryDetails.zonec_id)">Set</button>
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<button class="btn btn-warning btn-sm" type="button" data-bs-toggle="collapse" data-bs-target="#registrydetail" aria-expanded="false" aria-controls="registrydetail">
							Raw
						</button>
						<pre id="registrydetail" class="collapse">
							{{registryDetails}}
						</pre>
					</div>
				</div>
			</div>
		</div>
		<div class="border border-secondary p-3">
			<pre id="localdetail" class="collapse">
				{{item}}
			</pre>
			<button class="btn btn-warning btn-sm" type="button" data-bs-toggle="collapse" data-bs-target="#localdetail" aria-expanded="false" aria-controls="localdetail">
				Raw
			</button>
		</div>
	</div>
</template>

<script src="./DomainsDetails.js"></script>
<style lang="scss">
@import "../../assets/scss/main.scss";

#domaindetails {
	.collapse .container-fluid {
		padding-left: 0;
		.col {
			padding-left: 0;
		}
	}
	.toggle-red {
		.toggle {
			width: 90px !important;
			color: white;
			font-weight: bold;
			&.toggle-off {
				padding-right: 10px;
			}
			&.toggle-on {
				padding-left: 10px;
			}
		}
		--toggle-bg-on: #a00e0e;
		--toggle-border-on: #a00e0e;
		--toggle-bg-off: #{$color-success};
		--toggle-border-off: #{$color-success};
	}
	.fixedvalue {
		font-weight: 700;
		font-size: 1.1rem;
	}
	.subdetails {
		padding: 0;
		.col {
			padding: 0;
		}
	}
	.article {
		font-weight:bold;
	}
}
</style>