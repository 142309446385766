<template>
	<div class="container-fluid site">
		<div class="row">
			<div class="col-sm-12 col-lg-2 h-100 navcarry">
				<VerticalNavigation :menuitems="menuitems" />
			</div>
			<div class="col-sm-12 col-lg-10">
				<router-view/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "DomainsLayout",
	data() {
		return {
			menuitems: [
				{
					topic: "Domains",
					expanded: true,
					entries: [
						{ name: "List", url: "/domains/domains/list" },
						{ name: "Add", url: "/domains/domains/add" },
						{ name: "Transfers", url: "/domains/domains/transfers" },
					],
				},
				{
					topic: "Contacts",
					expanded: false,
					entries: [
						{ name: "List", url: "/domains/contacts/list" },
						{ name: "Add", url: "/domains/contacts/add" }
					],
				},
				{
					topic: "Nameservers",
					expanded: false,
					entries: [
						{ name: "List", url: "/domains/dns/list" },
						{ name: "Add", url: "/domains/dns/add" }
					],
				},
			]
		};
	},
};
</script>
<style lang="scss">
@import "../assets/scss/domains.scss";
</style>
