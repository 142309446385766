import { createToaster } from "@meforma/vue-toaster";
import Dropdown from "primevue/dropdown";
import Toggle from '@vueform/toggle'
// import Calendar from "primevue/calendar";
import { Calendar, DatePicker } from 'v-calendar';
import useVuelidate from '@vuelidate/core'
import * as dayjs from 'dayjs'
import { required, email, integer, helpers } from '@vuelidate/validators'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from "vuex"; //eslint-disable-line no-unused-vars
import ClientSource from '@/js/backend/client';
import countries from "../../js/countries";
import { ref, watch, onBeforeMount, onMounted, defineProps } from "vue";
import 'v-calendar/dist/style.css';

export default {

	props: {
		add: String,
	},

	components: {
		Dropdown,
		Toggle,
		DatePicker,
		Calendar
	},

	setup(props, { emit }) {

		const matrix = {
			"CL_ID": "",
			"CL_FIRSTNAME": "",
			"CL_LASTNAME": "",
			"CL_PWD_CLEAR": "",
			"CL_COMPANY": "",
			"CL_ADDRESS": "",
			"CL_ZIP": "",
			"CL_CITY": "",
			"CL_STATE": "",
			"CL_COUNTRY": "",
			"CL_VIES": "",
			"CL_TEXT": "",
			"CL_TEL": "",
			"CL_FAX": "",
			"CL_EMAIL": "",
			"CL_VATFREE": 0,
			"CL_STATUS": 2,
			"CL_DATA_OPTIN": 0,
			"CL_TEMP_PWD": "",
			"CL_TIER": 1,
			"CL_NL": 0,
			"CL_MANDAT": "",
		}		
		
		const dummy = {
			"CL_FIRSTNAME": "Uwe",
			"CL_LASTNAME": "Muster",
			"CL_PWD_CLEAR": "Pavonia23+-12",
			"CL_COMPANY": "Superduper Inc.",
			"CL_ADDRESS": "My little hole 33",
			"CL_ZIP": "33655",
			"CL_CITY": "Shithole",
			"CL_STATE": "NDS",
			"CL_COUNTRY": "DE",
			"CL_VIES": "DE12345678",
			"CL_TEXT": "",
			"CL_TEL": "+49-336-5566987",
			"CL_FAX": "",
			"CL_EMAIL": "test@someaddress.de",
			"CL_VATFREE": 0,
			"CL_STATUS": 1,
			"CL_DATA_OPTIN": 0,
			"CL_TEMP_PWD": "",
			"CL_TIER": 1,
			"CL_NL": 0,
			"CL_ROLE": 0,
			"CL_MANDAT": ""
		};
		
		const clientstatusselect = ref([
			{"id" : 0, "text": "Unconfirmed/Inactive"},
			{"id" : 1, "text" : "Billed"},
			{"id" : 2, "text" : "Prepaid"}
		])
		

		const tiers = ref([0,1,2,3]);
		const store = useStore()
		const toaster = createToaster({ position: 'top' });
		const router = useRouter()
		const route = useRoute()
		const loading = ref(false);
		const item = ref(matrix);
		const itemid = ref(null);
		const password1 = ref("");
		const password2 = ref("");
		let submitLabel = ref("Add");
		const datePickerAttributes = ref([{ 
			title: "MM YYYY" 
		}]);
		
		const isPhoneNumber = (value) => /^\+\d{1,4}-\d{1,5}-\d{3,12}$/.test(value)
		const isFaxNumber = (value) => !helpers.req(value) || /^\+\d{1,4}-\d{1,5}-\d{3,12}$/.test(value)

		const rules = {
			CL_FIRSTNAME: { required },
			CL_LASTNAME: { required },
			CL_TEL: { required, isPhoneNumber: helpers.withMessage("Must be in the format +XX-XXX-XXXXX", isPhoneNumber) },
			CL_FAX: { isFaxNumber: helpers.withMessage("Must be in the format +XX-XXX-XXXXX", isFaxNumber) },
			CL_EMAIL: { required, email },
			CL_ADDRESS: { required },
			CL_ZIP: { required },
			CL_CITY: { required },
			CL_COUNTRY: { required }
		}
		const v$ = useVuelidate(rules, item, { $lazy: true })

		watch(route, (first, to) => {
			if (to.params?.id && to.params?.id != itemid.value) {
				itemid.value = to.params.id
				getDetails()
				submitLabel.value = "Edit"
			}
			else {
				itemid.value = matrix;
				submitLabel.value = "Add"
			}
		})

		onMounted(() => {
			if (route.params?.id) {
				itemid.value = route.params.id
				submitLabel.value = "Edit"
				getDetails()
			}
			else {
				submitLabel.value = "Add"
			}
		})

		const resetData = () => {
			item.value = matrix;
			console.log("itemn has been reset to " , item.value)
			submitLabel.value = "Add"
		}

		const getDetails = () => {
			if (props.add == 1) {
				console.log("no data to fetch")
				return false;
			}
			loading.value = true;
			console.log("fetch data from hosting endpoint for id ", itemid.value)
			ClientSource.getClient(itemid.value)
				.then((response) => {
					item.value = response.data;
				})
				.catch((error) => {
					toaster.error("Could not fetch data ", error);
				})
				.finally(() => {
					loading.value = false;
				});
		}
		
		const editClient = async () => {
			const result = await(v$.value.$validate());
			if(result == false) {
				console.log("false", v$.value.$errors)
				return false;
			}
			if (item.value.CL_ID && item.value.CL_ID > 0) {
				ClientSource.editClient(item.value)
					.then((response) => {
						if (response.data.Error) {
							let err = response.data.Error.replace(/\n/g, "\n<br/>");
							toaster.error(
								"Could not edit Client: \n<br/>" + err
							);
							console.log(typeof(response.data.Error));
						} else {
							emit('updateList', item.value);
							toaster.success("Client updated");
							// getDetails(item.value.H_ID);
						}
					})
					.catch((error) => {
						toaster.error("Could not edit Client ", error);
					});
			} else {
				ClientSource.addClient(item.value)
					.then((response) => {
						if (response.data.Error) {
							toaster.error(
								"Could not add Client: \n<br/>" + response.data.Error
							);
							console.error(response.data.Error);
						} else {
							toaster.success("Client added");
							if(response.data.CL_ID) {
								item.value['CL_ID'] = response.data.CL_ID;
							}
							emit('addToList', item.value);
							router.push({
								path: "/accounting/clients/list",
							});
						}
					})
					.catch((error) => {
						toaster.error("Could not add Client ", error);
					});
			}
		}
		
		const setPassword = () => {
			if(password1.value == "" || password2.value == "" || password1.value != password2.value) {
				toaster.error("Passwords do not match");
				return false;
			}
			if(!item.value['CL_ID'] || item.value['CL_ID'] == '') {
				toaster.error("No Client ID");
				return false;
			}
			console.log(item.value.CL_ID, "--", password1.value)
			ClientSource.setPassword(item.value.CL_ID, password1.value)
			.then((response) => {
				if (response.data.Error) {
					toaster.error(
						"Could not set Password: \n<br/>" + response.data.Error
					);
					console.error(response.data.Error);
				} else {
					toaster.success("Password set");
				}
			})
			.catch((error) => {
				toaster.error("Could not set Password ", error);
			});
		}
		
		const setDummy = () => {
			item.value = dummy;
		}
		
		return {
			item,
			password1,
			password2,
			tiers,
			submitLabel,
			loading,
			countries,
			clientstatusselect,
			datePickerAttributes,
			editClient,
			setDummy,
			resetData,
			setPassword,
			v$
		}
	}
};
