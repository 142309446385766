<template>
	<vue-good-table
		mode="remote"
		:totalRows="totalRecords"
		:columns="columns"
		:rows="rows"
		:pagination-options="{
			enabled: true,
			mode: 'records',
			perPage: 20,
			position: 'both',
			perPageDropdown: [10, 20, 50],
			dropdownAllowAll: false,
			setCurrentPage: 1,
			nextLabel: 'next',
			prevLabel: 'prev',
			rowsPerPageLabel: 'Rows per page',
			ofLabel: 'of',
			pageLabel: 'page', // for 'pages' mode
			allLabel: 'All',
		}"
		v-on:page-change="onPageChange"
		v-on:sort-change="onSortChange"
		v-on:column-filter="onColumnFilter"
		v-on:per-page-change="onPerPageChange"
		styleClass="vgt-table striped"
	>
		<template #table-row="props">
			<span v-if="props.column.field == 'H_SERVERID'">
				<router-link :to="'/hosting/server/list?id=' + props.row.H_SERVERID">
					{{ serverlist[props.row.H_SERVERID] }}
				</router-link>
				<!-- <button class="btn btn-primary btn-sm" @click="showDetails(props.row)">
								Details
							</button> -->
			</span>
			<span v-else-if="props.column.field == 'H_PRICE'">
				{{ money(props.row.H_PRICE) }}
			</span>
			<span v-else-if="props.column.field == 'Action'">
				<router-link
					class="btn btn-primary btn-sm"
					:to="'/hosting/subscriptions/single/' + props.row.H_ID"
				>
					Details
				</router-link>
				<!-- <button class="btn btn-primary btn-sm" @click="showDetails(props.row)">
								Details
							</button> -->
			</span>
			<span v-else>
				{{ props.formattedRow[props.column.field] }}
			</span>
		</template>
	</vue-good-table>
</template>

<script>
import { VueGoodTable } from "vue-good-table-next";
import HostingSource from "@/js/backend/hosting";
import { useStore } from "vuex"; //eslint-disable-line no-unused-vars
import { createToaster } from "@meforma/vue-toaster";
// import { defineRule } from 'vee-validate';
import { ref, reactive, computed, watch, onMounted, onBeforeMount, defineProps } from "vue";

export default {
	name: "SubscriptionList",
	
	props: {
		showCancelled:Boolean
	},

	components: {
		VueGoodTable,
	},
	
	setup(props) {
		const store = useStore()
		const toaster = createToaster({ position: 'top' });
		let rows = ref([]); 
		const columns =  [
			{
				label: "",
				field: "H_STATUS",
				sortable: false,
				width: "14px",
				thClass: "status",
				tdClass: statusClassFunc,
			},
			{
				label: "",
				field: "H_CANCELLED",
				sortable: false,
				width: "14px",
				thClass: "status",
				tdClass: cancelledClassFunc,
				filterOptions: {
					customFilter: true,
				}
			},
			{ label: "ID", field: "H_ID", type: "number" },
			{ 
				label: "Client", 
				field: "CLIENT", 
				width: "140px",
				filterOptions: {
					styleClass: "class1", // class to be added to the parent th element
					enabled: true, // enable filter for this column
				},
			},
			{
				label: "Article",
				field: "ARTICLE",
				width: "140px",
				filterOptions: {
					styleClass: "class1", // class to be added to the parent th element
					enabled: true, // enable filter for this column
				},
			},
			{ label: "Name", field: "H_NAME", type: "string" },
			{ label: "Server", field: "H_SERVERID", type: "number" },
			{ label: "Price", field: "H_PRICE" },
			{ label: "Due", field: "H_DUE", type: "string" },
			{ label: "Action", field: "Action" },
		];
		let totalRecords = ref(0);
		let serverParams = ref({
			// a map of column filters example: {name: 'john', age: '20'}
			columnFilters: {},
			sort: [
				{
					field: "H_CANCELLED", // example: 'name'
					type: "desc", // 'asc' or 'desc'
				},
			],

			page: 1, // what page I want to show
			perPage: 20, // how many items I'm showing per page
		});
		
		const serverlist = computed(() => {
			let horst = {}
			for(let i in store.state.hosting.servers) {
				horst[store.state.hosting.servers[i].S_ID] = store.state.hosting.servers[i].S_NAME
			}
			return horst;
		});
	
		let showCancelled = ref(props.showCancelled)
		
		watch(() => props.showCancelled, (newVal, oldVal) => {
			console.log("showcancelled is ", props.showCancelled)
				if(newVal != oldVal) {
					if(newVal == true) {
						updateParams({ "columnFilters": { H_CANCELLED: 99 }});
					}
					else {
						updateParams({ "columnFilters": { H_CANCELLED: 0 }});
					}
					loadItems();
				}
		},{immediate:true});
	
		onBeforeMount(() => {
			store.dispatch("HOSTING_SERVERLIST", true);
		})
	
		onMounted(() => {
			console.log("is da: ", props.showCancelled)
			updateParams({ "columnFilters": { H_CANCELLED: 0 }});
			loadItems();
		});
		
		function money(betr) {
			const formatter = new Intl.NumberFormat('de-DE', {
				style: 'currency',
				currency: 'EUR',
				minimumFractionDigits: 2
			});
			return formatter.format(betr);
		}

		
		function updateParams(newProps) {
			serverParams = Object.assign({}, serverParams, newProps);
		}
		
		function onPageChange(params) {
			updateParams({ page: params.currentPage });
			loadItems();
		}
		
		function onPerPageChange(params) {
			updateParams({ perPage: params.currentPerPage });
			loadItems();
		}
		
		function onSortChange(params) {
			updateParams({
				sort: [
					{
						type: params[0].type,
						field: params[0].field,
					},
				],
				page: 1,
			});
			loadItems();
		}
	
		function onColumnFilter(params) {
			updateParams(params);
			loadItems();
		}
		
		function loadItems() {
			HostingSource.getSubscriptionList(serverParams).then((response) => {
				rows.value = response.data.rows
				totalRecords.value = response.data.totalRecords;
			})
			.catch((error) => {
				toaster.error("Could not fetch subscriptions ", error);
			});
		}
		
		function statusClassFunc(row) {
			if (row.H_STATUS == "1") {
				return "status status__success";
			} else if (row.H_STATUS == "2") {
				return "status status__progress";
			}
			return "status status__error";
		}

		function cancelledClassFunc(row) {
			if (row.H_CANCELLED == "0") {
				return "status status__success";
			}
			return "status status__error";
		}
		
		function updateList(data) {			
			console.log("updating in list: ", data)
			for (const obj of rows.value) {
				if (obj.H_ID === data.H_ID) {
					obj.H_CLIENTID = data.H_CLIENTID,
					obj.H_NAME = data.H_NAME,
					obj.H_SERVERID = data.H_SERVERID,
					obj.H_STATUS = data.H_STATUS,
					obj.H_PRICE = data.H_PRICE,
					obj.H_DUE = data.H_DUE
					break;
				}
			}
		}
		
		function addToList(data) {
			rows.value.push(data)
		}

		
		return {
			rows,
			totalRecords,
			columns,
			serverlist,
			money,
			addToList,
			updateList,
			cancelledClassFunc,
			statusClassFunc,
			onColumnFilter,
			onSortChange,
			onPerPageChange,
			onPageChange
		}
		
	}
}
</script>