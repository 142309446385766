// eslint-disable-next-line
import API from "./axios";
export default {
	// eslint-disable-next-line
	getClientList(params) {
		return API.get("/admin/clients/list",{params: params});
	},
	getClientShortList(params) {
		return API.get("/admin/clients/shortlist",{params: params});
	},
	getClient(id) {
		return API.get("/admin/clients/single/" + id);
	},
	editClient(data) {
		return API.post("/admin/clients/edit", data);
	},
	addClient(data) {
		return API.post("/admin/clients/add", data);
	},
	deleteClient(hid) {
		return API.post("/admin/clients/delete", {id: hid});
	},
	setPassword(id, pwd) {
		return API.post("/admin/clients/setpassword", {id: id, pwd: pwd});
	}
	
};
