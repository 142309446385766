import {
	mapState
} from "vuex"; //eslint-disable-line no-unused-vars
import "@/assets/scss/login.scss";

export default {
	data() {
		return {
			username: "",
			password: "",
			error: ""
		};
	},

	computed: {
		...mapState({
			loggedin: state => state.auth.loggedin,
			status: state => state.auth.status,
			userdata: state => state.auth.userdata
		})
	},

	created: function () {
		// console.log(this.loggedin)
		// console.log(process.env.VUE_APP_API_URL)
	},

	methods: {
		login: function () {
			let usererror = "";
			const {
				username,
				password
			} = this;
			var that = this;
			this.$store
				.dispatch("AUTH_REQUEST", {
					username,
					password
				})
				.then(result => {
					if (result.loginError) {
						usererror = result.loginError;
						console.error(result.loginError);
					} 
					else {
						if (localStorage.getItem("userData") == "") {
							usererror = "No matching user data available";
						}
					}
					if (usererror != "") {
						this.$toast.error("Error<br/>" + usererror)
					}
					else {
						let startpage = localStorage.getItem("lastPage") || "/";
						// startpage = "/";
						this.$toast.success("Hooray.<br/>Logged in")
						this.$forceUpdate();
						console.log(localStorage.getItem('userData'))
						this.$router.push(startpage);
					// that.$toastr.Add({
						// 	title: "Hooray.", // Toast Title
						// 	msg: "Logged in",
						// 	position: "toast-top-center",
						// 	type: "success"
						// });
						that.error = "";
					}
				})
				.catch(function (error) {
					let failtxt;
					console.log(error)
					if (error.status) {
						let perr = parseInt(error.status);
						if (perr == 400) {
							failtxt = "No access with this data";
						}
						else {
							if (error.data.error != "" && typeof (error.data.error) != 'undefined') {
								failtxt = error.data.error;
							}
							else {
								failtxt = "Server error";
							}
						}
					} else {
						failtxt = "Login failed.";
					}
					// console.error("hier auch nicht");
					that.$toast.error(failtxt, error)

					that.email = "";
					that.password = "";
					that.$forceUpdate();
				});
		}
	}
};