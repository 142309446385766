import Dropdown from "primevue/dropdown";
import ToggleButton from "primevue/togglebutton";
import { Calendar, DatePicker } from 'v-calendar';
import PBNSource from "@/js/backend/pbn";
import { mapState } from "vuex"; //eslint-disable-line no-unused-vars
import * as dayjs from 'dayjs'

const matrix = {
	H_NAME: "",
	M_BASEIP: "",
	M_COUNTRY: "",
	M_HID: 0,
	M_HOSTNAME: "",
	M_ID: 0,
	M_MAXDOMAINS: "",
	M_PROXY: 0,
	M_RAM: "",
	M_REMARKS: "",
	M_SERVERADMIN_PWD: "",
	M_SERVERADMIN_URL: "",
	M_SERVERADMIN_USER: "",
	M_SSH_PWD: "",
	M_SSH_USER: "",
	M_SYS: "",
	P_AMOUNT: "",
	P_CURRENCY: "",
	P_INTERVAL: "",
	P_NEXT_PAYMENT: "",
	P_SUBSCRIPTION: "0",
};

const dummy = {
	H_NAME: "Racknerd",
	M_BASEIP: "176.105.253.102",
	M_COUNTRY: "USA",
	M_HID: "89",
	M_HOSTNAME: "DUMMYYY",
	M_ID: "0",
	M_MAXDOMAINS: "6",
	M_PANEL_ADDRESS: "",
	M_PANEL_PWD: "",
	M_PANEL_USER: "",
	M_PROXY: "0",
	M_RAM: "1024",
	M_REMARKS: "CentOS 7",
	M_SERVERADMIN_PWD: "n5NEZRrAf2M5g7fX",
	M_SERVERADMIN_URL: "https://176.105.253.102:8083",
	M_SERVERADMIN_USER: "admin",
	M_SSH_PWD: "",
	M_SSH_USER: "root",
	M_SYS: "Vesta",
	P_AMOUNT: "399",
	P_CURRENCY: "USD",
	P_INTERVAL: "1",
	P_NEXT_PAYMENT: "2021-11-09",
	P_SUBSCRIPTION: "1",
};

export default {
	name: "PBNServerDetails",
	props: {
		itemid: Number,
	},
	components: {
		Dropdown,
		ToggleButton,
		Calendar,
		DatePicker
	},
	watch: {
		itemid: {
			immediate: true,
			// eslint-disable-next-line no-unused-vars
			handler(newVal, oldVal) {
				// console.log("new id passed to details ", newVal, " old ", oldVal);
				if (newVal != null && newVal != 0) this.getDetails(newVal);
				else {
					this.item = matrix;
				}
			},
		},
	},
	computed: {
		...mapState({
			hosts: (state) => state.pbn.hosts,
		}),
		isPaymentAuto: {
			get: function() {
				// console.log('P_SUBSCRIPTION is ',this.item.P_SUBSCRIPTION)
				return this.item.P_SUBSCRIPTION == "1";
			},
			// setter
			set: function(newValue) {
				console.log("set is ", newValue);
				this.item.P_SUBSCRIPTION = newValue == true ? "1" : "0";
				console.log("this.item.P_SUBSCRIPTION ", this.item.P_SUBSCRIPTION);
			},
		},
	},

	data() {
		return {
			hostinglist: [],
			host: null,
			loading: false,
			item: matrix,
			submitLabel: "Edit",
			datePickerAttributes: [{ 
				title: "MM YYYY" 
			}]
	
		};
	},

	created() {
		this.$store.dispatch("PBN_HOSTS");
		console.log(this.hosts);
	},

	methods: {
		getDetails(id) {
			this.loading = true;
			PBNSource.getServer(id)
				.then((response) => {
					this.item = response.data;
					this.loading = false;
					this.submitLabel = "Edit";
				})
				.catch((error) => {
					this.$toast.error("Could not fetch data ", error);
					this.loading = false;
				});
		},
		
		resetData() {
			this.item = matrix;
			this.submitLabel = "Edit";
		},
		
		editServer() {
			console.log("SEND ", this.item);

			// Changed date leads to object instead string
			if (typeof this.item.P_NEXT_PAYMENT == "object") {
				this.item.P_NEXT_PAYMENT = dayjs(this.item.P_NEXT_PAYMENT).format("YYYY-MM-DD");
				console.log(this.item.P_NEXT_PAYMENT);
			}

			if (this.item.M_ID && this.item.M_ID > 0) {
				PBNSource.editServer(this.item)
					.then((response) => {
						console.log(response.data.Error);
						if (response.data.Error) {
							this.$toast.error("Could not edit Server: \n<br/>" + response.data.Error);
							console.log(response.data.Error);
						} else {
							this.$toast.success("Server updated");
							this.$emit('updateList', this.item);
							this.getDetails(this.item.M_ID);
						}
					})
					.catch((error) => {
						this.$toast.error("Could not edit Server ", error);
					});
			}
			
			else {
				PBNSource.addServer(this.item)
					.then((response) => {
						if (response.data.Error) {
							this.$toast.error("Could not add Server: \n<br/>" + response.data.Error);
							console.log(response.data.Error);
						} else {
							if(response.data.M_ID) {
								this.item = response.data
								this.$emit('addToList', this.item);								
							}
							console.log("Emitting to addToList:"  , this.item)
							this.$toast.success("Server add");
							this.$router.push({ path: '/pbn/server/list?id=' + response.data.M_ID});
						}
					})
					.catch((error) => {
						this.$toast.error("Could not edit Server ", error);
					});
			}

			// $dayjs
		},
		
		deleteServer() {
			if(confirm("Are you absolutely sure? The world will be a different place")) {
				PBNSource.deleteServer(this.item.M_ID)
					.then((response) => {
						console.log(response.data.Error);
						if (response.data.Error) {
							this.$toast.error("Could not delete Server: \n<br/>" + response.data.Error);
							console.log(response.data.Error);
						} else {
							this.$toast.success("Server delete");
							this.$emit('removeFromList', this.item);
							this.item = matrix
							this.$router.push({ path: '/pbn/server/list'});
						}
					})
					.catch((error) => {
						this.$toast.error("Could not delete Server ", error);
					});
			}
		},
		
		getHosting(id) {
			PBNSource.getHostingList({"M_ID": id}).then(response => {
				this.totalRecords = response.data.totalRecords;
				this.hostinglist = response.data.rows;
				console.log(this.totalRecords + " records");
			})
			.catch((error) => {
				this.$toast.error("Could not get Hosting from Server ", error);
			});
		},
		
		setDummy() {
			this.item = dummy;
		}
	},
};
