<template>
	<div class="container-fluid pbn">
		<div class="row">
			<h1 class="text-center text-3xl font-extrabold leading-9 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
				Hosting Server
			</h1>
			<ul class="nav nav-tabs">
				<li :class="{ 'nav-item': true, 'active' : (active == 'list')}">
					<router-link :class="'nav-link'" aria-current="List" to="/hosting/server/list" @click="showTab('list')">List</router-link>
				</li>
				<li :class="{ 'nav-item': true, 'active' : (active == 'details')}">
					<router-link :class="'nav-link '" aria-current="Details" :to="detailurl" @click="showTab('details')">Details</router-link>
				</li>
				<li :class="{ 'nav-item': true, 'active' : (active == 'add')}">
					<router-link 
						:class="'nav-link '" 
						aria-current="Details" 
						to="/hosting/server/add"
						@click="prepareAdd"
						>Add</router-link>
				</li>
			</ul>
		</div>
		<div class="row content" id="list" v-show="active == 'details'">
			<div class="col">
				<HostingServerDetails add="0"  @updateList="updateListHandler"  @removeFromList="removeFromListHandler"/>
			</div>
		</div>
		<div class="row content" id="list" v-show="active == 'add'">
			<div class="col">
				<HostingServerDetails add="1" ref="hostingserveradd"  @addToList="addListHandler"/>
			</div>
		</div>

		<div class="row content goodtable" id="list" v-show="active == 'list'">
			<div class="col">
				<vue-good-table
					:columns="columns"
					:rows="rows"
					:pagination-options="{
						enabled: true,
						mode: 'records',
						perPage: 20,
						position: 'both',
						perPageDropdown: [10, 20, 50],
						dropdownAllowAll: false,
						setCurrentPage: 1,
						nextLabel: 'next',
						prevLabel: 'prev',
						rowsPerPageLabel: 'Rows per page',
						ofLabel: 'of',
						pageLabel: 'page', // for 'pages' mode
						allLabel: 'All',
					}"
					styleClass="vgt-table striped"
				>
					>
					<template #table-row="props">
						<span v-if="props.column.field == 'Action'">
							<router-link class="btn btn-primary btn-sm" :to='"/hosting/server/single/" + props.row.S_ID'>
								Details
							</router-link>
							<!-- <button class="btn btn-primary btn-sm" @click="showDetails(props.row)">
								Details
							</button> -->
						</span>
						<span v-else-if="props.column.field == 'S_PANEL_URL'">
							<a 
							v-if="props.row.S_PANEL_URL"
								class="btn btn-secondary btn-sm" 
								:href="props.row.S_PANEL_URL"
								@click="cpLogin(props.row.S_PANEL_PWD)"
								target="_new">
								<span class="pi pi-external-link"></span>
								Login
							</a>
							<!-- <button class="btn btn-primary btn-sm" @click="showDetails(props.row)">
								Details
							</button> -->
						</span>
						<span v-else>
							{{ props.formattedRow[props.column.field] }}
						</span>
					</template>
				</vue-good-table>
			</div>
		</div>
	</div>
</template>

<script src="./ServerList.js"></script>
