import PBNLayout from "@/Layouts/PBNLayout.vue";
import PBNServerDetails from "@/components/PBN/ServerDetails.vue";
import PBNServerAnalysis from "@/components/PBN/ServerAnalysis.vue";
import { VueGoodTable } from 'vue-good-table-next';
import PBNSource from '@/js/backend/pbn';
export default {

	name: 'Home',

	components: {
		PBNLayout,
		PBNServerDetails,
		PBNServerAnalysis,
		VueGoodTable
	},

	props: {
		msg: String
	},
	data() {
		return {
			baseurl: '',
			detailurl: '',
			//Good Table Stuff
			isLoading: false,
			columns: [
				{ label: 'ID', field: 'M_ID', type: 'number' },
				{
					label: 'Host',
					field: 'H_NAME',
					filterOptions: {
						styleClass: 'class1', // class to be added to the parent th element
						enabled: true, // enable filter for this column
					}
				},
				{
					label: 'IP',
					field: 'M_BASEIP',
					type: 'number',
					filterOptions: {
						styleClass: 'class1', // class to be added to the parent th element
						enabled: true, // enable filter for this column
					}
				},
				{
					label: 'Hostname',
					field: 'M_HOSTNAME',
					filterOptions: {
						styleClass: 'class1', // class to be added to the parent th element
						enabled: true, // enable filter for this column
					}
				},
				{ label: 'Used', field: 'Used', type: 'number' },
				{ label: 'Avail.', field: 'M_MAXDOMAINS', type: 'number' },
				{ label: 'Sys', field: 'M_SYS' },
				{ label: 'CP', field: 'M_SERVERADMIN_URL' },
				{ label: 'Auto', field: 'P_SUBSCRIPTION', type: 'number' },
				{ label: 'Action', field: 'Action' }
			],
			rows: [
			],
			active: 'list',
			mid: 0
		}
	},

	created() {
		this.baseurl = this.$route.path;
		this.getTab(this.$route.query);
		PBNSource.getServerList().then(response => {
			this.rows = response.data
			// console.log("GOT ", this.rows.length, " NOW")
		})
			.catch(error => {
				this.$toast.error('Could not fetch data ', error)
			});
	},

	mounted() {
	},

	beforeRouteUpdate(to, from, next) {  // eslint-disable no-unused-vars
		// react to route changes...
		// don't forget to call next()
		this.getTab(to.query);
		this.$forceUpdate();
		next();
	},

	methods: {
		getTab(addr) {
			if (!addr) {
				this.showTab('list');
			}
			else if (addr.id) {
				this.detailurl = this.$route.fullPath
				this.showDetails(parseInt(addr.id))
			}
			else if (addr.tab) {
				if (addr.tab == "add") {
					this.showTab('add');
				}
				else if (addr.tab == "analysis") {
					this.showTab('analysis');
				}
			}
			else {
				this.showTab('list');
			}

		},

		prepareAdd() {
			this.$refs.pbnserveradd.resetData();
			this.showTab('add');
		},
		
		
		showTab(which) {
			this.active = which;
			return which;
		},
		showDetails(id) {
			this.mid = id;
			this.showTab('details');
		},
		updateListHandler(data) {
			console.log("will now set ", data);
			for (const obj of this.rows) {
				if (obj.M_ID === data.M_ID) {
					obj.M_BASEIP = data.M_BASEIP,
					obj.M_SYS = data.M_SYS,
					obj.M_HOSTNAME = data.M_HOSTNAME,
					obj.M_MAXDOMAINS = data.M_MAXDOMAINS,
					obj.P_SUBSCRIPTION = data.P_SUBSCRIPTION
					break;
				}
			}
		},
		removeFromListHandler(data) {
			this.rows.splice(this.rows.findIndex(v => v.M_ID === data.M_ID), 1);
		},
		
		addServerHandler(data) {
			console.log("added data: ", data)
			this.rows.push(data);
			this.$forceUpdate()
		},
		
		cpLogin(pwd) {
			const el = document.createElement("textarea");
			el.value = pwd;
			document.body.appendChild(el);
			el.select();
			var successful = document.execCommand("copy");
			var msg = successful ? "successful" : "unsuccessful";
			document.body.removeChild(el);
			console.log(msg);

		}

	}

}
