import Dropdown from "primevue/dropdown";
import { createToaster } from "@meforma/vue-toaster";
import Toggle from '@vueform/toggle'
import DomainsSource from "@/js/backend/domains";
import { Calendar, DatePicker } from 'v-calendar';
import { useStore } from "vuex"; //eslint-disable-line no-unused-vars
// import { defineRule } from 'vee-validate';
import useVuelidate from '@vuelidate/core'
import { required, integer, helpers } from '@vuelidate/validators'
import { useRouter, useRoute } from 'vue-router'
import handleModal from "./handleModal.vue"
import { ref, reactive, computed, watch, onBeforeMount, onMounted, defineProps } from "vue";
import providers from "@/js/providers";
import 'v-calendar/dist/style.css';

export default {
	props: {
		add: String,
	},

	components: {
		Dropdown,
		DatePicker,
		Calendar,
		handleModal,
		Toggle
	},

	setup(props, { emit }) {
		name: "DomainAdd";
		
		const handlemodalwindow = ref();
		
		const store = useStore()
		const toaster = createToaster({ position: 'top' });
		const router = useRouter()
		const route = useRoute()

		const matrix = {
			DOM_PROVIDER: 0,
			DOM_CLIENTID: 0,
			DOM_NAME: '',
			DOM_TLD: '',
			DOM_IP: '',
			DOM_DNS: '',
			DOM_PERIOD: 0,
			DOM_OWNER_ID: 0,
			DOM_ZONEC_ID: 0,
			DOM_ADMINC_ID: 0,
			DOM_TECHC_ID: 0,
			DOM_OWNER_HANDLE: '',
			DOM_ADMIN_HANDLE: '',
			DOM_TECH_HANDLE: '',
			DOM_ZONE_HANDLE: '',
			DOM_AUTHCODE: '',
			DOM_AUTH_VALID: 0,
			DOM_RESPONSECODE: '',
			DOM_RESPONSETEXT: '',
			DOM_CREATED: null,
			DOM_EXPIRED: null,
			DOM_AUTORENEW: 0,
			DOM_STATUS: 0,
			DOM_DNSSEC: 0,
			DOM_PRIVACY: 0,
			DOM_REGISTRAR_STATUS: 0,
			DOM_REGISTRY_STATUS: 0,
			DOM_BILLING_PERIOD: 0,
			DOM_CANCEL: 0,
			DOM_DUE: null,
			NS1: '',
			NS2: '',
			NS3: '',
			NS4: '',
			NS5: '',
			NS6: '',
			DOM_MANUAL: 0
		};

		const dummy = {
			DOM_PROVIDER: 1,
			DOM_CLIENTID: 1,
			DOM_NAME: 'superding.de',
			DOM_TLD: 'de',
			DOM_IP: '192.168.1.1',
			DOM_DNS: '{"NS1": "ns1.hottentotten.de", "NS2": "ns2.hottentotten.de", "NS3": "ns3.hottentotten.de", "NS4": "ns4.hottentotten.de"}',
			DOM_PERIOD: 12,
			DOM_OWNER_ID: 1,
			DOM_ZONEC_ID: 16,
			DOM_ADMINC_ID: 16,
			DOM_TECHC_ID: 16,
			DOM_OWNER_HANDLE: '16603516',
			DOM_ADMIN_HANDLE: '16693553',
			DOM_TECH_HANDLE: '16693553',
			DOM_AUTHCODE: '16693553',
			DOM_AUTH_VALID: 1,
			DOM_RESPONSECODE: '',
			DOM_RESPONSETEXT: '',
			DOM_CREATED: '2022-03-31 18:01:22',
			DOM_EXPIRED: '2023-03-30 00:00:00',
			DOM_AUTORENEW: 1,
			DOM_STATUS: 1,
			DOM_DNSSEC: 1,
			DOM_PRIVACY: 0,
			DOM_REGISTRAR_STATUS: 'ACTIVE',
			DOM_REGISTRY_STATUS: 'LOCK',
			DOM_BILLING_PERIOD: 12,
			DOM_CANCEL: 0,
			DOM_DUE: '2023-03-30',
			DOM_MANUAL: 0
		};
		
		const owners = ref([{"NAME": "---", "CO_INTERNAL_ID": 0}]);
		const handles = ref([{"NAME": "---", "CO_INTERNAL_ID": 0}]);
		
		const itemid = ref(null)
		const isChecking = ref(false);
		const unAvailableClass = ref(false);
		const isAvailable = ref(0)
		const loading = ref(false);
		const item = ref();
		const statuslist = ref([
			{ 'STATUS_TEXT': 'Inactive', 'STATUS_ID': 0 },
			{ 'STATUS_TEXT': 'Active', 'STATUS_ID': 1 },
		]);
		const datePickerAttributes = ref([{ 
			title: "MM YYYY" 
		}]);

		const mustLargerThanZero = (value) => value != '0';
		// const isDate = (value) => /\d{4}-\d{2}-\d{2}/.test(value);
		const isDomainName = (value) => /^[^\.]+\.[a-z0-9]+(\.[a-z0-9]+)?/.test(value)
		let submitLabel = ref("Add");

		const rules = {
			DOM_NAME: { required,isDomainName, $lazy: true },
			DOM_TLD: { required },
			// DOM_EXPIRED: { required, isDate: helpers.withMessage("Must be a valid date", isDate) },
			// DOM_DUE: { required, isDate: helpers.withMessage("Must be a valid date", isDate) },
			DOM_OWNER_ID: { required, mustLargerThanZero: helpers.withMessage("Must be > 0", mustLargerThanZero) },
			DOM_CLIENTID: { required, integer, mustLargerThanZero: helpers.withMessage("Must be > 0", mustLargerThanZero) },
			DOM_PROVIDER: { required, integer },
			DOM_STATUS: { required, integer },
			DOM_CANCEL: { required, integer },	
			NS1: { required,isDomainName, $lazy: true },
			NS2: { required,isDomainName, $lazy: true },
		}

		const v$ = useVuelidate(rules, item, { $lazy: true })

		watch(route, (first, to) => {
			if (to.params?.id && to.params?.id != itemid.value) {
				itemid.value = to.params.id
				getDetails()
				submitLabel.value = "Edit"
			}
		})

		const clients = computed(() => store.state.accounting.clients);
		const default_nameservers = computed(() => store.state.domains.default_nameservers);
		const whois_privacy_enabled = computed(() => store.state.domains.whois_privacy_enabled);
		const showPrivacy = computed(() => {
			return (whois_privacy_enabled.value.includes(item.value.DOM_TLD));
		});

		onBeforeMount(() => {
			store.dispatch("CLIENTS", true);
			store.dispatch("DOMAINS_DEFAULT_NAMESERVERS", false);
			store.dispatch("DOMAINS_WHOIS_PRIVACY_ENABLED", false);
			item.value =JSON.parse(JSON.stringify(matrix));
		})

		onMounted(() => {
			if (route.params?.id) {
				itemid.value = route.params.id
				submitLabel.value = "Edit"
				getDetails()
			}
			else {
				submitLabel.value = "Add"
			}
		})

		const resetData = () => {
			item.value = JSON.parse(JSON.stringify(matrix));
			console.log("itemn has been reset to " , item.value)
			submitLabel.value = "Add"
		}
				
		
		const getDetails = () => {
			if (props.add == 1) {
				return false;
			}
			console.log("get details for ", itemid.value)
			loading.value = true;
			DomainsSource.getDomain(itemid.value)
				.then((response) => {
					item.value = response.data.domain;
				})
				.catch((error) => {
					toaster.error("Could not fetch data ", error);
				})
				.finally(() => {
					loading.value = false;
				});
		}

		const addDomain = async () => {
			const result = await(v$.value.$validate());
			if(result == false) {
				console.log("something ios happening")
				console.log(v$.value.$errors);
				return false;
			}
			DomainsSource.addDomain(item.value)
				.then((response) => {
					if (response.data.Error) {
						toaster.error(
							"Could not add Domain: \n<br/>" + response.data.Error
						);
						console.error(response.data.Error);
					} else {
						if(response.data.DOM_ID) {
							item.value.DOM_ID = response.data.DOM_ID;
						}
						toaster.success("Domain added");
						emit('addToList', item.value);
						router.push({
							path: "/domains/domains/list",
						});
					}
				})
				.catch((error) => {
					toaster.error("Could not add Server ", error);
				});
		}

		const checkAvailable = () => {
			
			isChecking.value = true
			DomainsSource.checkAvailable(item.value.DOM_NAME)
				.then(res => {
					if (!res || !res.data) {
						toaster.error("Cannot check this domain name\n<br/>No check data");
					}
					else {
						if(res.data.Custom) {
							isAvailable.value = 3;
							isChecking.value = false
						}
						else {
							if(res.data.check.summary == 'inactive') {
								isAvailable.value = 1
								isChecking.value = false
								item.value.DOM_TLD = res.data.check.zone;
								unAvailableClass.value = false
								
								if(res.data.registrar) {
									item.value.DOM_PROVIDER = parseInt(res.data.registrar.DP_REGISTRY);
									item.value.DOM_MANUAL = parseInt(res.data.registrar.DP_MANUAL);
									console.log("Provider ", item.value.DOM_PROVIDER)
								}
							}
							else {
								unAvailableClass.value = "unavailable"
								isAvailable.value = 2
								isChecking.value = false
							}
							// console.log(res)
						}
					}
				})
				.catch(error => {
					unAvailableClass.value = false
					isAvailable.value = false
					isChecking.value = false

					toaster.error(error);
				});
		}
		
		const showHandles = (type,field, provider,clientid) => {
			handlemodalwindow.value.getHandlesOrOwners(type,field,provider,clientid)
		}
		
		const addContactHandler = (coid, cohandle, field) => {
			console.log(`set new field ${coid}, ${cohandle}, ${field}`)
			item.value[field] = coid;
			if(field == 'DOM_OWNER_ID') {
				item.value.DOM_OWNER_HANDLE = cohandle
			} 
			else if(field == 'DOM_ADMINC_ID') {
				item.value.DOM_ADMIN_HANDLE = cohandle
			} 
			else if(field == 'DOM_TECHC_ID') {
				item.value.DOM_TECH_HANDLE = cohandle
			} 
			else if(field == 'DOM_ZONEC_ID') {
				item.value.DOM_ZONE_HANDLE = cohandle
			} 
		}
		
		const setDefaultNs = () => {
			if(item.value.DOM_PROVIDER == '') {
				toaster.error("Choose provider first");
				return false;
			}
			let ns = default_nameservers.value[item.value.DOM_PROVIDER];
			if(!ns) {
				toaster.error("Could not find default nameservers");
				return false;
			}
			for(let i = 0;i < ns.length;i++) {
				let x = i + 1;
				item.value[`NS${x}`] = ns[i];
			}
			updateDNS()
			console.log(default_nameservers.value[item.value.DOM_PROVIDER], "!!")
		}
		
		const updateDNS = () => {
			let newdns = {};
			for(let i = 1;i <= 6;i++) {
				newdns[`NS${i}`] = item.value[`NS${i}`];
			}
			item.value.DOM_DNS = JSON.stringify(newdns);
			console.log("UPDATE DNS ", newdns)
		}
		
		const setDummy = () => {
			item.value = JSON.parse(JSON.stringify(dummy));
			updateDNS()
		}
		const changeValueTest = () => {
			emit('addToList');

			
		}
		
		return {
			item,
			submitLabel,
			loading,
			clients,
			owners,
			handles,
			statuslist,
			handlemodalwindow,
			addDomain,
			checkAvailable,
			setDummy,
			changeValueTest,
			resetData,
			Calendar, 
			DatePicker,
			showHandles,
			setDefaultNs,
			addContactHandler,
			updateDNS,
			default_nameservers,
			whois_privacy_enabled,
			providers,
			datePickerAttributes,
			isChecking,
			unAvailableClass,
			isAvailable,
			showPrivacy,
			v$
		}
	}

};
