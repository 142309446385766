<template>
	<div
		id="pricesdomainsmodal"
		class="modal-dialog-scrollable modal-xl modal-fullscreen-lg-down transfermodal modal-dialog"
	>
		<div class="modal-content">
			<div class="container-fluid text-center">
				<div class="row mt-1">
					<div class="col">
						<h3 class="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
							Domain Prices for <b>{{ details.DP_TLD }}</b>
						</h3>
					</div>
					<div class="col" v-if="add">
						<input type="text" class="form-control" v-model="details.DP_TLD">
					</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="container-fluid text-center" v-if="loading">
					<div class="loader loader__sm">
						<div class="spinner-grow text-success" style="width: 2rem; height: 2rem" role="status"></div>
						<p class="text-primary">Loading...</p>
					</div>
				</div>
				<div class="container-fluid" style="min-height: 250px" v-else>
					<form @submit.prevent="editPrices">
						<div class="row">
							<div class="col col-4">
								<div class="title mb-2">Provider</div>
								<v-select 
									label="text" 
									:reduce="providers => providers.value" 
									:options="providers" 
									v-model="details.DP_REGISTRY">
								</v-select>
							</div>
							<div class="col col-4">
								<div class="title mb-2">NewTLD</div>
								<Toggle
									v-model="details.DP_ISNEWTLD"
									class="toggle-red"
									on-label="Yes"
									off-label="No"
									:falseValue="0"
									:trueValue="1"
								/>
							</div>
							<div class="col col-4">
								<div class="title mb-2">Popular</div>
								<Toggle
									v-model="details.DP_POPULAR"
									class="toggle-red"
									on-label="Yes"
									off-label="No"
									:falseValue="0"
									:trueValue="1"
								/>
							</div>
						</div>
						<div class="row">
							<div class="col col-4">
								<div class="title mb-2">WHOIS Privacy</div>
								<Toggle
									v-model="details.DP_WHOIS_PRIVACY"
									class="toggle-red"
									on-label="Yes"
									off-label="No"
									:falseValue="0"
									:trueValue="1"
								/>
							</div>
							<div class="col col-4">
								<div class="title mb-2">Manual</div>
								<Toggle
									v-model="details.DP_MANUAL"
									class="toggle-red"
									on-label="Yes"
									off-label="No"
									:falseValue="0"
									:trueValue="1"
								/>
							</div>
							<div class="col col-4">
								<div class="title mb-2">Years</div>
									<input type="text" class="form-control" v-model="details.DP_YEARS">
							</div>
						</div>
						<div class="row">
							<div class="col col-12">
								<table class="table table-striped">
									<thead>
										<tr>
											<th>Name</th>
											<th>Cost</th>
											<th>T1</th>
											<th>T2</th>
											<th>T3</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, idx) in formscheme" :key="idx">
											<th>{{item.Name}}</th>
											<td>
												<div class="input-group mb-3">
													<input type="number" step="0.01" class="form-control" v-model="details[`DP_COST_${item.Field}`]">
												  <span class="input-group-text" id="basic-addon2">/{{details_orig[`DP_COST_${item.Field}`]}}</span>
												</div>
											</td>
											<td>
												<div class="input-group mb-3">
													<input type="number" step="0.01" class="form-control" v-model="details[`DP_T1_${item.Field}`]">
												  <span class="input-group-text" id="basic-addon2">/{{details_orig[`DP_T1_${item.Field}`]}}</span>
												</div>
											</td>
											<td>
												<div class="input-group mb-3">
													<input type="number" step="0.01" class="form-control" v-model="details[`DP_T2_${item.Field}`]">
												  <span class="input-group-text" id="basic-addon2">/{{details_orig[`DP_T2_${item.Field}`]}}</span>
												</div>
											</td>
											<td>
												<div class="input-group mb-3">
													<input type="number" step="0.01" class="form-control" v-model="details[`DP_T3_${item.Field}`]">
												  <span class="input-group-text" id="basic-addon2">/{{details_orig[`DP_T3_${item.Field}`]}}</span>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="row">
							<div class="col col-3 text-start">
								<button type="button" class="btn btn-outline-secondary" @click="autoPrices">Auto Price</button>
							</div>
							<div class="col col-3 text-start">
								<button type="button" class="btn btn-outline-danger" @click="resetPrices">Reset prices</button>
							</div>
							<div class="col col-6 text-end">
								<button type="submit" class="btn btn-success">Save</button>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</template>

<script>
import vSelect from "vue-select";
import Toggle from "@vueform/toggle";
import "vue-select/dist/vue-select.css";
import ArticlesSource from "@/js/backend/articles";
import providers from "@/js/providers";
import { Modal } from "bootstrap";
import { createToaster } from "@meforma/vue-toaster";
import { ref, onBeforeMount, onMounted } from "vue";
export default {
	props: {},

	components: {
		Toggle,
		vSelect,
	},

	setup(props, { emit }) {
		name: "PricesDomainsModal";
		const toaster = createToaster({ position: "top-left" });
		const theModal = ref();
		const theschema = ref({
			DP_TLD: "",
			DP_REGISTRY: "",
			DP_ISNEWTLD: 0,
			DP_WHOIS_PRIVACY: 0,
			DP_POPULAR: 0,
			DP_MANUAL: 0,
			DP_YEARS: 1,
			DP_REGULATIONS: "",
			DP_COST_REG: 0,
			DP_T1_REG: 0,
			DP_T2_REG: 0,
			DP_T3_REG: 0,
			DP_COST_RENEW: 0,
			DP_T1_RENEW: 0,
			DP_T2_RENEW: 0,
			DP_T3_RENEW: 0,
			DP_COST_TRANSFER: 0,
			DP_T1_TRANSFER: 0,
			DP_T2_TRANSFER: 0,
			DP_T3_TRANSFER: 0,
			DP_COST_UPDATE: 0,
			DP_T1_UPDATE: 0,
			DP_T2_UPDATE: 0,
			DP_T3_UPDATE: 0,
			DP_COST_RESTORE: 0,
			DP_T1_RESTORE: 0,
			DP_T2_RESTORE: 0,
			DP_T3_RESTORE: 0,
			DP_COST_OWNERCHANGE: 0,
			DP_T1_OWNERCHANGE: 0,
			DP_T2_OWNERCHANGE: 0,
			DP_T3_OWNERCHANGE: 0,
			DP_COST_TRUSTEE_SERVICE: 0,
			DP_T1_TRUSTEE_SERVICE: 0,
			DP_T2_TRUSTEE_SERVICE: 0,
			DP_T3_TRUSTEE_SERVICE: 0,
		});
		const details = ref(JSON.parse(JSON.stringify(theschema.value)));
		const details_orig = ref(JSON.parse(JSON.stringify(theschema.value)));
		const formscheme = [
			{"Name": "Registration", "Field" : "REG"},
			{"Name": "Renew", "Field" : "RENEW"},
			{"Name": "Transfer", "Field" : "TRANSFER"},
			{"Name": "Update", "Field" : "UPDATE"},
			{"Name": "Restore", "Field" : "RESTORE"},
			{"Name": "Owner Change", "Field" : "OWNERCHANGE"},
			{"Name": "Trustee Service", "Field" : "TRUSTEE_SERVICE"},
		]
		const providername = ref();
		const providerByNum = ref({});
		const error = ref(false);
		const errors = ref([]);
		const loading = ref(false);
		const add = ref(false);


		onBeforeMount(() => {
			for(let i = 0; i < providers.length;i++) {
				providerByNum.value[providers[i].value] = providers[i].text
			}
		});

		onMounted(() => {
			theModal.value = new Modal(document.getElementById("PricesDomainsModal"));
		});
		
		const showNew = () => {
			add.value = true
			details.value = JSON.parse(JSON.stringify(theschema.value));
		}

		const showDetails = (tld) => {
			add.value = false
			ArticlesSource.getDomainPrices(tld)
				.then(res => {
					if (!res || !res.data) {
						toaster.error("Cannot get Domain Info: ");
					} else if (res.data.Error) {
						toaster.error(`Error: ${res.data.Error}`);
					} else {
						details.value = res.data;
						details_orig.value = JSON.parse(JSON.stringify(details.value))
					}
				})
				.catch(error => {
					toaster.error(error, "Error getting Contactlist");
				});
		};

		const autoPrices = () => {
			const steps = [
				{
					"max": 10,
					"t1": 7,
					"t2": 5,
					"t3": 2
				},
				{
					"max": 20,
					"t1": 9,
					"t2": 6,
					"t3": 4
				},
				{
					"max": 50,
					"t1": 14,
					"t2": 10,
					"t3": 7
				},
				{
					"max": 800,
					"t1": 20,
					"t2": 12,
					"t3": 9
				} 
			]
			for(let i = 0;i < formscheme.length;i++) {
				let fname = formscheme[i].Field;
				let cost = details.value[`DP_COST_${fname}`];
				if(cost == 0) {
					continue;
				}
				let newt1 = 0;
				let newt2 = 0;
				let newt3 = 0;
				for(let u = 0;u < steps.length;u++) {
					if(cost <= steps[u].max) {
						details.value[`DP_T1_${fname}`] = (Math.ceil(details.value[`DP_COST_${fname}`]) + steps[u].t1 - 0.1);
						details.value[`DP_T2_${fname}`] = (Math.ceil(details.value[`DP_COST_${fname}`]) + steps[u].t2 - 0.1);
						details.value[`DP_T3_${fname}`] = (Math.ceil(details.value[`DP_COST_${fname}`]) + steps[u].t3 - 0.1);
						break;
					}
				}
				console.log(fname + " == " + newt1 + " == " + newt2 + " == " + newt3);
			}
		}

		const resetPrices = () => {
			for(let a = 1;a <= 3;a++) {
				for(let i = 0;i < formscheme.length;i++) {
					let fname = formscheme[i].Field;
					details.value[`DP_T${a}_${fname}`] = details_orig.value[`DP_T${a}_${fname}`]
				}
			}
		}
		
		const editPrices = () => {
			if(add.value == true) {
				ArticlesSource.addDomainPrices(details.value)
					.then((response) => {
						if (response.data.Error) {
							let err = response.data.Error.replace(/\n/g, "\n<br/>");
							toaster.error(
								"Could not add Prices: \n<br/>" + err
							);
							console.log(typeof(response.data.Error));
						} else {
							emit('addToList', details.value);
							toaster.success("Prices added");
							// getDetails(item.value.H_ID);
						}
					})
					.catch((error) => {
						toaster.error("Could not edit Prices ", error);
					});
			}
			else {
				ArticlesSource.setDomainPrices(details.value)
					.then((response) => {
						if (response.data.Error) {
							let err = response.data.Error.replace(/\n/g, "\n<br/>");
							toaster.error(
								"Could not edit Prices: \n<br/>" + err
							);
							console.log(typeof(response.data.Error));
						} else {
							emit('updateList', details.value);
							toaster.success("Prices updated");
							// getDetails(item.value.H_ID);
						}
					})
					.catch((error) => {
						toaster.error("Could not edit Prices ", error);
					});
				
			}
		}
		
		return {
			add,
			error,
			errors,
			showDetails,
			showNew,
			autoPrices,
			resetPrices,
			editPrices,
			loading,
			details,
			details_orig,
			providers,
			providername,
			formscheme
		};
	},
};
</script>

<style lang="scss">
@import "../../assets/scss/main.scss";
#detailsmodal {
	.modal-content {
		font-size: 1rem;
		padding: 5px;
	}

	.row.error {
		border: 1px solid $color-error;
		li {
			font-size: 16px;
			color: $color-error;
		}
	}

	.copy {
		border: 1px solid $color-success;
		color: $color-success;
		border-radius: 0.2rem;
		padding: 0.3rem;
		&:hover {
			cursor: pointer;
			color: $color-success-hover;
			border: 1px solid $color-success-hover;
		}
	}
}
</style>
