<template>
	<div class="pbn" id="hostingdetails">
		<h2 v-if="item.HO_ID > 0">Hosting #{{ item.HO_ID }}</h2>
		<h2 v-else>Add Hosting</h2>
		<div class="container-fluid" v-if="loading">
			<div class="loader">
				<div
					class="spinner-grow text-success"
					style="width: 6rem; height: 6rem"
					role="status"
				></div>
				<p class="text-primary">Loading...</p>
			</div>
		</div>
		<div class="container-fluid details" v-else>
			<form @submit.prevent="editHosting">
				<div class="row">
					<div class="col col-6">
						Client: <br /><Dropdown
							v-model="item.HO_CLID"
							:options="clients"
							optionLabel="CL_NAME"
							optionValue="CL_ID"
							placeholder="Select one"
						/>
					</div>
					<div class="col col-6">
						<label for="hostname" class="control-label">Domain</label>
						<input
							type="text"
							class="form-control"
							id="domain"
							v-model="item.HO_DOMAIN"
						/>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						IP: <br /><Dropdown
							:filter="true"
							v-model="item.HO_IP"
							:options="iplist"
							optionLabel="IP_TEXT"
							optionValue="M_BASEIP"
							placeholder="Select one"
						/>
					</div>
					<div class="col col-5">
						DNS: <br /><Dropdown
							:filter="true"
							v-model="item.HO_DNSID"
							:options="dnsproviders"
							optionLabel="DNS_NAME"
							optionValue="DNS_ID"
							placeholder="Select one"
						/>
						<div class="collapse" :class="{show: item.HO_CF_DNS1 != ''}" id="customdns">
							<div class="container-fluid" style="">
								<div class="row">
									<div class="col col-6">
										<label for="HO_CF_DNS1" class="control-label"
											>Custom DNS 1</label
										>
										<input
											type="text"
											class="form-control"
											name="HO_CF_DNS1"
											id="HO_CF_DNS1"
											v-model="item.HO_CF_DNS1"
										/>
									</div>
									<div class="col col-6">
										<label for="HO_CF_DNS2" class="control-label"
											>Custom DNS 2</label
										>
										<input
											type="text"
											class="form-control"
											name="HO_CF_DNS2"
											id="HO_CF_DNS2"
											v-model="item.HO_CF_DNS2"
										/>
									</div>
								</div>
								<div class="row">
									<div class="col col-6">
										<label for="HO_CF_DNS3" class="control-label"
											>Custom DNS 3</label
										>
										<input
											type="text"
											class="form-control"
											name="HO_CF_DNS3"
											id="HO_CF_DNS3"
											v-model="item.HO_CF_DNS3"
										/>
									</div>
									<div class="col col-6">
										<label for="HO_CF_DNS4" class="control-label"
											>Custom DNS 4</label
										>
										<input
											type="text"
											class="form-control"
											name="HO_CF_DNS4"
											id="HO_CF_DNS4"
											v-model="item.HO_CF_DNS4"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col col-1">
						&nbsp;
						<br />
						<button
							type="button"
							class="btn btn-info btn-sm"
							data-bs-target="#customdns"
							data-bs-toggle="collapse"
						>
							Custom
						</button>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<label for="HO_USERNAME" class="control-label"
							>FTP User:</label
						>
						<input
							type="text"
							class="form-control"
							name="HO_USERNAME"
							id="HO_USERNAME"
							v-model="item.HO_USERNAME"
						/>
					</div>
					<div class="col col-6">
						<label for="HO_PASSWD" class="control-label"
							>FTP Pwd:</label
						>
						<input
							type="text"
							class="form-control"
							name="HO_PASSWD"
							id="HO_PASSWD"
							v-model="item.HO_PASSWD"
						/>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<label for="HO_WP_USER" class="control-label"
							>WP User:</label
						>
						<input
							type="text"
							class="form-control"
							name="HO_WP_USER"
							id="HO_WP_USER"
							v-model="item.HO_WP_USER"
						/>
					</div>
					<div class="col col-6">
						<label for="HO_WP_PASSWD" class="control-label"
							>WP Pwd:</label
						>
						<input
							type="text"
							class="form-control"
							name="HO_WP_PASSWD"
							id="HO_WP_PASSWD"
							v-model="item.HO_WP_PASSWD"
						/>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						Status: <br /><Dropdown
							v-model="item.HO_STATUS"
							:options="statuslist"
							optionLabel="STATUS_TEXT"
							optionValue="STATUS_ID"
							placeholder="Select one"
						/>
					</div>
					<div class="col col-6">
					</div>
				</div>
				<div class="row">
					<div class="col col-12">
						<label for="M_REMARKS" class="control-label">Installer</label>
						<textarea
							class="form-control"
							name="installer"
							id="installer"
							style="height: 100px"
							v-model="installerstring"
						></textarea>
					</div>
				</div>
				<div class="row">
					<div class="col col-4 text-center">
						<input
							class="btn btn-primary"
							type="submit"
							name="su"
							:value="submitLabel"
						/>
					</div>
					<div class="col col-4 text-center">
						<button type="button" class="btn btn-danger" @click="deleteHosting">
							Delete
						</button>
					</div>
					<div class="col col-4 text-center" v-if="item.HO_ID < 1">
						<button type="button" class="btn btn-warning" @click="setDummy">
							Dummy
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script setup>
import Dropdown from "primevue/dropdown";
import { createToaster } from "@meforma/vue-toaster";
import { useStore } from "vuex"; //eslint-disable-line no-unused-vars
import { useRouter } from 'vue-router'
import PBNSource from "@/js/backend/pbn";
import { ref, watch, computed, onBeforeMount,onMounted } from 'vue';
import * as dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration);

const matrix = {
	HO_ID: 0,
	HO_CLID: 0,
	HO_IP: "",
	HO_DNSID: 0,
	HO_CF_DNS1: "",
	HO_CF_DNS2: "",
	HO_CF_DNS3: "",
	HO_CF_DNS4: "",
	HO_DOMAIN: "",
	HO_USERNAME: "",
	HO_PASSWD: "",
	HO_WP_USER: "",
	HO_WP_PASSWD: "",
	HO_PANEL_URL: "",
	HO_STATUS: 0,
	HO_BACKUP_FILENAME: "",
	HO_BACKUP_LASTCHECK: "",
	HO_BACKUP_OK: 0,
};

const dummy = {
	HO_CLID: 3,
	HO_IP: "127.0.0.1",
	HO_DNSID: 12,
	HO_CF_DNS1: "",
	HO_CF_DNS2: "",
	HO_CF_DNS3: "",
	HO_CF_DNS4: "",
	HO_DOMAIN: "dummytest.gg",
	HO_USERNAME: "idiot",
	HO_PASSWD: "test1234",
	HO_WP_USER: "trottel",
	HO_WP_PASSWD: "passworT123",
	HO_PANEL_URL: "https://nix.da",
	HO_STATUS: 0,
	HO_BACKUP_FILENAME: "",
	HO_BACKUP_LASTCHECK: "",
	HO_BACKUP_OK: 0,
};

const name = "PBNHostingDetails";
let submitLabel = ref("Edit");

const props = defineProps({
		itemid: Number,
		add: Boolean
});
const toaster = createToaster({ position: 'top' });

const emit = defineEmits(['updateList'])

watch(() => props.itemid, (newVal, oldVal) => {
	console.log("itemid is ", props.itemid)
	if(newVal != oldVal) {
		if (newVal != null && newVal != 0) {
			getDetails(newVal);
			submitLabel.value = "Edit"
		}
		else {
			submitLabel.value = "Add"
		}
	}
});

const router = useRouter()

const store = useStore()
const dnsproviders = computed(() => store.state.pbn.dnsproviders);
const clients = computed(() => store.state.pbn.pbn_clients);
const iplist = computed(() => store.state.pbn.iplist);

const host = ref(null);
const loading = ref(false);
const item= ref(matrix);
const isCustomDNS = ref(false);
const installerstring = ref('');
const statuslist = ref([
	{'STATUS_TEXT': 'Not ready', 'STATUS_ID': 0},
	{'STATUS_TEXT': 'In progress', 'STATUS_ID': 2},
	{'STATUS_TEXT': 'Ready', 'STATUS_ID': 1},
]);


onBeforeMount(() => {
	store.dispatch("PBN_CLIENTS", true);
	store.dispatch("PBN_DNS", true);
	store.dispatch("PBN_IPLIST", true);
});

onMounted(() => {
	submitLabel.value = (props.itemid > 0) ? "Edit" : "Add" 
	console.log("Mounted with add=", props.add, "submitLabel = ", submitLabel.value)
	getDetails(props.itemid)
});

function getDetails(id) {
	if (props.add == 1 || id == 0) {
		return false;
	}
	loading.value = true;
	PBNSource.getHosting(id)
		.then((response) => {
			item.value = response.data;
			console.log(item);
			loading.value = false;
			setInstallerString()
		})
		.catch((error) => {
			toaster.error("Could not fetch data ", error);
			loading.value = false;
		});
};

function resetData() {
	item.value = matrix;
	submitLabel = "Add"
}

function editHosting() {
	// Changed date leads to object instead string
	if (typeof item.value.P_NEXT_PAYMENT == "object") {
		item.value.P_NEXT_PAYMENT = dayjs(item.value.P_NEXT_PAYMENT).format(
			"YYYY-MM-DD"
		);
		// console.log(this.item.P_NEXT_PAYMENT);
	}

	if (item.value.HO_ID && item.value.HO_ID > 0) {
		PBNSource.editHosting(item.value)
			.then((response) => {
				if (response.data.Error) {
					toaster.error(
						"Could not edit Server: \n<br/>" + response.data.Error
					);
					console.log(response.data.Error);
				} else {
					// Get Current DNS Name and Client name from the Dropdown Arrays
					const result = dnsproviders.value.find( ({ DNS_ID }) => DNS_ID === item.value.HO_DNSID );
					item.value.DNS_NAME = result.DNS_NAME
					const cl = clients.value.find( ({ CL_ID }) => CL_ID === item.value.HO_CLID );
					item.value.CLIENT = cl.CL_NAME
					console.log("saved ", item.value);
					emit('updateList', item.value);
					toaster.success("Server updated");
					getDetails(item.value.HO_ID);
				}
			})
			.catch((error) => {
				toaster.error("Could not edit Server ", error);
			});
	} else {
		PBNSource.addHosting(item.value)
			.then((response) => {
				console.log(response.data.Error);
				if (response.data.Error) {
					toaster.error(
						"Could not add Hosting: \n<pre>" + response.data.Error + "</pre>"
					);
					console.log(response.data.Error);
				} else {
					toaster.success("Hosting added");
					emit('addToList', item.value);
					router.push({
						path: "/pbn/hosting/list",
					});
				}
			})
			.catch((error) => {
				toaster.error("Could not edit Server ", error);
			});
	}
	// $dayjs
}

function deleteHosting() {
	if (
		confirm("Are you absolutely sure? The world will be a different place")
	) {
		PBNSource.deleteHosting(item.value.HO_ID)
			.then((response) => {
				console.log(response.data.Error);
				if (response.data.Error) {
					toaster.error(
						"Could not delete Hosting: \n<br/>" + response.data.Error
					);
					console.log(response.data.Error);
				} else {
					toaster.success("Hosting deleted");
					emit('removeFromList', item.value.HO_ID);
					item.value = matrix;
					router.push({ path: "/pbn/hosting/list" });
				}
			})
			.catch((error) => {
				toaster.error("Could not delete Server ", error);
			});
	}
};
		
function setInstallerString() {
	installerstring.value = 
`/*
ssh root@${item.value.HO_IP}  "bash --login -c 'v-delete-user ${item.value.HO_USERNAME}'"
*/
$domain = "${item.value.HO_DOMAIN}";
$ip = "${item.value.HO_IP}";
$user = "${item.value.HO_USERNAME}";
$wpuser = "${item.value.HO_WP_USER}";
$wppwd = "${item.value.HO_WP_PASSWD}";
/*
rsync -ua installer root@${item.value.HO_IP}:~/
ssh root@${item.value.HO_IP}
./installer/install.php --todo domain
*/
`;
}

function setDummy() {
	item.value = dummy;
}


defineExpose({resetData})

</script>

<style lang="scss" scoped>
@import '@/assets/scss/main.scss';

#hostingdetails {
	.collapse .container-fluid {
		padding-left:0;
		.col {
			padding-left:0;
		
		}
	}
}
</style>