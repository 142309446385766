import Dropdown from "primevue/dropdown";
import { createToaster } from "@meforma/vue-toaster";
import Toggle from '@vueform/toggle'
import DomainsSource from "@/js/backend/domains";
import { Calendar, DatePicker } from 'v-calendar';
import { useStore } from "vuex"; //eslint-disable-line no-unused-vars
// import { defineRule } from 'vee-validate';
import useVuelidate from '@vuelidate/core'
import { required, integer, helpers } from '@vuelidate/validators'
import { useRouter, useRoute } from 'vue-router'
import providers from "../../js/providers";
import { ref, reactive, computed, watch, onBeforeMount, onMounted, defineProps } from "vue";
import 'v-calendar/dist/style.css';
import countries from "../../js/countries";

export default {
	props: {
		add: String,
	},

	components: {
		Dropdown,
		DatePicker,
		Calendar,
		Toggle
	},

	setup(props, { emit }) {
		name: "DomainDetails";

		const store = useStore()
		const toaster = createToaster({ position: 'top' });
		const router = useRouter()
		const route = useRoute()

		const matrix = {
			DOM_PROVIDER: 0,
			DOM_CLIENTID: 0,
			DOM_NAME: '',
			DOM_TLD: '',
			DOM_IP: '',
			DOM_DNS: '',
			DOM_DNS_TEXT: '',
			DOM_PERIOD: 0,
			DOM_OWNER_ID: 0,
			DOM_ZONEC_ID: 0,
			DOM_ADMINC_ID: 0,
			DOM_TECHC_ID: 0,
			DOM_OWNER_HANDLE: '',
			DOM_ADMIN_HANDLE: '',
			DOM_TECH_HANDLE: '',
			DOM_AUTHCODE: '',
			DOM_AUTH_VALID: 0,
			DOM_RESPONSECODE: '',
			DOM_RESPONSETEXT: '',
			DOM_CREATED: null,
			DOM_EXPIRED: null,
			DOM_AUTORENEW: 0,
			DOM_STATUS: 0,
			DOM_DNSSEC: 0,
			DOM_PRIVACY: 0,
			DOM_REGISTRAR_STATUS: 0,
			DOM_REGISTRY_STATUS: 0,
			DOM_BILLING_PERIOD: 0,
			DOM_CANCEL: 0,
			DOM_DUE: null
		};

		const parsedValues = ref({
			clientname: "",
			provider: "",
		})
		
		const itemid = ref(null)
		const loading = ref(false);
		const item = ref(matrix);
		const owners = ref([]);
		const handles = ref([]);
		const registryDetails = ref();
		const registryLoading = ref(false);
		const statuslist = ref([
			{ 'STATUS_TEXT': 'Inactive', 'STATUS_ID': 0 },
			{ 'STATUS_TEXT': 'Active', 'STATUS_ID': 1 },
		]);
		const datePickerAttributes = ref([{ 
			title: "MM YYYY" 
		}]);

		const mustLargerThanZero = (value) => value != '0';
		const rules = {
			DOM_DNS_TEXT: { required, $lazy: true },
			DOM_OWNER_ID: { required, mustLargerThanZero: helpers.withMessage("Must be > 0", mustLargerThanZero) },
		}

		const v$ = useVuelidate(rules, item, { $lazy: true })
		
		watch(route, (first, to) => {
			if (to.params?.id && to.params?.id != itemid.value) {
				itemid.value = to.params.id
				getDetails()
			}
		})

		const clients = computed(() => store.state.accounting.clients);

		onBeforeMount(() => {
			store.dispatch("CLIENTS", true);
		})

		onMounted(() => {
			if (route.params?.id) {
				itemid.value = route.params.id
				getDetails()
			}
		})

		const resetData = () => {
			item.value = matrix;
			registryDetails.value = false;
			// console.log("item has been reset to " , item.value)
		}
				
		
		const getDetails = () => {
			if (props.add == 1) {
				return false;
			}
			resetData();
			// console.log("get details for ", itemid.value)
			loading.value = true;
			DomainsSource.getDomain(itemid.value)
				.then((response) => {
					console.log("getting ", itemid.value)
					item.value = response.data.domain;
					const cldata = clients.value.find( ({ CL_ID }) => CL_ID ===  item.value.DOM_CLIENTID);
					parsedValues.value.clientname = cldata.NAME
					const provdata = providers.find( ({ value }) => value ===  item.value.DOM_PROVIDER);
					console.log("getting ", provdata)
					parsedValues.value.provider = provdata.text
					console.log(parsedValues.value.provider, "!!")
					item.value.DOM_DNS_TEXT = item.value.DOM_DNS;
				// 	const dns = JSON.parse(item.value.DOM_DNS)
				// 	console.log("DNS is ", typeof(dns));
				// 	console.log("DNS is ", dns);
				// if(dns != null && typeof(dns) == 'object') {
				// 		const dnss = Object.values(dns)
				// 		console.log("DNSS is ", typeof(dns));
				// 		console.log("DNSS is ", dnss);
				// 			item.value.DOM_DNS_TEXT = JSON.stringify(dnss.join("\n"));
				// 	}
					getContacts(item.value.DOM_PROVIDER, item.value.DOM_CLIENTID, 'owner');
					getContacts(item.value.DOM_PROVIDER, item.value.DOM_CLIENTID, 'noowner');
				})
				.catch((error) => {
					toaster.error("Could not fetch data ", error);
				})
				.finally(() => {
					loading.value = false;
				});
		}
		
		const getContacts = (provider,clid,type) => {
			console.log("show handles for ", provider,clid,type)
			DomainsSource.getContactsShortList(provider,clid,type)
				.then((response) => {
					if(type == "owner") {
						owners.value = response.data.contacts
					}
					else {
						handles.value = response.data.contacts
					}
				})
				.catch((error) => {
					toaster.error("Could not fetch contacts ", error);
				})
		}

		const editDomain = async () => {
			console.log("Bloofy hell!!!!")
			const result = await(v$.value.$validate());
			if(result == false) {
				console.log(v$.value.$errors);
				return false;
			}
			// const nslist = item.value.DOM_DNS_TEXT.trim();
			// let nsall = nslist.split("\n");
			// let nsarr = {};
			// if(nsall.length > 1) {
			// 	for(let i = 0;i < nsall.length;i++) {
			// 		let idx = i + 1;
			// 		nsarr[`NS${idx}`] = nsall[i]
			// 	}
			// item.value.DOM_DNS = JSON.stringify(nsarr);
			item.value.DOM_DNS = item.value.DOM_DNS_TEXT;
		// }
			console.log("DOM_DNS VALUE is ", item.value.DOM_DNS , "\n");
			DomainsSource.editDomain(item.value)
				.then((response) => {
					console.log(response.data)
					if (response.data.Error) {
						toaster.error(
							"Could not edit Domain: \n<br/>" + response.data.Error
						);
						console.log(response.data.Error);
					} else {
						emit('updateList', item.value);
						toaster.success("Domain updated");
						// getDetails(item.value.H_ID);
					}
				})
				.catch((error) => {
					toaster.error("Could not edit Domain ", error);
				});
		}
		
		const changeOwner = () => {
			DomainsSource.changeOwner(item.value)
			.then((response) => {
				if (response.data.Error) {
					toaster.error(
						"Could not Change Owner for Domain: \n<br/>" + response.data.Error
					);
					console.log(response.data.Error);
				} else {
					emit('updateList', item.value);
					toaster.success("Domain updated");
					// getDetails(item.value.H_ID);
				}
			})
			.catch((error) => {
				toaster.error("Could not Change Owner for Domain ", error);
			});	
		}
		

		const deleteDomain = () => {
			if (confirm("Are you absolutely sure? The world will be a different place")) {
				DomainsSource.deleteDomain(item.value.DOM_ID)
					.then((response) => {
						console.error(response.data.Error);
						if (response.data.Error) {
							toaster.error(
								"Could not delete Domain: \n<br/>" + response.data.Error
							);
							console.error(response.data.Error);
						} else {
							toaster.success("Domain deleted");
							item.value = matrix;
							router.push({ path: "/domains/domains/list" });
						}
					})
					.catch((error) => {
						toaster.error("Could not delete Domain ", error);
					});
			}
		}
		
		const updateDNS = () => {
			let newdns = {};
			for(let i = 1;i <= 6;i++) {
				newdns[`NS${i}`] = item.value[`NS${i}`];
			}
			item.value.DOM_DNS = JSON.stringify(newdns);
			console.log("UPDATE DNS ", newdns)
		}

		const getFromRegistry = () => {
			registryLoading.value = true;
			DomainsSource.getDomainInfo(item.value.DOM_ID)
				.then((response) => {
					registryDetails.value = response.data
					const dns = []
					if(registryDetails.value.nameservers != null) {
						for(let i = 0;i < registryDetails.value.nameservers.length;i++) {
							dns.push(registryDetails.value.nameservers[i].name)
						}
						registryDetails.value.dns = dns.join("\n");
					}
					// Check if the expire date is correctly formatted!
					if(!/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/.test(registryDetails.value.expires)) {
						registryDetails.value.expires = null
					}
				})
				.catch((error) => {
					toaster.error("Could not get Info from registry ", error);
				})
				.finally(() => {
					registryLoading.value = false;
				})
			
			return true;
		}
		
		const setValue = (item_name,item_value) => {
			if(item_name == "DOM_DNS") {
				const dnslist = item_value.split("\n");
				let dnsobj = {};
				for(let i = 0;i < dnslist.length;i++) {
					let u = i+1;
					dnsobj[`NS${u}`] = dnslist[i];
				}
				item_value = JSON.stringify(dnsobj);
			}
			else if(item_name == 'DOM_ADMINC_ID' || item_name == 'DOM_TECHC_ID' || item_name == 'DOM_ZONEC_ID') {
				const reg = new RegExp("\[" + item_value + "\]");
				const cdata = handles.value.find( ({ text }) => text.match(reg));
				console.log("FOUND ", cdata)
				item_value = cdata.value;
			}
			else if(item_name == 'DOM_OWNER_ID') {
				const reg = new RegExp("\[" + item_value + "\]");
				const cdata = owners.value.find( ({ text }) => text.match(reg));
				console.log("FOUND ", cdata)
				item_value = cdata.value;
			}
			DomainsSource.setDomainValue(item.value.DOM_ID, item_name, item_value)
				.then((response) => {
					item.value[item_name] = item_value
					toaster.success("Value set in DB ");
				})
				.catch((error) => {
					toaster.error("Could not get Info from registry ", error);
				})
			return true;
		}
		
		return {
			item,
			loading,
			clients,
			owners,
			handles,
			statuslist,
			registryDetails,
			registryLoading,
			editDomain,
			deleteDomain,
			changeOwner,
			setValue,
			resetData,
			Calendar, 
			DatePicker,
			updateDNS,
			getFromRegistry,
			providers,
			parsedValues,
			datePickerAttributes,
			v$
		}
	}

};
