<template>
	<div>
		<img :src="iconPath"/>
		{{ cname }} <span v-if="state != ''"> / {{ state }} </span>
	</div>
</template>
<script>
import countries from "../assets/countries.json";
export default {
	props: { country: String },
	data() {
		return {
			iso: '',
			state: '',
			cname: ''
		}
	},
	
	watch: {
		country: {
			immediate: true,
			// eslint-disable-next-line no-unused-vars
			handler(newVal, oldVal) {
				// console.log("new id passed to details ", newVal, " old ", oldVal);
				if (newVal != null && newVal != 0)  {
					if(newVal.indexOf('/') >= 0) {
						const parts = newVal.split('/');
						this.iso = parts[0].toLowerCase();
						this.state = parts[1];
					}
					else {
						this.iso = newVal.toLowerCase();
					}
					if(!countries[this.iso.toUpperCase()]) {
						console.warn("No entry for "+  this.iso);
						this.cname = '';
					}
					else {
						this.cname = countries[this.iso.toUpperCase()]
					}
				}
			},
		},
	},
	created() {
		
	},
	computed: {
		iconPath() {
			if(this.cname == '') return true;
			const imgUrl = new URL(`../assets/images/flags_svg/${this.iso}.svg`)
            // import.meta.url)
return imgUrl
			// return require(`../assets/images/flags_svg/${this.iso}.svg`);
		}


	}
};
</script>
<style scoped>
img {
	height:24px;
}
</style>