// eslint-disable-next-line
import API from "./axios";
export default {
	// eslint-disable-next-line
	getDomainsList(params) {
		return API.get("/admin/domains/domains/list",{params: params});
	},
	getDomainsTodos(params) {
		return API.get("/admin/domains/domains/todos",{params: params});
	},
	getDomain(id) {
		return API.get("/admin/domains/domains/single/" + id);
	},
	editDomain(data) {
		return API.post("/admin/domains/domains/edit", data);
	},
	addDomain(data) {
		return API.post("/admin/domains/domains/add", data);
	},
	checkAvailable(domain) {
		return API.get("/inside/domains/check/" + domain);
	},
	deleteDomain(hid) {
		return API.post("/admin/domains/domains/delete", {id: hid});
	},
	changeOwner(data) {
		return API.post("/inside/domains/ownerchange", data);
	},
	getDomainInfo(id) {
		return API.get("/admin/domains/domains/info/" + id);
	},
	getWhoisPrivacyTlds() {
		return API.get("/inside/domains/whoisprivacy");
	},
	setDomainValue(id, name, value) {
		return API.post("/admin/domains/domains/setvalue", {id: id, fieldname: name, fieldval: value});
	},
	getAuthCode(domid) {
		return API.get(`/admin/domains/domains/authcode/${domid}`);
	},
	getTransferList(params) {
		return API.get('/admin/domains/transfers/list',{params: params});
	},
	addTransferDomains(clientid,data) {
		return API.post("/admin/domains/transfers/add", {client:clientid, transfers: data});
	},
	setTransferStatus(ids,status) {
		return API.post("/admin/domains/transfers/setstatus", {ids:ids, status: status});
	},
	setDomainCancelStatus(domid,status) {
		return API.get("/admin/domains/domains/setcancelstatus",{params: {DOM_ID: domid, DOM_CANCEL: status}});
	},
	getContactsList() {
		return API.get("/admin/domains/contacts/all");
	},
	getContactsQueryList(params) {
		return API.get("/admin/domains/contacts/list",{params: params});
	},
	getContactsShortList(registry, CL_ID, type) {
		return API.get("/inside/contacts/shortlist",{params: {CO_PROVIDER: registry, CO_CLID: CL_ID,  type: type}});
	},
	getContact(id) {
		return API.get("/admin/domains/contacts/single/" + id);
	},
	editContact(data) {
		return API.post("/inside/contacts/edit", data);
	},
	addContact(data) {
		return API.post("/inside/contacts/add", data);
	},
	deleteContact(mid) {
		return API.post("/admin/domains/contacts/delete", {id: mid});
	},
	getContactInfo(id) {
		return API.get("/inside/contacts/info/" + id);
	},
	setContactValue(id, name, value) {
		return API.post("/admin/domains/contacts/setcontactvalue", {id: id, fieldname: name, fieldval: value});
	},
	getDefaultNameservers() {
		return API.get("/admin/domains/domains/defaultnameservers");
	},
	
	getClientShortList() {
		return API.get("/admin/domains/clients/shortlist");
	},
	
};
