<template>
	<div class="container-fluid pbn">
		<div class="row">
			<h1
				class="
					text-center text-3xl
					font-extrabold
					leading-9
					tracking-tight
					text-gray-900
					sm:text-4xl sm:leading-10
				"
			>
				Invoices
			</h1>
			<ul class="nav nav-tabs">
				<li :class="{ 'nav-item': true, 'active' : (active == 'list')}">
					<router-link :class="'nav-link'" aria-current="List" to="/accounting/invoices/list" @click="showTab('list')">List</router-link>
				</li>
				<li :class="{ 'nav-item': true, 'active' : (active == 'details')}">
					<router-link :class="'nav-link '" aria-current="Details" :to="detailurl" @click="showTab('details')"
						>Details</router-link>
				</li>
				<li :class="{ 'nav-item': true, 'active' : (active == 'add')}">
					<router-link 
						:class="'nav-link '" 
						aria-current="Details" 
						to="/accounting/invoices/add"
						@click="prepareAdd"
						>Add</router-link>
				</li>
			</ul>
		</div>
		<div class="row content" id="list" v-show="active == 'details'">
			<div class="col">
				<InvoiceDetails add="0"  @updateList="updateList"  @removeFromList="removeFromList"/>
			</div>
		</div>
		<div class="row content" id="list" v-show="active == 'add'">
			<div class="col">
				<InvoiceAdd add="1" ref="invoiceadd"  @addToList="addToList"/>
			</div>
		</div>
		<div class="row content goodtable" id="list" v-show="active == 'list'">
			<div class="col">
				<vue-good-table
					mode="remote"
					:totalRows="totalRecords"
					:columns="columns"
					:rows="rows"
					:pagination-options="{
						enabled: true,
						mode: 'records',
						perPage: 20,
						position: 'both',
						perPageDropdown: [10, 20, 50],
						dropdownAllowAll: false,
						setCurrentPage: 1,
						nextLabel: 'next',
						prevLabel: 'prev',
						rowsPerPageLabel: 'Rows per page',
						ofLabel: 'of',
						pageLabel: 'page', // for 'pages' mode
						allLabel: 'All',
					}"
					v-on:page-change="onPageChange"
					v-on:sort-change="onSortChange"
					v-on:column-filter="onColumnFilter"
					v-on:per-page-change="onPerPageChange"
					styleClass="vgt-table striped"
				>
					>
					<template #table-row="props">
						<span v-if="props.column.field == 'IN_AMOUNT'">
							<i18n-n
								tag="p"
								:value="props.row.IN_AMOUNT"
								format="currency"
								locale="de-DE"
								scope="global"
							></i18n-n>
							<!-- {{ n(props.column.field, 'currency') }} -->
						</span>
						<span v-else-if="props.column.field == 'IN_TOTAL'">
							<i18n-n
								tag="p"
								:value="props.row.IN_TOTAL"
								format="currency"
								locale="de-DE"
								scope="global"
							></i18n-n>
							<!-- {{ n(props.column.field, 'currency') }} -->
						</span>
						<span v-else-if="props.column.field == 'IN_STATUS'">
							<span v-if="props.row.IN_STATUS == 1">
								<BIconCheckCircleFill class="booticons medium success"/>
							</span>
							<span v-else>
								<BIconDashCircleFill class="booticons medium alert"/>
							</span>
						</span>
						<span v-else-if="props.column.field == 'Details'">
							<router-link 
								:to="`/accounting/invoices/single/${props.row.IN_ID}`"
								@click="showTab('details')"
								>
								<BIconInfoSquareFill class="bootbutton booticons medium primary"/>
							</router-link>
						</span>
						<span v-else-if="props.column.field == 'Delete'">
							<BIconTrashFill class="bootbutton booticons medium alert" @click="deleteInvoice(props.row.IN_ID)" />
						</span>
						<span v-else-if="props.column.field == 'SetPaid'">
							<span v-if="props.row.IN_STATUS == 1">
								<BIconCoin class="bootbutton booticons medium success" @click="togglePaid(props.row.originalIndex)"/>
							</span>
							<span v-else>
								<BIconDashCircleFill class="bootbutton booticons medium warning" @click="togglePaid(props.row.originalIndex)" />
							</span>
						</span>
						<span v-else-if="props.column.field == 'PDF'">
							<BIconFilePdfFill class="bootbutton booticons medium alert" @click="pdfInvoice(props.row.IN_ID, props.row.IN_NUMBER)" />
						</span>
						<span v-else-if="props.column.field == 'Clone'">
							<BIconCollectionFill class="bootbutton booticons medium primary" @click="cloneInvoice(props.row.IN_ID)" />
						</span>

						<span v-else>
							{{ props.formattedRow[props.column.field] }}
						</span>
					</template>
				</vue-good-table>
			</div>
		</div>
	</div>
</template>

<script src="./Invoices.js"></script>
