<template>
	<div class="container-fluid site">
		<div class="row">
			<div class="col-sm-12 col-lg-2 h-100 navcarry">
				<VerticalNavigation :menuitems="menuitems" />
			</div>
			<div class="col-sm-12 col-lg-10">
				<router-view/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "HostingLayout",
	data() {
		return {
			menuitems: [
				{
					topic: "Subscriptions",
					expanded: true,
					entries: [
						{ name: "List", url: "/hosting/subscriptions/list" },
						{ name: "Add", url: "/hosting/subscriptions/list?tab=add" },
					],
				},
				{
					topic: "Server",
					expanded: false,
					entries: [
						{ name: "List", url: "/hosting/server/list" },
						{ name: "Add", url: "/hosting/server/add" }
					],
				},
			]
		};
	},
};
</script>
<style lang="scss">
@import "../assets/scss/pbn.scss";
</style>
