<template>
	<div
		id="customarticlemodal"
		class="modal-dialog-scrollable modal-xl modal-fullscreen-lg-down transfermodal modal-dialog"
	>
		<div class="modal-content">
			<div class="container-fluid text-center">
				<h3 class="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
					Custom Article
				</h3>
			</div>
			<div class="modal-body">
				<div class="container-fluid text-center" v-if="loading">
					<div class="loader loader__sm">
						<div class="spinner-grow text-success" style="width: 2rem; height: 2rem" role="status"></div>
						<p class="text-primary">Loading...</p>
					</div>
				</div>
				<div class="container-fluid" style="min-height: 250px" v-else>
					<form @submit.prevent="editArticle">
						<div class="row">
							<div class="col col-12">
								<div :class="{ error: v$.CA_TITLE.$errors.length }">
									<label for="hostname" class="control-label">Title</label>
									<input type="text" class="form-control" id="domain" v-model="details.CA_TITLE" />
									<div class="input-errors" v-for="error of v$.CA_TITLE.$errors" :key="error.$uid">
										<div class="error-msg">{{ error.$message }}</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col col-12">
								<div :class="{ error: v$.CA_TEXT.$errors.length }">
									<label for="hostname" class="control-label">Text</label>
									<textarea
										class="form-control form-control-sm"
										id="itemtext"
										rows="3"
										v-model="details.CA_TEXT"
									></textarea>
									<div class="input-errors" v-for="error of v$.CA_TEXT.$errors" :key="error.$uid">
										<div class="error-msg">{{ error.$message }}</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col col-8">
								<label for="site_first_payment" class="control-label">Due</label>
								<br />
								<DatePicker
									:attributes="datePickerAttributes"
									:masks="{ title: 'MMM YYYY', L: 'YYYY-MM-DD' }"
									:model-config="dateConfig"
									v-model="details.CA_DUE"
								>
									<template v-slot="{ inputValue, inputEvents }">
										<input class="form-control" :value="inputValue" v-on="inputEvents" />
									</template>
								</DatePicker>
							</div>
							<div class="col col-4">
								<div :class="{ error: v$.CA_PRICE.$errors.length }">
									<label for="hostname" class="control-label">Price</label>
									<input type="text" class="form-control" id="domain" v-model="details.CA_PRICE" />
									<div class="input-errors" v-for="error of v$.CA_PRICE.$errors" :key="error.$uid">
										<div class="error-msg">{{ error.$message }}</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col col-6">
								<label for="site_period" class="control-label">Period</label>
								<br />
								<input type="text" class="form-control" id="period" v-model="details.CA_PERIOD" />
							</div>
							<div class="col col-4">
								<label for="site_first_payment" class="control-label">Recurring</label>
								<br />
								<Toggle
									v-model="details.CA_RECURRING"
									class="toggle-red"
									on-label="Yes"
									off-label="No"
									:falseValue="0"
									:trueValue="1"
								/>
							</div>
						</div>
						<div class="row">
							<div class="col col-12 text-center">
								<button type="submit" class="btn btn-primary btn-sm">Submit</button>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { Calendar, DatePicker } from "v-calendar";
import "v-calendar/dist/style.css";
import Toggle from "@vueform/toggle";
import CustomArticlesSource from "@/js/backend/customarticles";
import { Modal } from "bootstrap";
import { useStore } from "vuex"; //eslint-disable-line no-unused-vars
import useVuelidate from "@vuelidate/core";
import { required, integer, helpers } from "@vuelidate/validators";
import { createToaster } from "@meforma/vue-toaster";
import { ref, onMounted, onBeforeMount, computed, watch } from "vue";
export default {
	props: {},

	components: {
		vSelect,
		Toggle,
		DatePicker,
		Calendar,
	},

	setup(props, { emit }) {
		name: "CustomArticleModal";
		const store = useStore();

		const dateConfig = {
			type: "string",
			mask: "YYYY-MM-DD", // Uses 'iso' if missing
		};

		const datePickerAttributes = ref([
			{
				title: "MM YYYY",
			},
		]);
		const toaster = createToaster({ position: "top-left" });
		const theModal = ref();
		const details = ref({
			CA_TITLE: "",
			CA_TEXT: "",
			CA_DUE: "",
			CA_STATUS: 0,
			CA_PRICE: 0,
			CA_RECURRING: 0,
			CA_PERIOD: 0,
		});
		const client = ref();
		const status = ref("");
		const message = ref("");
		const loading = ref(false);

		const statusse = ref(["active", "inactive"]);
		const mustLargerThanZero = value => value != "0";
		const isDate = value => /\d{4}-\d{2}-\d{2}/.test(value);
		const rules = {
			CA_TITLE: { required, $lazy: true },
			CA_TEXT: { required, $lazy: true },
			CA_PRICE: { required },
			CA_DUE: { required, isDate: helpers.withMessage("Must be a valid date", isDate) },
			CA_CLID: { required, mustLargerThanZero },
			CA_STATUS: { required, integer },
			CA_RECURRING: { required, integer },
		};
		const v$ = useVuelidate(rules, details, { $lazy: true });

		const clients = computed(() => store.state.accounting.clients);

		onBeforeMount(() => {
			store.dispatch("CLIENTS", true);
		});

		onMounted(() => {
			theModal.value = new Modal(document.getElementById("customArticleModal"));
		});

		const setDetails = data => {
			details.value = data;
			const cldata = clients.value.find(({ CL_ID }) => CL_ID === data.CA_CLID);
			client.value = cldata.NAME;
			status.value = data.CA_STATUS;
		};

		const editArticle = () => {
			CustomArticlesSource.editArticle(details.value)
				.then(response => {
					if (response.data.Error) {
						toaster.error("Could not set status: \n<br/>" + response.data.Error);
						console.error(response.data.Error);
					} else {
						toaster.success("status changed");
						emit("setStatus", details.value);
					}
				})
				.catch(error => {
					toaster.error("Could not add Server ", error);
				});
		};

		return {
			message,
			datePickerAttributes,
			dateConfig,
			setDetails,
			loading,
			editArticle,
			clients,
			client,
			details,
			statusse,
			status,
			v$,
		};
	},
};
</script>

<style lang="scss">
@import "../../assets/scss/main.scss";

#customarticlemodal {
	.modal-content {
		min-height: 80vh;
		font-size: 1rem;
		padding: 5px;
	}

	.row {
		margin-bottom: 2rem;
		&.error {
			border: 1px solid $color-error;

			li {
				font-size: 16px;
				color: $color-error;
			}
		}
	}

	.copy {
		border: 1px solid $color-success;
		color: $color-success;
		border-radius: 0.2rem;
		padding: 0.3rem;

		&:hover {
			cursor: pointer;
			color: $color-success-hover;
			border: 1px solid $color-success-hover;
		}
	}
	.toggle-red {
		.toggle {
			width: 90px !important;
			color: white;
			font-weight: bold;
			&.toggle-off {
				padding-right: 10px;
			}
			&.toggle-on {
				padding-left: 10px;
			}
		}
		--toggle-bg-on: #a00e0e;
		--toggle-border-on: #a00e0e;
		--toggle-bg-off: #{$color-success};
		--toggle-border-off: #{$color-success};
	}
}
</style>
