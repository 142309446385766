import PBNLayout from "@/Layouts/PBNLayout.vue";
import PBNDnsDetails from "@/components/PBN/DNSDetails.vue";
import { VueGoodTable } from 'vue-good-table-next';
import PBNSource from '@/js/backend/pbn';
export default {

	name: 'Home',

	components: {
		PBNLayout,
		PBNDnsDetails,
		VueGoodTable
	},
	data() {
		return {
			baseurl: '',
			detailurl: '',
			//Good Table Stuff
			isLoading: false,
			columns: [
				{ label: 'ID', field: 'DNS_ID', type: 'number' },
				{
					label: 'Host',
					field: 'DNS_NAME',
					filterOptions: {
						styleClass: 'class1', // class to be added to the parent th element
						enabled: true, // enable filter for this column
					}
				},
				{
					label: 'Hostings',
					field: 'Hostings',
					type: 'number',
				},
				{ label: 'Action', field: 'Action' }
			],
			rows: [
			],
			active: 'list',
			hid: 0
		}
	},
	created() {
		this.baseurl = this.$route.path;
		this.getTab(this.$route.query);
		this.getList();		
	},

	beforeRouteUpdate(to, from, next) {  // eslint-disable no-unused-vars
		this.getTab(to.query);
		this.$forceUpdate();
		next();
	},

	methods: {
		getTab(addr) {
			if(!addr) {
				this.showTab('list');
			}
			else if(addr.id) {
				this.detailurl = this.$route.fullPath
				this.showDetails(addr.id)
			}
			else if(addr.tab) {
				if(addr.tab == "add") {
					this.showTab('add');
				}
				else if(addr.tab == "analysis") {
					this.showTab('analysis');
				}
			}
			else {
				this.showTab('list');
			}
				
		},
		
		showTab(which) {
			this.active = which;
			return which;
		},
		showDetails(id) {
			this.hid = id;
			// console.log("set id to ", this.hid);
			this.showTab('details');
		},
		getList() {
			PBNSource.getDnsList().then(response => {
				this.rows = response.data
				// console.log("GOT ", this.rows.length, " NOW")
			})
				.catch(error => {
					this.$toast.error('Could not fetch data ', error)
				});

		},
		deleteDns(id) {
			if (confirm("Are you absolutely sure? The world will be a different place")) {
				PBNSource.deleteDns(id)
					.then((response) => {
						console.log(response.data.Error);
						if (response.data.Error) {
							this.$toast.error("Could not delete DNS: \n<br/>" + response.data.Error);
							console.log(response.data.Error);
						} else {
							this.$toast.success("DNS delete");
							this.getList()
						}
					})
					.catch((error) => {
						this.$toast.error("Could not delete Host ", error);
					});
			}
		},
	}
}
