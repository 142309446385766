import DomainsLayout from "@/Layouts/DomainsLayout.vue";
import ContactsDetails from "./ContactsDetails.vue";
import ContactAdd from "./ContactAdd.vue";
import ContactsList from "./ContactsList.vue";
import DomainsSource from '@/js/backend/domains';

export default {

	name: 'Home',

	components: {
		DomainsLayout,
		ContactsList,
		ContactAdd,
		ContactsDetails
	},

	props: {
		msg: String
	},
	data() {
		return {
			showCancelled: false,
			baseurl: '',
			detailurl: '',
			active: 'list',
			//Good Table Stuff
			isLoading: false,
			rows: [],
			todos: [],
			totalRecords: 0,
			totalTodos: 0,
			hoid: 0,
		}
	},
	
	created() {
		this.baseurl = this.$route.path;
		this.getTab(this.$route);
	},
	
	mounted() {
	},

	beforeRouteEnter (to, from, next) {
		next(vm => {
			vm.getTab(to)
		})
	},
	
	
	methods: {
		prepareAdd() {
			this.$refs.contactsadd.resetData();
			this.showTab('add');
		},
		showTab(which) {
			this.active = which;
			return which;
		},
		showDetails(id) {
			this.hoid = id;
			// console.log("set id to ", this.hoid);
			this.showTab('details');
		},
		getTab(addr) {
			// console.log("calles get tab with ", addr)
			if(!addr) {
				this.showTab('list');
			}
			else if(addr.params?.id) {
				this.detailurl = addr.fullPath
				this.showDetails(addr.params.id)
			}
			else if(addr.path && addr.path.match(/todos/)) {
				this.showTab('todos');
				
				DomainsSource.getDomainsTodos().then(response => {
					this.totalTodos = response.data.totalRecords;
					this.todos = response.data.rows;
				})
			}
			else if(addr.path && addr.path.match(/add/)) {
				this.showTab('add');
			}
			else {
				this.showTab('list');
			}
		},
		
		updateListHandler(data) {
			// console.log("passing data to child ")
			this.$refs.contactsList.updateList(data);
		},
		
		addListHandler(data) {
			// console.log("add to list: ", data)
			this.$refs.contactsList.addToList(data);
		},
		
		toggleCancelled() {
			this.showCancelled = !this.showCancelled
			return  this.showCancelled;
		}

	}

}
