/* eslint-disable no-unused-vars */
import {
	CLIENTS,
	SETCLIENTS
} from "../actions/accounting";
// eslint-disable-next-line no-unused-vars
import ClientSource from "@/js/backend/client";

const state = {
	clients: JSON.parse(localStorage.getItem("clients")) || {},
};


const actions = {
	[CLIENTS]: ({ commit, dispatch }, force) => {

		if (localStorage.getItem("clients") && force != 1) {
			return JSON.parse(localStorage.getItem("clients"));
		} else {
			ClientSource
				.getClientShortList()
				.then(resp => {
					let in_clients = JSON.parse(JSON.stringify(resp.data));
					in_clients.unshift({ text: "---", value: 0 });
					localStorage.setItem("clients", JSON.stringify(in_clients));
					commit(SETCLIENTS);
					return in_clients;
				})
				.catch(error => {
					localStorage.removeItem("clients"); // if the request fails, remove any possible user token if possible
					return error;
				});
		}
	},
}

const mutations = {
	[SETCLIENTS]: (state, resp) => {
		state.clients = JSON.parse(localStorage.getItem("clients"));
	},
};

export default {
	state,
	actions,
	mutations
};
