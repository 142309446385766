/* eslint-disable no-unused-vars */
import {
	PBN_HOSTS,
	PBN_SETHOSTS,
	PBN_CLIENTS,
	PBN_SETCLIENTS,
	PBN_DNS,
	PBN_SETDNS,
	PBN_IPLIST,
	PBN_SETIPLIST,
	
} from "../actions/pbn";
// eslint-disable-next-line no-unused-vars
import PBNSource from "@/js/backend/pbn";

const state = {
	hosts: JSON.parse(localStorage.getItem("hosts")) || {},
	pbn_clients: JSON.parse(localStorage.getItem("pbn_clients")) || {},
	dnsproviders: JSON.parse(localStorage.getItem("dnsproviders")) || {},
	iplist: JSON.parse(localStorage.getItem("iplist")) || {},
};


const actions = {
	[PBN_HOSTS]: ({ commit, dispatch }, user) => {

		if (localStorage.getItem("hosts")) {
			return JSON.parse(localStorage.getItem("hosts"));
		} else {
			PBNSource
				.getHostShortList()
				.then(resp => {
					let in_hosts = JSON.parse(JSON.stringify(resp.data));
					in_hosts.unshift({ text: "---", value: 0 });
					localStorage.setItem("hosts", JSON.stringify(in_hosts));
					commit(PBN_SETHOSTS);
					return in_hosts;
				})
				.catch(error => {
					localStorage.removeItem("hosts"); // if the request fails, remove any possible user token if possible
					return error;
				});
		}
	},
	[PBN_CLIENTS]: ({ commit, dispatch }, force) => {

		if (localStorage.getItem("pbn_clients") && !force) {
			return JSON.parse(localStorage.getItem("pbn_clients"));
		} else {
			PBNSource
				.getClientShortList()
				.then(resp => {
					let in_pbn_clients = JSON.parse(JSON.stringify(resp.data));
					in_pbn_clients.unshift({ text: "---", value: 0 });
					localStorage.setItem("pbn_clients", JSON.stringify(in_pbn_clients));
					commit(PBN_SETCLIENTS);
					return in_pbn_clients;
				})
				.catch(error => {
					localStorage.removeItem("pbn_clients"); // if the request fails, remove any possible user token if possible
					return error;
				});
		}
	},
	[PBN_DNS]: ({ commit, dispatch }, force) => {

		if (localStorage.getItem("dnsproviders") && !force) {
			return JSON.parse(localStorage.getItem("dnsproviders"));
		} else {
			PBNSource
				.getDnsShortList()
				.then(resp => {
					let in_dns = JSON.parse(JSON.stringify(resp.data));
					in_dns.unshift({ text: "---", value: 0 });
					localStorage.setItem("dnsproviders", JSON.stringify(in_dns));
					commit(PBN_SETDNS);
					return in_dns;
				})
				.catch(error => {
					localStorage.removeItem("dnsproviders"); // if the request fails, remove any possible user token if possible
					return error;
				});
		}
	},
	[PBN_IPLIST]: ({ commit, dispatch }, force) => {
		// console.log('get iplist')
		if (localStorage.getItem("iplist") && !force) {
			// console.log('in cache and force=' + force)
			return JSON.parse(localStorage.getItem("iplist"));
		} else {
			
			PBNSource
				.getIPShortList()
				.then(resp => {
					let in_ips = JSON.parse(JSON.stringify(resp.data));
					in_ips.unshift({ text: "---", value: 0 });
					localStorage.setItem("iplist", JSON.stringify(in_ips));
					commit(PBN_SETIPLIST);
					// console.log(in_ips, '  and force=', force)
					return in_ips;
				})
				.catch(error => {
					// console.log('errrrrrrr ',  error);
					localStorage.removeItem("iplist"); // if the request fails, remove any possible user token if possible
					return error;
				});
		}
	}
}

const mutations = {
	[PBN_SETHOSTS]: (state, resp) => {
		state.hosts = JSON.parse(localStorage.getItem("hosts"));
	},
	[PBN_SETCLIENTS]: (state, resp) => {
		state.pbn_clients = JSON.parse(localStorage.getItem("pbn_clients"));
	},
	[PBN_SETDNS]: (state, resp) => {
		state.dnsproviders = JSON.parse(localStorage.getItem("dnsproviders"));
	},
	[PBN_SETIPLIST]: (state, resp) => {
		state.iplist = JSON.parse(localStorage.getItem("iplist"));
	},
};

export default {
	state,
	actions,
	mutations
};
