/* eslint-disable no-unused-vars */
import {
	MISC_SETURL,
	MISC_SETLANG,
	MISC_SETLASTPAGE,
	MISC_SETTITLE,
	MISC_SETACTIVENAV
} from "../actions/misc";
// eslint-disable-next-line no-unused-vars

const state = {
	currentTitle: JSON.parse(localStorage.getItem("currentTitle")) || {},
	currentUrl: '/',
	activenav: 'home',
	currentLang: localStorage.getItem("currentLang") || 'en',
	lastPage: localStorage.getItem('lastPage') || '/'
};

const getters = {
	currentUrl: state => state.currentUrl,
	currentLang: state => state.currentLang,
	currentTitle: state => state.currentTitle
};

const actions = {
	[MISC_SETURL]: ({ commit, dispatch }, url) => {
		commit(MISC_SETURL, {
			url: url
		});
	},
	[MISC_SETLANG]: ({ commit, dispatch }, lang) => {
		commit(MISC_SETLANG, {
			lang: lang
		});
	},
	[MISC_SETTITLE]: ({ commit, dispatch }, title) => {
		commit(MISC_SETTITLE, {
			title: title
		});
	},
	[MISC_SETLASTPAGE]: ({ commit, dispatch }, url) => {
		commit(MISC_SETLASTPAGE, {
			url: url
		});
	},
	[MISC_SETACTIVENAV]: ({ commit, dispatch }, navtitle) => {
		commit(MISC_SETACTIVENAV, {
			activenav: navtitle
		});
	},
};

const mutations = {
	[MISC_SETACTIVENAV]: (state, resp) => {
		state.activenav = resp.activenav;
	},
	[MISC_SETURL]: (state, resp) => {
		state.currentUrl = resp.url;
	},
	[MISC_SETLANG]: (state, resp) => {
		localStorage.setItem("currentLang", resp.lang)
		state.currentLang = resp.lang;
	},
	[MISC_SETTITLE]: (state, resp) => {
		state.currentTitle = resp.title;
	},
	[MISC_SETLASTPAGE]: (state, resp) => {
		localStorage.setItem("lastPage", resp.url)
		state.lastPage = resp.url;
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
