<template>
	<div class="container-fluid domains">
		<div class="mt-2 d-flex justify-content-between">
			<div class="text-left">
				<h2
					class="
						text-3xl
						font-extrabold
						leading-9
						tracking-tight
						text-gray-900
						sm:text-4xl sm:leading-10
					"
				>
					Domain Transfers
				</h2>
			</div>
			<div class="text-right">
				<button class="btn btn-outline-primary"
					data-bs-toggle="modal"
					data-bs-target="#transferModal"
					@click="showAddTransfer">
					ADD
				</button>
			</div>
		</div>
		<div class="row">
			<vue-good-table
				ref="transfertable"
				:totalRows="totalRecords"
				:columns="columns"
				:rows="rows"
				:pagination-options="{
					enabled: true,
					mode: 'records',
					perPage: 20,
					position: 'both',
					perPageDropdown: [10, 20, 50],
					dropdownAllowAll: false,
					setCurrentPage: 1,
					nextLabel: 'next',
					prevLabel: 'prev',
					rowsPerPageLabel: 'Rows per page',
					ofLabel: 'of',
					pageLabel: 'page', // for 'pages' mode
					allLabel: 'All',
				}"
				:select-options="{ enabled: true }"
				v-on:selected-rows-change="selectionChanged"
				v-on:page-change="onPageChange"
				v-on:sort-change="onSortChange"
				v-on:column-filter="onColumnFilter"
				v-on:per-page-change="onPerPageChange"
				styleClass="vgt-table striped"
			>
				<template #selected-row-actions>
					<select class="form-select" aria-label="Choose action" v-model="todo" v-on:change="performIt">
						<option value="">Choose</option>
						<option value="export">Export</option>
						<optgroup label="Set Status to">
							<option v-for="(option,idx) in statusse" v-bind:key="idx" :value="option">
								{{ option}}
							</option>
						</optgroup>
					</select>
				</template>
				<!-- <template #emptystate>
					No transfers found
				</template> -->
				<template #table-row="props">
					<span v-if="props.column.field == 'Action'">
						<button 
							class="ms-3 btn btn-outline-primary btn-sm" 
							data-bs-toggle="modal"
							data-bs-target="#detailsModal"
							@click="showDetails(props.row)">
							Details
						</button>
					</span>
					<span v-else>
						{{ props.formattedRow[props.column.field] }}
					</span>
				</template>
			</vue-good-table>
			
		</div>
		<div class="row mt-3">
			<div class="col col-12">
				<textarea class="form-control" rows="6" :value="exported">
				</textarea> 
			</div>
			
			<div class="modal fade" id="transferModal" tabindex="-1" aria-labelledby="transferModal" aria-hidden="true">
				<transferModal 
					@addTransfers="addTransfersHandler"
					ref="transfermodalwindow" 
				/>
			</div>
			<div class="modal fade" id="detailsModal" tabindex="1" aria-labelledby="detailsModal" aria-hidden="true">
				<detailsModal 
					@setStatus="updateList"
					ref="detailmodalwindow" 
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { VueGoodTable } from "vue-good-table-next";
import { ref, onBeforeMount } from "vue";
import { createToaster } from "@meforma/vue-toaster";
import DomainsSource from "@/js/backend/domains";
import transferModal from "./transferModal.vue"
import detailsModal from "./detailsModal.vue"
import providers from "../../js/providers";

export default {
	components: {
		VueGoodTable,
		transferModal,
		detailsModal
	},

	setup() {
		const transfermodalwindow = ref();
		const detailmodalwindow = ref();
		const toaster = createToaster({ position: 'top' });
    const transfertable = ref([]);
		const todo = ref("");
		const exported = ref("");

		const rows = ref([]);
		const columns = ref([
			{
				label: "",
				field: "DT_ID",
				sortable: false,
				width: "14px",
			},
			{ label: "Created", field: "DT_CD", type: "number", sortable: true },
			{ label: "Lastmod", field: "DT_UD", type: "number", sortable: true },
			{
				label: "Domain",
				field: "DT_DOMAIN",
				filterOptions: {
					styleClass: "class1", // class to be added to the parent th element
					enabled: true, // enable filter for this column
				},
			},
			{
				label: "Status",
				field: "DT_STATUS",
				filterOptions: {
					styleClass: "class1", // class to be added to the parent th element
					enabled: true, // enable filter for this column
				},
			},
			{
				label: "Provider",
				field: "DT_PROVIDER",
				filterOptions: {
					styleClass: "class1",
					enabled: true,
					filterDropdownItems: providers
				},
			},
			{ label: "Action", field: "Action" },
		]);
		const totalRecords = ref(0);
		const serverParams = ref ({
			// a map of column filters example: {name: 'john', age: '20'}
			columnFilters: {},
			sort: [
				{
					field: "DT_UD", // example: 'name'
					type: "desc", // 'asc' or 'desc'
				},
			],

			page: 1, // what page I want to show
			perPage: 20, // how many items I'm showing per page
		});
		
		const statusse = ref([
			"open", "progress", "done", "failed"
		]);

		onBeforeMount(() => {
			loadItems();
		});

		const getDetails = (domid) => {
			transfermodalwindow.value.getDetails(domid)
		}

		function updateParams(newProps) {
			serverParams.value = Object.assign({}, serverParams.value, newProps);
			console.log("Filter changed", serverParams.value);
		}

		function onPageChange(params) {
			updateParams({ page: params.currentPage });
			loadItems();
		}

		function onPerPageChange(params) {
			updateParams({ perPage: params.currentPerPage });
			loadItems();
		}

		function onSortChange(params) {
			updateParams({
				sort: [
					{
						type: params[0].type,
						field: params[0].field,
					},
				],
				page: 1,
			});
			loadItems();
		}

		function onColumnFilter(params) {
			updateParams(params);
			loadItems();
		}

		// load items is what brings back the rows from server
		function loadItems() {
			DomainsSource.getTransferList(serverParams.value).then((response) => {
				rows.value = response.data.rows;
				totalRecords.value = response.data.totalRecords;
				// for(let i = 0;i <rows.value.length;i++) {
				// 	const provdata = providers.find( ({ value }) => value ===  rows.value[i].DT_PROVIDER);
				// 	try {
				// 		rows.value[i].DT_PROVIDER = provdata.text
				// 	}
				// 	catch (error) {
				// 		console.error(error, "Error setting Transfers");
				// 	}
				// }
			console.log(rows.value)
			});
		}
	
		function addTransfersHandler() {
			onSortChange([{"type": "desc", "field": "DT_ID"}]);
			loadItems();
		}
		
		function updateList(data) {
			console.log("arrived in the list with ", data)
			for (const obj of rows.value) {
				if (obj.DT_ID === data.DT_ID) {
					obj.DT_STATUS = data.DT_STATUS;
					break;
				}
			}
			return true;
		}
		
		function removeFromList(id) {
			rows.value.splice(rows.value.findIndex(v => v.DT_ID === id), 1);
		}
		
		function showDetails(data) {
			detailmodalwindow.value.setDetails(data)
		}
		
		function showAddTransfer() {
			transfermodalwindow.value.resetData()
		}
		
		function selectionChanged() {
			console.log(transfertable.value.selectedRows);
		}
		
		function performIt() {
			if(transfertable.value.selectedRows.length < 1) {
				return false;
			}
			if(todo.value == "export") {
				let data = [];
				for(let i = 0;i < transfertable.value.selectedRows.length;i++) {
					data.push(`${transfertable.value.selectedRows[i].DT_DOMAIN};${transfertable.value.selectedRows[i].DT_AUTH_CODE}`);
				}
				exported.value = data.join("\n");
				// console.log(data);
			}
			else if(statusse.value.includes(todo.value)) {
				let data = [];
				for(let i = 0;i < transfertable.value.selectedRows.length;i++) {
					data.push(transfertable.value.selectedRows[i].DT_ID);
				}

				DomainsSource.setTransferStatus(data, todo.value)
				.then((response) => {
					if (response.data.Error) {
						toaster.error(
							"Could not set status: \n<br/>" + response.data.Error
						);
						console.error(response.data.Error);
					} else {
						toaster.success("status changed");
						for(let i = 0;i < transfertable.value.selectedRows.length;i++) {
							setStatus(transfertable.value.selectedRows[i].DT_ID, todo.value)
						}

					}
				})
				.catch((error) => {
					toaster.error("Could not add Server ", error);
				});

			}
			console.log(todo.value)
		}
		
		function setStatus(id,what) {
			const outid = rows.value.findIndex(v => v.DT_ID === id);
			rows.value[outid].DT_STATUS = what;
		}
		
		return {
			rows,
			totalRecords,
			columns,
			todo,
			exported,
			statusse,
			showDetails,
			updateList,
			removeFromList,
			getDetails,
			transfermodalwindow,
			detailmodalwindow,
			showAddTransfer,
			onColumnFilter,
			onSortChange,
			onPerPageChange,
			onPageChange,
			addTransfersHandler,
			selectionChanged,
			performIt,
			transfertable
		}
		
	}
}

</script>