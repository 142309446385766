<template>
	<div class="contacts" id="contactadd">
		<div class="d-flex justify-content-between">
			<div class="text-left">
				<h2>Edit Contact <i>{{item.CO_HANDLE}}</i></h2>
			</div>
			<div class="text-right">
				<button class="btn btn-outline-primary" @click="getFromRegistry">Get data from registry</button>
			</div>
		</div>
		<div class="container-fluid" v-if="loading">
			<div class="loader">
				<div
					class="spinner-grow text-success"
					style="width: 6rem; height: 6rem"
					role="status"
				></div>
				<p class="text-primary">Loading...</p>
			</div>
		</div>
		<div class="container-fluid details" v-else>
			<form @submit.prevent="editContact">
				<div class="row">
					<div class="col col-3">
						Client: <br />
						<span class="fixedvalue">{{ parsedValues.clientname }}</span>
					</div>
					
					<div class="col col-3">
						Client Default Contact:<br/>
						<Toggle
							v-model="item.CO_CLIENTBASEACCOUNT"
							class="toggle-red"
							on-label="Yes"
							off-label="No"
							:falseValue="0"
							:trueValue="1"
						/>
					</div>
					
					<div class="col col-3">
						Provider: <br />
						<span class="fixedvalue">{{ parsedValues.provider }}  ({{ item.CO_PROVIDER }})</span>

					</div>
					
					<div class="col col-3">
						Is Owner:<br/>
						<span class="fixedvalue">{{ parsedValues.isowner }}</span>
					</div>
					
				</div>
				<div class="row">
					<div class="col col-3">
						<label for="hostname" class="control-label">First Name</label><br/>
						<span class="fixedvalue">{{ item.CO_FIRSTNAME }}</span>
					</div>
					<div class="col col-3">
						<label for="hostname" class="control-label">Last Name</label><br/>
						<span class="fixedvalue">{{ item.CO_LASTNAME }}</span>
					</div>
					<div class="col col-3">
						Type: <br />
						<span class="fixedvalue">{{ parsedValues.type }}</span>
					</div>
					
					<div class="col col-3">
						<div v-if="item.CO_TYPE == 'O'">
							<label for="company" class="control-label">Company</label><br/>
							<span class="fixedvalue">{{ item.CO_COMPANY }}</span>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col col-3">
						<div :class="{ error: v$.CO_TEL.$errors.length }">
							<label for="tel" class="control-label">Phone</label>
							<input
								type="text"
								class="form-control"
								id="company"
								placeholder="Contact Last Name"
								v-model="item.CO_TEL"
							/>
							<div
								class="input-errors"
								v-for="error of v$.CO_TEL.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					<div class="col col-3">
						<div :class="{ error: v$.CO_FAX.$errors.length }">
							<label for="tel" class="control-label">Fax</label>
							<input
								type="text"
								class="form-control"
								id="company"
								placeholder="Contact Last Name"
								v-model="item.CO_FAX"
							/>
							<div
								class="input-errors"
								v-for="error of v$.CO_FAX.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					
					<div class="col col-6">
						
						<div :class="{ error: v$.CO_EMAIL.$errors.length }">
							<label for="email" class="control-label">
								Email:
							</label>
							<input
								type="text"
								class="form-control"
								id="email"
								v-model="item.CO_EMAIL"
							/>
							<div
								class="input-errors"
								v-for="error of v$.CO_EMAIL.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<div :class="{ error: v$.CO_STREET.$errors.length }">
							<label for="hostname" class="control-label">Street</label>
							<input
								type="text"
								class="form-control"
								id="domain"
								v-model="item.CO_STREET"
							/>
							<div
								class="input-errors"
								v-for="error of v$.CO_STREET.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					<div class="col col-2">
						<div :class="{ error: v$.CO_ZIP.$errors.length }">
							<label for="hostname" class="control-label">ZIP</label>
							<input
								type="text"
								class="form-control"
								id="domain"
								v-model="item.CO_ZIP"
							/>
							<div
								class="input-errors"
								v-for="error of v$.CO_ZIP.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					<div class="col col-4">
						<div :class="{ error: v$.CO_CITY.$errors.length }">
							<label for="city" class="control-label">
								City:
							
							</label>
							<input
								type="text"
								class="form-control"
								id="city"
								v-model="item.CO_CITY"
							/>
							<div
								class="input-errors"
								v-for="error of v$.CO_CITY.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<label for="state" class="control-label">
							State:
						</label>
						<input
							type="text"
							class="form-control"
							id="state"
							v-model="item.CO_STATE"
						/>
					</div>
					<div class="col col-6">
						<div :class="{ error: v$.CO_COUNTRY.$errors.length }">
							<label for="hostname" class="control-label">Country</label>
							<br /><Dropdown
								v-model="item.CO_COUNTRY"
								:options="countries"
								optionLabel="text"
								optionValue="id"
								placeholder="Select one"
							/>
							<div
								class="input-errors"
								v-for="error of v$.CO_COUNTRY.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col col-12 fw-bold border-top border-dark">
						These items are not mandatory for all TLDs but needed for some. It does not hurt to fill them in!
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<div :class="{ error: v$.CO_BIRTHDAY.$errors.length }">
							<label for="site_first_payment" class="control-label">Birthday</label>
							<br/>
							<DatePicker 
								:attributes='datePickerAttributes'  
								:masks="{ title: 'MMM YYYY', L: 'YYYY-MM-DD'  }" 
								:model-config="datePickerModelConfig"
								v-model="item.CO_BIRTHDAY">
								<template v-slot="{ inputValue, inputEvents }">
									<input
										class="form-control"
										
										:value="inputValue"
										v-on="inputEvents"
									/>
								</template>
							</DatePicker>
							<div
								class="input-errors"
								v-for="error of v$.CO_BIRTHDAY.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					<div class="col col-6">
						<label for="hostname" class="control-label">
							Pers. ID No:
						</label>
						<input
							type="text"
							class="form-control"
							id="domain"
							v-model="item.CO_PERSID"
						/>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<label for="hostname" class="control-label">
							ID Issued on:
						</label>
						<input
							type="text"
							class="form-control"
							id="domain"
							v-model="item.CO_PERS_ISSUED"
						/>
					</div>
					<div class="col col-6">
						<label for="hostname" class="control-label">
							VAT ID:
						</label>
						<input
							type="text"
							class="form-control"
							id="domain"
							v-model="item.CO_VATID"
						/>
					</div>
				</div>
				<div class="row" v-if="item.CO_PROVIDER == 1">
					<div class="col col-12">
						<span class="article">NIC Refs:</span> {{ item.CO_NIC_REFS }}
					</div>
				</div>

				<div class="row">
					<div class="col col-6 text-center">
						<input
							class="btn btn-primary"
							type="submit"
							name="su"
							value="Edit"
						/>
					</div>
					<div class="col col-6 text-center">
					</div>
				</div>
			</form>
		</div>
		<div class="container-fluid details" v-if="registryDetails">
			<div v-if="registryLoading">
				<div class="loader loader__sm">
					<div
						class="spinner-grow text-success"
						style="width: 2rem; height: 2rem"
						role="status"
					></div>
					<p class="text-primary">Loading...</p>
				</div>
			</div>
			<div class="border border-secondary p-3" v-else>
				<h3>Registry Data</h3>
				<div class="row">
					<div class="col col-2"><span class="article">Handle:</span> {{ registryDetails.CO_HANDLE }}</div>
					<div class="col col-1"><span class="article">Type:</span> {{ registryDetails.CO_TYPE }}</div>
					<div class="col col-3"><span class="article">First name:</span> {{ registryDetails.CO_FIRSTNAME }}</div>
					<div class="col col-3"><span class="article">Last name:</span> {{ registryDetails.CO_LASTNAME }}</div>
					<div class="col col-3" v-if="registryDetails.CO_TYPE == 'O'"><span class="article">Company:</span> {{ registryDetails.CO_COMPANY }}</div>
				</div>
				<div class="row">
					<div class="col col-3">
						<span class="article">Street:</span> {{ registryDetails.CO_STREET }}
						<br/><button type="button" class="btn btn-success btn-sm" @click="setValue('CO_STREET',registryDetails.CO_STREET)">Set</button>
					</div>
					<div class="col col-3">
						<span class="article">ZIP:</span> {{ registryDetails.CO_ZIP }}
						<br/><button type="button" class="btn btn-success btn-sm" @click="setValue('CO_ZIP',registryDetails.CO_ZIP)">Set</button>
					</div>
					<div class="col col-3">
						<span class="article">City:</span> {{ registryDetails.CO_CITY }}
						<br/><button type="button" class="btn btn-success btn-sm" @click="setValue('CO_CITY',registryDetails.CO_CITY)">Set</button>
					</div>
					<div class="col col-3">
						<span class="article">Area:</span> {{ registryDetails.CO_STATE }}
						<br/><button type="button" class="btn btn-success btn-sm" @click="setValue('CO_STATE',registryDetails.CO_STATE)">Set</button>
					</div>
				</div>
				<div class="row">
					<div class="col col-3">
						<span class="article">Country:</span> {{ registryDetails.CO_COUNTRY }}
						<br/><button type="button" class="btn btn-success btn-sm" @click="setValue('CO_COUNTRY',registryDetails.CO_COUNTRY)">Set</button>
					</div>
					<div class="col col-3">
						<span class="article">Tel:</span> {{ registryDetails.CO_TEL }}
						<br/><button type="button" class="btn btn-success btn-sm" @click="setValue('CO_TEL',registryDetails.CO_TEL)">Set</button>
					</div>
					<div class="col col-3">
						<span class="article">Fax:</span> {{ registryDetails.CO_FAX }}
						<br/><button type="button" class="btn btn-success btn-sm" @click="setValue('CO_FAX',registryDetails.CO_FAX)">Set</button>
					</div>
					<div class="col col-3">
						<span class="article">Email:</span> {{ registryDetails.CO_EMAIL }}
						<br/><button type="button" class="btn btn-success btn-sm" @click="setValue('CO_EMAIL',registryDetails.CO_EMAIL)">Set</button>
					</div>
				</div>
				<div class="row" v-if="item.CO_PROVIDER == 1">
					<div class="col col-12">
						<span class="article">NIC Refs:</span> {{ registryDetails.CO_NIC_REFS }}
						<br/><button type="button" class="btn btn-success btn-sm" @click="setValue('CO_NIC_REFS',registryDetails.CO_NIC_REFS)">Set</button>
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<button class="btn btn-warning btn-sm" type="button" data-bs-toggle="collapse" data-bs-target="#registrydetail" aria-expanded="false" aria-controls="registrydetail">
							Raw
						</button>
						<pre id="registrydetail" class="collapse">
							{{registryDetails}}
						</pre>
					</div>
				</div>
			</div>
			<div class="border border-secondary p-3">
				<pre id="localdetail" class="collapse">
					{{item}}
				</pre>
				<button class="btn btn-warning btn-sm" type="button" data-bs-toggle="collapse" data-bs-target="#localdetail" aria-expanded="false" aria-controls="localdetail">
					Raw
				</button>
			</div>
		</div>
	</div>
</template>

<script src="./ContactsDetails.js"></script>
<style lang="scss">
@import "../../assets/scss/main.scss";

#contactadd {
	.collapse .container-fluid {
		padding-left: 0;
		.col {
			padding-left: 0;
		}
	}
	
	.article {
		font-weight:bold;
	}
	.toggle-red {
		.toggle {
			width: 90px !important;
			color: white;
			font-weight: bold;
			&.toggle-off {
				padding-right: 10px;
			}
			&.toggle-on {
				padding-left: 10px;
			}
		}
		--toggle-bg-on: #{$color-success};
		--toggle-border-on: #{$color-success};
		--toggle-bg-off: #a00e0e;
		--toggle-border-off: #a00e0e;
	}
	
	.errortext {
		color: $color-error;
	}
	
	.handle {
		.error .input-errors {
			display:block;
		}
	}
	.fixedvalue {
		font-weight: 700;
		font-size: 1.1rem;
	}
}
</style>