<template>
	<div class="pbn">
		<div class="text-center" style="margin-bottom:.6rem">
			<h3 class="text-center">Server Analysis</h3>
			<button class="btn btn-primary" @click="getAnalysis">Get Analysis</button>
		</div>
		<div class="container-fluid" v-if="loading">
			<div class="loader">
				<div class="spinner-grow text-secondary" style="width: 6rem; height: 6rem;" role="status"></div>
				<p class="text-secondary">Loading...</p>
			</div>
		</div>
		<div class="container-fluid details grid-striped" v-else>
			<div class="row header">
				<div class="col col-sm-4">Country</div>
				<div class="col col-sm-2">Used</div>
				<div class="col col-sm-2">Total</div>
				<div class="col col-sm-4">Free</div>
			</div>
			<div class="row line" v-for="(item,idx) in list" v-bind:key="idx" @click="toggleCountryDetails(idx, item.M_COUNTRY)">
				<div class="col col-sm-4"><CountryFlag :country="item.M_COUNTRY"/></div>
				<div class="col col-sm-2">{{item.TAKEN }}</div>
				<div class="col col-sm-2">{{ item.MAXAVAIL }}</div>
				<div class="col col-sm-4" v-html="getFree(item)"></div>
				<div v-show="activerow == idx">
					<table class="table table-striped">
						<thead>
							<tr>
								<th>Name</th>
								<th>IP</th>
								<th>Used</th>
								<th>Free</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(entry, index) in country_details" v-bind:key="index" @click="getServerDetails(entry.M_ID)">
								<td>{{entry.M_HOSTNAME}}</td>
								<td>{{entry.M_BASEIP}}</td>
								<td>{{entry.Used}}</td>
								<td>{{entry.M_FREE}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PBNSource from "@/js/backend/pbn";
import CountryFlag from "@/components/CountryFlag.vue";


export default {
	name: "PBNServerAnalysis",
	components: {
		CountryFlag
	},
	computed: {
	},

	data() {
		return {
			activerow: false,
			country_details: [],
			list: [],
			host: null,
			loading: false,
		};
	},

	created() {
	},

	methods: {
		getAnalysis() {
			this.loading = true;
			PBNSource.getServerAnalysis()
				.then((response) => {
					this.list = response.data;
					this.loading = false;
				})
				.catch((error) => {
					this.$toast.error("Could not fetch data ", error);
					this.loading = false;
				});
		},
		getFree(it) {
			return (parseInt(it.MAXAVAIL,10) - parseInt(it.TAKEN,10));
		},
		toggleCountryDetails(idx,country) {
			PBNSource.getServerQueryList({"country": country})
				.then((response) => {
					let inc = response.data;
					this.country_details = inc.map(obj => {
						var rObj = obj;
						rObj.M_FREE = parseInt(obj.M_MAXDOMAINS,10) - parseInt(obj.Used,10);
						return rObj;
					});

					this.loading = false;
					this.activerow = idx;
				})
				.catch((error) => {
					this.$toast.error("Could not fetch data ", error);
					this.loading = false;
				});
		},
		getServerDetails(id) {
			console.log("Push to /pbn/server/list?id=" + id )
			this.$router.push('/pbn/server/list?id=' + id);
		}
		
	},
};
</script>
<style lang="scss" scoped>
	.grid-striped .row {
		&.header {
			font-weight:bold;
			border-bottom: 2px solid #aaa;
		}
		margin: 0px 0;
		padding:6px 0;
		border-bottom:1px solid #ccc;
		&:nth-of-type(odd) {
			background-color: rgba(0,0,0,.05);
		}
		&.line {
			&:hover {
				cursor: pointer;
				background-color: aliceblue;
			}
			table, table:hover {
				margin-top:.3rem;
				background-color: #fff;
			}
		}
	}

</style>