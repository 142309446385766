
<template>
	<div class="mh-100" style="height:100vh">
		<Menu v-if="loggedin" @onLogOut="logout" :count="counter" :wtf="'jo!'" />
		<main style="min-height:100%">
			<router-view />
		</main>
	</div>
</template>

<style lang="scss">
@import "./assets/scss/main.scss";
</style>
<style src='primevue/resources/themes/saga-blue/theme.css'></style>
<style src='primevue/resources/primevue.min.css'></style>
<style src='primeicons/primeicons.css'></style>
<style src="@vueform/toggle/themes/default.css"></style>


<script>
/* eslint-disable */
// import commonMessage from './locales/common.json'
import { mapState } from "vuex"; //eslint-disable-line no-unused-vars
import Menu from "@/components/Menu.vue"
export default {
	name: "App",

	data() {
		return {
			counter: (60*60*24),
		};
	},
	components: { Menu },
	computed: {
		...mapState({
			currentUrl: (state) => state.misc.currentUrl,
			loggedin: (state) => state.auth.loggedin,
			userdata: (state) => state.auth.userdata,
			admindata: (state) => state.auth.admindata,
		}),
	},

	mounted() {
		this.countDownTimer()
	},

	methods: {
		countDownTimer() {
			if (this.counter > 0) {
				setTimeout(() => {
					this.counter -= 1;
					this.countDownTimer();
				}, 1000);
			}
			else {
				this.logout();
			}
		},

		logout() {
			this.$store
				.dispatch("AUTH_LOGOUT", {})
				.then(() => {
					let startpage = "/login";
					this.$toast.success("Hooray.<br/>Logged out");
					this.$forceUpdate();
					this.$router.push(startpage);
				})
				.catch(function(err) {
					console.error("Could not log out ", err);
				});
		},
	},
};
</script>
