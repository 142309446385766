<template>
	<div class="pbn">
		<h2 v-if="item.M_ID > 0">Server #{{ item.M_ID }}</h2>
		<h2 v-else>Add Server</h2>
		<div class="container-fluid" v-if="loading">
			<div class="loader">
				<div
					class="spinner-grow text-success"
					style="width: 6rem; height: 6rem"
					role="status"
				></div>
				<p class="text-primary">Loading...</p>
			</div>
		</div>
		<div class="container-fluid details" v-else>
			<form @submit.prevent="editServer">
				<div class="row">
					<div class="col col-6">
						Host: <br /><Dropdown
							v-model="item.M_HID"
							:options="hosts"
							optionLabel="H_NAME"
							optionValue="H_ID"
							placeholder="Select one"
						/>
					</div>
					<div class="col col-6">
						<label for="hostname" class="form-label">Hostname</label>
						<input
							type="text"
							class="form-control"
							id="hostname"
							v-model="item.M_HOSTNAME"
						/>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<label for="M_COUNTRY" class="control-label">Country</label>
						<input
							type="text"
							class="form-control"
							name="M_COUNTRY"
							id="M_COUNTRY"
							v-model="item.M_COUNTRY"
						/>
					</div>
					<div class="col col-6">
						<div>
							<label for="P_NEXT_PAYMENT" class="control-label"
								>Next Pay:</label
							>
						</div>
							<DatePicker :attributes='datePickerAttributes'  :masks="{ title: 'MMM YYYY', L: 'YYYY-MM-DD'  }" v-model="item.P_NEXT_PAYMENT">
								<template v-slot="{ inputValue, inputEvents }">
									<input
										class="form-control"
										
										:value="inputValue"
										v-on="inputEvents"
									/>
								</template>
							</DatePicker>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<label for="M_BASEIP" class="control-label">Base IP</label>
						<input
							type="text"
							class="form-control"
							name="M_BASEIP"
							id="M_BASEIP"
							v-model="item.M_BASEIP"
						/>
					</div>
					<div class="col col-3">
						<label for="M_RAM" class="control-label">RAM MB</label>
						<input
							type="text"
							class="form-control"
							name="M_RAM"
							id="M_RAM"
							v-model="item.M_RAM"
						/>
					</div>
					<div class="col col-3">
						<label for="M_MAXDOMAINS" class="control-label"
							>Max. Domains:</label
						>
						<input
							type="text"
							class="form-control"
							name="M_MAXDOMAINS"
							id="M_MAXDOMAINS"
							v-model="item.M_MAXDOMAINS"
						/>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<label for="M_SSH_USER" class="control-label">SSH User:</label>
						<input
							type="text"
							class="form-control"
							name="M_SSH_USER"
							id="M_SSH_USER"
							v-model="item.M_SSH_USER"
						/>
					</div>
					<div class="col col-6">
						<label for="M_SSH_PWD" class="control-label">SSH Pwd:</label>
						<input
							type="text"
							class="form-control"
							name="M_SSH_PWD"
							id="M_SSH_PWD"
							v-model="item.M_SSH_PWD"
						/>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<label for="M_SYS" class="control-label">System:</label>
						<select
							class="form-control"
							name="M_SYS"
							id="M_SYS"
							v-model="item.M_SYS"
						>
							<option value="">---</option>
							<option value="Vesta">Vesta</option>
							<option value="Hestia">Hestia</option>
							<option value="Webmin">Webmin</option>
						</select>
					</div>
					<div class="col col-6">
						<label for="M_SERVERADMIN_URL" class="control-label"
							>Serveradmin Url:</label
						>
						<input
							type="text"
							class="form-control"
							name="M_SERVERADMIN_URL"
							id="M_SERVERADMIN_URL"
							v-model="item.M_SERVERADMIN_URL"
						/>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<label for="M_SERVERADMIN_USER" class="control-label"
							>Serveradmin User:</label
						>
						<input
							type="text"
							class="form-control"
							name="M_SERVERADMIN_USER"
							id="M_SERVERADMIN_USER"
							v-model="item.M_SERVERADMIN_USER"
						/>
					</div>
					<div class="col col-6">
						<label for="M_SERVERADMIN_PWD" class="control-label"
							>Serveradmin Pwd:</label
						>
						<input
							type="text"
							class="form-control"
							name="M_SERVERADMIN_PWD"
							id="M_SERVERADMIN_PWD"
							v-model="item.M_SERVERADMIN_PWD"
						/>
					</div>
				</div>
				<div class="row">
					<div class="col col-4">
						<label for="P_AMOUNT" class="control-label">Cost:</label>
						<input
							type="text"
							class="form-control"
							name="P_AMOUNT"
							id="P_AMOUNT"
							v-model="item.P_AMOUNT"
						/>
					</div>
					<div class="col col-2">
						<label for="P_CURRENCY" class="control-label">Currency:</label>
						<input
							class="form-control"
							name="P_CURRENCY"
							id="P_CURRENCY"
							v-model="item.P_CURRENCY"
						/>
					</div>
					<div class="col col-4">
						<label for="P_INTERVAL" class="control-label"
							>Interval(months):</label
						>
						<input
							class="form-control"
							name="P_INTERVAL"
							id="P_INTERVAL"
							v-model="item.P_INTERVAL"
						/>
					</div>
					<div class="col col-2">
						<p style="line-height: 0.4rem">&nbsp;</p>
						<ToggleButton
							onIcon="pi pi-check"
							offIcon="pi pi-times"
							onLabel="auto"
							offLabel="Manual"
							v-model="isPaymentAuto"
						/>
					</div>
				</div>
				<div class="row">
					<div class="col col-12">
						<label for="M_REMARKS" class="control-label">Remarks</label>
						<textarea
							class="form-control"
							name="M_REMARKS"
							id="M_REMARKS"
							style="height: 60px"
							v-model="item.M_REMARKS"
						></textarea>
					</div>
				</div>
				<div class="row">
					<div class="col col-4 text-center">
						<input
							class="btn btn-primary"
							type="submit"
							name="su"
							:value="submitLabel"
						/>
					</div>
					<div class="col col-4 text-center">
						<button type="button" class="btn btn-danger" @click="deleteServer">
							Delete
						</button>
					</div>
					<div class="col col-4 text-center" v-if="item.M_ID < 1">
						<button type="button" class="btn btn-warning" @click="setDummy">
							Dummy
						</button>
					</div>
				</div>
				<div class="row">
					<div class="col col-12 text-center">
						<button
							type="button"
							class="btn btn-primary"
							data-bs-target="#pbnhostinglist"
							data-bs-toggle="collapse"
							@click="getHosting(item.M_ID)"
						>
							Hosting
						</button>
					</div>
				</div>
			</form>
		</div>
		<div id="pbnhostinglist" class="container-fluid details">
			<div class="row">
				<div class="col">
					<table class="table table-striped">
						<thead>
							<tr>
								<th>Domain</th>
								<th>Client</th>
								<th>DNS</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(entry, index) in hostinglist"
								v-bind:key="index"
							>
								<td>{{ entry.HO_DOMAIN }}</td>
								<td>{{ entry.CLIENT }}</td>
								<td>{{ entry.DNS_NAME }}</td>
								<td>
									<router-link class="btn btn-secondary btn-sm" :to="'/pbn/hosting/list?id='+ entry.HO_ID">
										Details
									</router-link>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script src="./ServerDetails.js"></script>
