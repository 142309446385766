import PBNHostingDetails from "@/components/PBN/HostingDetails.vue";
import PBNHostingList from "@/components/PBN/PBNHostingList.vue";
import PBNSource from '@/js/backend/pbn';

export default {

	name: 'Hosting',

	components: {
		PBNHostingList,
		PBNHostingDetails
	},

	props: {
		msg: String
	},
	
	data() {
		return {
			baseurl: '',
			detailurl: '',
			active: 'list',
			//Good Table Stuff
			isLoading: false,
			rows: [],
			todos: [],
			totalRecords: 0,
			totalTodos: 0,
			hoid: 0,
		}
	},
	
	created() {
		this.baseurl = this.$route.path;
		console.log(this.baseurl + "!!")
		this.getTab(this.$route);
	},
	
	mounted() {
	},

	beforeRouteEnter (to, from, next) {
		next(vm => {
			vm.getTab(to)
			console.log('before!', to)
			// access to component public instance via `vm`
		})
	},
	
	
	methods: {
		// loadItems() {
		// 	PBNSource.getHostingList(this.serverParams).then(response => {
		// 		this.totalRecords = response.data.totalRecords;
		// 		this.rows = response.data.rows;
				
		// 		console.log(this.totalRecords + " records");
		// 	});
		// },
		prepareAdd() {
			this.$refs.pbnhostingadd.resetData();
			this.showTab('add');
		},
		
		showTab(which) {
			this.active = which;
			return which;
		},
		showDetails(id) {
			this.hoid = parseInt(id);
			console.log("set id to ", this.hoid);
			this.showTab('details');
		},
		getTab(addr) {
			if(!addr) {
				this.showTab('list');
			}
			else if(addr.params?.id) {
				this.detailurl = addr.fullPath
				this.showDetails(addr.params.id)
			}
			else if(addr.path && addr.path.match(/todos/)) {
				this.showTab('todos');
				
				PBNSource.getHostingTodos().then(response => {
					this.totalTodos = response.data.totalRecords;
					this.todos = response.data.rows;
				})
			}
			else if(addr.path && addr.path.match(/add/)) {
				this.showTab('add');
			}
			else if(addr.tab) {
				if(addr.tab == "add") {
					this.showTab('add');
				}
			}
			else {
				this.showTab('list');
			}
		},
		
		updateListHandler(data) {
			console.log("updating list with ", data)
			this.$refs.pbnhostingList.updateList(data);
		},
		
		addListHandler(data) {
			console.log("add to list: ", data)
			this.$refs.pbnhostingList.addToList(data);
		},

		removeFromListHandler(data) {
			console.log("remove from list: ", data)
			this.$refs.pbnhostingList.removeFromList(data);
		}
	}

}
