<template>
	<nav class="navbar navbar-expand-lg navbar-dark">
		<div class="container-fluid">
			<router-link class="navbar-brand" to="/"><img class="navimg" src="/images/office_logo_paths.svg"/></router-link>
			<button
				class="navbar-toggler"
				type="button"
				data-bs-toggle="collapse"
				data-bs-target="#navbarSupportedContent"
				aria-controls="navbarSupportedContent"
				aria-expanded="false"
				aria-label="Toggle navigation"
			>
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse" id="navbarSupportedContent">
				<ul class="navbar-nav me-auto mb-2 mb-lg-0">
					<li class="nav-item">
						<router-link :class="{'nav-link': true, 'active': activenav === 'Home'}" aria-current="page" to="/">Home</router-link>
					</li>
					<li class="nav-item">
						<router-link :class="{'nav-link': true, 'active': activenav === 'PBN'}" to="/pbn/">PBN</router-link>
					</li>
					<li class="nav-item">
						<router-link :class="{'nav-link': true, 'active': activenav === 'Hosting'}" to="/hosting/">Hosting</router-link>
					</li>
					<li class="nav-item">
						<router-link :class="{'nav-link': true, 'active': activenav === 'Domains'}" to="/domains/">Domains</router-link>
					</li>
					<li class="nav-item">
						<router-link :class="{'nav-link': true, 'active': activenav === 'Accounting'}" to="/accounting/">Accounting</router-link>
					</li>
					<li class="nav-item">
						<router-link :class="{'nav-link': true, 'active': activenav === 'Statistics'}" to="/statistics/">Statistics</router-link>
					</li>
					<!-- <li class="nav-item dropdown">
						<a
							class="nav-link dropdown-toggle"
							href="#"
							id="navbarDropdown"
							role="button"
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							Dropdown
						</router-link>
						<ul class="dropdown-menu" aria-labelledby="navbarDropdown">
							<li><router-link class="dropdown-item" href="#">Action</router-link></li>
							<li><router-link class="dropdown-item" href="#">Another action</router-link></li>
							<li><hr class="dropdown-divider" /></li>
							<li><router-link class="dropdown-item" href="#">Something else here</router-link></li>
						</ul>
					</li>
					<li class="nav-item">
						<router-link class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</router-link>
					</li> -->
				</ul>
				<div class="d-flex">
					<div class="roundicon" style="background:white;padding:8px;">
						{{ formattedTimeout }}!

						<BIconPersonXFill style="height:32px;width:32px;" @click="logout" />
					</div>
				</div>
			</div>
		</div>
	</nav>
</template>

<script>
// import commonMessage from './locales/common.json'
import { mapState } from "vuex"; //eslint-disable-line no-unused-vars
import * as dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

// import { inject } from "vue";
// import { defineComponent } from 'vue'

export default {
	name: "Menu",

	props: ["count"],

	// setup(props) {
	//   const userLocation = inject('currentTime');
	// 	const dummy = inject("dumbo");
	// 	return {
	// 		dummy,
	// 		userLocation,
	// 		...props
	// 	}
	// },

	// setup() {
	// 	// Counter
	// 	const count = ref(0);

	// 	// Current time
	// 	const currentTime = ref(new Date());
	// 	const updateCurrentTime = () => {
	// 		currentTime.value = new Date();
	// 	};
	// 	const intervalHandle = setInterval(updateCurrentTime, 1000);
	// 	onBeforeUnmount(() => {
	// 		clearInterval(intervalHandle);
	// 	});

	// 	return { count, currentTime };
	// },

	data() {
		return {
			active: 'home'
		};
	},
	components: {},
	computed: {
		...mapState({
			loggedin: (state) => state.auth.loggedin,
			activenav: (state) => state.misc.activenav
		}),
		formattedTimeout() {
			// `this` points to the vm instance
			var nn = dayjs.duration(this.count, 'seconds').format('H[h] m[m] s[s]')
			return nn;
		},
		// getActiveTab() {
			
		// }
	},


	methods: {
		logout() {
			this.$emit("onLogOut");
		},
	},
};
</script>
