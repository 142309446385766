/* eslint-disable no-unused-vars */
import {
	AUTH_REQUEST,
	AUTH_ERROR,
	AUTH_SUCCESS,
	AUTH_LOGOUT,
	AUTH_REFRESH
} from "../actions/auth";
// eslint-disable-next-line no-unused-vars
import axios from "axios";
import VueJwtDecode from "vue-jwt-decode";
// eslint-disable-next-line no-unused-vars
// const API_URL = process.env.VUE_APP_API_URL || "http://localhost:3000/api";
// console.log("using base ", API_URL)
import API from "../../js/backend/axios";
const state = {
	userdata: JSON.parse(localStorage.getItem("userData")) || {},
	admindata: JSON.parse(localStorage.getItem("adminData")) || {},
	token: localStorage.getItem("adminToken") || "",
	status: "ola",
	hasLoadedOnce: false,
	basecontacts: (localStorage.getItem("basecontacts") != 'undefined') ? JSON.parse(localStorage.getItem("basecontacts")) : [],
	loggedin: (typeof (localStorage.getItem("userData")) != 'string') ? false : true
};

const getters = {
	token: state => state.token,
	clid: state => state.clid,
	isAuthenticated: state => !!state.token,
	authStatus: state => state.status,
	hasLoggedOut: state => state.loggedout,
	getUserdata: state => state.userdata,
	basecontacts: state => state.basecontacts
};

const actions = {
	[AUTH_REQUEST]: ({ commit, dispatch }, user) => {
		commit(AUTH_REQUEST);
		return API.post("/signintooffice", user)
			.then(resp => {
				if (resp.data.error) {
					return { loginError: resp.data.error };
				}
				else {
					const token = resp.data.jwt;
					localStorage.setItem("adminToken", token);
					// console.log(resp.data)
					const decodedToken = VueJwtDecode.decode(resp.data.jwt);
					console.log(decodedToken)
					var userdata = decodedToken.context.user;
					var admindata = (decodedToken.context.admin) ? decodedToken.context.admin : {};
					localStorage.setItem("userData", JSON.stringify(userdata)); // store the token in localstorage
					localStorage.setItem("adminData", JSON.stringify(admindata)); // store the token in localstorage

					commit(AUTH_SUCCESS, {
						token: token,
						userdata: userdata,
						admindata: admindata
					});
					// you have your token, now log in your user :)
					return resp.data;
				}
			})
			.catch(err => {
				commit(AUTH_LOGOUT, err);
				localStorage.removeItem("adminToken"); // if the request fails, remove any possible user token if possible
				throw err.response;
			});
	},
	[AUTH_LOGOUT]: ({ commit, dispatch }) => {
		return new Promise((resolve, reject) => {
			commit(AUTH_LOGOUT);
			console.log("Logged out");
			console.log(state.loggedout);
			localStorage.removeItem("adminToken");
			localStorage.removeItem("userData");
			localStorage.removeItem("users");
			localStorage.removeItem("departments");
			localStorage.removeItem("tickets");
			resolve();
		});
	},
	[AUTH_REFRESH]: ({ commit, dispatch }) => {
		return API.post("/refresh")
			.then(resp => {
				if (resp.data.error) {
					return { refreshError: resp.data.error };
				}
				else {
					const token = resp.data.jwt;
					localStorage.setItem("adminToken", token);
					// console.log(resp.data)
					const decodedToken = VueJwtDecode.decode(resp.data.jwt);
					console.log(decodedToken)
					var userdata = decodedToken.context.user;
					var admindata = (decodedToken.context.admin) ? decodedToken.context.admin : {};
					localStorage.setItem("userData", JSON.stringify(userdata)); // store the token in localstorage
					localStorage.setItem("adminData", JSON.stringify(admindata)); // store the token in localstorage

					commit(AUTH_SUCCESS, {
						token: token,
						userdata: userdata,
						admindata: admindata
					});
					// you have your token, now log in your user :)
					return token;
				}
			})
			.catch(err => {
				commit(AUTH_ERROR, err);
				// localStorage.removeItem("adminToken"); // if the request fails, remove any possible user token if possible
				throw err.response;
			});
	},
};

const mutations = {
	[AUTH_REQUEST]: state => {
		state.status = "loading";
	},
	[AUTH_SUCCESS]: (state, resp) => {
		state.status = "success";
		state.token = resp.token;
		state.loggedin = true;
		state.userdata = resp.userdata;
		state.admindata = resp.admindata;
		state.hasLoadedOnce = true;
	},
	[AUTH_ERROR]: state => {
		state.status = "error";
		state.hasLoadedOnce = true;
	},
	[AUTH_LOGOUT]: state => {
		state.token = "";
		state.userdata = {};
		state.loggedin = false;
	},
	[AUTH_REFRESH]: state => {
		state.loggedin = true;
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
