/* eslint-disable no-undef */
import axios from "axios";
import store from "../../store";

const getToken = () => {
	return localStorage.getItem("adminToken");
};

const API_URL = process.env.VUE_APP_API_URL || "http://localhost:3000/api";

const axiosInstance = axios.create({
	baseURL: API_URL
	// headers: {
	// 	"Content-Type": "application/json",
	// 	"Authorization": "Bearer " + token
	// }
});

axiosInstance.interceptors.request.use(config => {
	config.headers.common["Content-Type"] = "application/json";
	config.headers.common["Authorization"] = "Bearer " + getToken();
	return config;
});



// axios.interceptors.response.use(function (response) {
//   return response
// }, function (error) {
//   console.log(error.response.data)
//   if (error.response.status === 401) {
//     store.dispatch('logout')
//     router.push('/login')
//   }
//   return Promise.reject(error)
// })


axiosInstance.interceptors.response.use(
	function(response) {
		if (response.headers["authtoken"]) {
			// console.log(response.headers);
			localStorage.setItem("adminToken", response.headers["authtoken"]);
		}

		return response;
	},
	function(error) {
		let axios_error = error
		if (axios_error.response.status == 401) {
			// eslint-disable-next-line no-undef
			if(axios_error.config && axios_error.config.url && axios_error.config.url == "/signintooffice") {
				// if the 401 comes from /login then the dispatcher does not work, no idea wyhy
			}
			else	{
			store.dispatch(AUTH_LOGOUT)
			router.push("/login");
			}
		} else if (![400, 422].includes(error.response.status)) {
			Vue.prototype.$toastr.Add({
				title: "Attention",
				msg:
					'An error occured. Please contact the support: "' +
					error.message +
					'"',
				position: "toast-bottom-center",
				type: "error"
			});
		}
		return Promise.reject(axios_error);
	}
);
export default axiosInstance;
