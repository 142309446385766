import PBNLayout from "@/Layouts/PBNLayout.vue";
import PBNHostsDetails from "@/components/PBN/HostsDetails.vue";
import PBNHostsList from "@/components/PBN/PBNHostsList.vue";
import { VueGoodTable } from 'vue-good-table-next';
import PBNSource from '@/js/backend/pbn';
export default {

	name: 'Home',

	components: {
		PBNLayout,
		PBNHostsDetails,
		PBNHostsList,
		VueGoodTable
	},
	data() {
		return {
			baseurl: '',
			detailurl: '',
			//Good Table Stuff
			isLoading: false,
			active: 'list',
			hid: 0
		}
	},
	created() {
		this.baseurl = this.$route.path;
		console.log(this.baseurl + "!!")
		this.getTab(this.$route);
	},
	
	beforeRouteEnter (to, from, next) {
		next(vm => {
			vm.getTab(to)
			console.log('before!', to)
			// access to component public instance via `vm`
		})
	},

	// beforeRouteUpdate(to, from, next) {  // eslint-disable no-unused-vars
	// 	// react to route changes...
	// 	// don't forget to call next()
	// 	if(to.query?.id) {
	// 		this.detailurl = this.baseurl + '?id=' + to.query.id;
	// 		this.showDetails(to.query.id)
	// 	}
	// 	else if(to.query?.new) {
	// 		this.showTab('add');
	// 	}
	// 	else {
	// 		this.showTab('list');
	// 	}
	// 	console.log("go to ", to.query.id);
	// 				this.$forceUpdate();

	// 	next();
	// },

	methods: {
		showTab(which) {
			this.active = which;
			return which;
		},
		showDetails(id) {
			this.hid = parseInt(id);
			console.log("set id to ", this.hid);
			this.showTab('details');
		},
		getTab(addr) {
			console.log("Get tab called for ", addr)
			if(!addr) {
				this.showTab('list');
			}
			else if(addr.params?.id) {
				console.log("show details for ", addr.params.id)
				this.detailurl = addr.fullPath
				this.showDetails(addr.params.id)
			}
			else if(addr.tab) {
				if(addr.tab == "add") {
					this.showTab('add');
				}
			}
			else {
				this.showTab('list');
			}
		},
		
		updateListHandler(data) {
			console.log("updating list with ", data)
			this.$refs.pbnhostsList.updateList(data);
		},
		removeFromListHandler(data) {
			this.rows.splice(this.rows.findIndex(v => v.H_ID === data.H_ID), 1);
		},
	}
}
