<template>
	<div class="container-fluid pbn">
		<div class="row">
			<h2 class="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
				PBN DNS
			</h2>
			<ul class="nav nav-tabs">
				<li :class="{ 'nav-item': true, 'active' : (active == 'list')}">
					<router-link :class="'nav-link'" aria-current="List" :to="baseurl" @click="showTab('list')">List</router-link>
				</li>
				<li :class="{ 'nav-item': true, 'active' : (active == 'details')}">
					<router-link :class="'nav-link '" aria-current="Details" :to="detailurl" @click="showTab('details')"
						>Details</router-link>
				</li>
				<li :class="{ 'nav-item': true, 'active' : (active == 'add')}">
					<router-link :class="'nav-link '" aria-current="Details" :to="baseurl + '?new=1'" @click="showTab('add')"
						>Add</router-link>
				</li>
			</ul>
		</div>
		<div class="row content" id="list" v-show="active == 'details'">
			<div class="col">
				<PBNDnsDetails :itemid="hid"/>
			</div>
		</div>
		<div class="row content" id="list" v-show="active == 'add'">
			<div class="col">
				<PBNDnsDetails itemid="0"/>
			</div>
		</div>

		<div class="row content goodtable" id="list" v-show="active == 'list'">
			<div class="col">
				<vue-good-table
					:columns="columns"
					:rows="rows"
					:pagination-options="{
						enabled: true,
						mode: 'records',
						perPage: 20,
						position: 'top',
						perPageDropdown: [3, 7, 9],
						dropdownAllowAll: false,
						setCurrentPage: 1,
						nextLabel: 'next',
						prevLabel: 'prev',
						rowsPerPageLabel: 'Rows per page',
						ofLabel: 'of',
						pageLabel: 'page', // for 'pages' mode
						allLabel: 'All',
					}"
					styleClass="vgt-table striped"
				>
					>
					<template #table-row="props">
						<span v-if="props.column.field == 'Action'">
							<router-link class="btn btn-primary btn-sm" :to='"/pbn/dns/list?id=" + props.row.DNS_ID'>
								Details
							</router-link>
							<span style="padding-right:20px">&nbsp;</span>
							<a class="btn btn-success btn-sm" :href="props.row.DNS_ADMIN_URL" target="_new">
								Login
							</a>
							<span style="padding-right:20px">&nbsp;</span>
							<button class="btn btn-danger btn-sm" @click="deleteDns(props.row.DNS_ID)">
								Delete
							</button>
							
							<!-- <button class="btn btn-primary btn-sm" @click="showDetails(props.row)">
								Details
							</button> -->
						</span>
						<span v-else>
							{{ props.formattedRow[props.column.field] }}
						</span>
					</template>
				</vue-good-table>
			</div>
		</div>
	</div>
</template>

<script src="./DNS.js"></script>
