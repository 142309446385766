// eslint-disable-next-line
import API from "./axios";
export default {
	// eslint-disable-next-line
	getInvoicesList(params) {
		return API.get("/admin/invoices/list",{params: params});
	},
	getInvoicesShortList(params) {
		return API.get("/admin/invoices/shortlist",{params: params});
	},
	getInvoice(id) {
		return API.get("/admin/invoices/single/" + id);
	},
	editInvoice(data) {
		return API.post("/admin/invoices/edit", data);
	},
	addInvoice(data) {
		return API.post("/admin/invoices/add", data);
	},
	deleteInvoice(hid) {
		return API.post("/admin/invoices/delete", {id: hid});
	},
	setInvoiceValue(id,key,value) {
		return API.post("/admin/invoices/setvalue", {id: id, key: key, value: value});
	},
	cloneInvoice(id) {
		return API.post("/admin/invoices/clone", {id: id});
	},
	downloadpdf(in_id) {
		return API.get("/inside/invoices/pdf/" + in_id, {
			responseType: "arraybuffer"
		});
	},
	sendInvoice(id) {
		return API.get("/inside/invoices/send/" + id);
	},
	editItem(item) {
		return API.post("/admin/invoices/edititem", item);
	},
	addItem(item) {
		return API.post("/admin/invoices/additem", item);
	},
	removeItem(id) {
		return API.post("/admin/invoices/removeitem", {id: id});
	},
	makePaymentCode(id) {
		return API.get("/admin/invoices/addcode/" + id);		
	}
};
