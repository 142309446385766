<template>
	<div class="hosting" id="serverdetails">
		<h2 v-if="item.S_ID > 0">Server #{{ item.S_ID }}</h2>
		<h2 v-else>Add Server</h2>
		<div class="container-fluid" v-if="loading">
			<div class="loader">
				<div
					class="spinner-grow text-success"
					style="width: 6rem; height: 6rem"
					role="status"
				></div>
				<p class="text-primary">Loading...</p>
			</div>
		</div>
		<div class="container-fluid details" v-else>
			<form @submit.prevent="editServer">
				<div class="row">
					<div class="col col-6">
						<div :class="{ error: v$.S_NAME.$errors.length }">
							<label for="servername" class="form-label">Server</label>
							<input
								type="text"
								class="form-control"
								id="servername"
								name="servername"
								v-model="item.S_NAME"
							/>
							<div
								class="input-errors"
								v-for="error of v$.S_NAME.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					<div class="col col-6">
						<div :class="{ error: v$.S_PROVIDER.$errors.length }">
							<label for="hostname" class="form-label">Provider</label>
							<input
								type="text"
								class="form-control"
								id="hostname"
								v-model="item.S_PROVIDER"
							/>
							<div
								class="input-errors"
								v-for="error of v$.S_PROVIDER.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<label for="site_ip" class="control-label">IP</label>
						<input
							type="text"
							class="form-control"
							name="site_ip"
							id="site_ip"
							v-model="item.S_IP"
						/>
					</div>
					<div class="col col-3">
						<label for="site_ram" class="control-label">RAM</label>
						<input
							type="text"
							class="form-control"
							name="site_ram"
							id="site_ram"
							v-model="item.S_RAM"
						/>
					</div>
					<div class="col col-3">
						<label for="site_first_payment" class="control-label">First Payment</label>
						<br/>
						<DatePicker :attributes='datePickerAttributes'  :masks="{ title: 'MMM YYYY', L: 'YYYY-MM-DD'  }" v-model="item.S_FIRST_PAYMENT">
							<template v-slot="{ inputValue, inputEvents }">
								<input
									class="form-control"
									
									:value="inputValue"
									v-on="inputEvents"
								/>
							</template>
						</DatePicker>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<label for="site_users" class="control-label">Used</label>
						<input
							type="text"
							class="form-control"
							name="site_users"
							id="site_users"
							v-model="item.S_USED"
						/>
					</div>
					<div class="col col-6">
						<label for="site_maxdomains" class="control-label"
							>Max. Domains</label
						>
						<input
							type="text"
							class="form-control"
							name="site_maxdomains"
							id="site_maxdomains"
							v-model="item.S_MAXDOMAINS"
						/>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<label for="ssh_user" class="control-label">SSH User</label>
						<input
							type="text"
							class="form-control"
							name="ssh_user"
							id="ssh_user"
							v-model="item.S_SSH_USER"
						/>
					</div>
					<div class="col col-6">
						<label for="site_ssh_pwd" class="control-label">SSH Password</label>
						<input
							type="text"
							class="form-control"
							name="site_ssh_pwd"
							id="site_ssh_pwd"
							v-model="item.S_SSH_PWD"
						/>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<label for="site_panel_url">Panel URL</label>
						<input
							type="text"
							class="form-control"
							name="M_RAM"
							id="M_RAM"
							v-model="item.S_PANEL_URL"
						/>
					</div>
					<div class="col col-6">
						<label for="site_panel_user" class="control-label"
							>Panel User</label
						>
						<input
							type="text"
							class="form-control"
							name="site_panel_user"
							id="site_panel_user"
							v-model="item.S_PANEL_USER"
						/>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<label for="site_panel_pwd" class="control-label"
							>Panel Password</label
						>
						<input
							type="text"
							class="form-control"
							name="site_panel_pwd"
							id="site_panel_pwd"
							v-model="item.S_PANEL_PWD"
						/>
					</div>
					<div class="col col-6">
						<div :class="{ error: v$.S_TARGET.$errors.length }">
							<label for="site_target" class="control-label"
								>Target Service</label
							>
							<input
								type="text"
								class="form-control"
								name="site_target"
								id="site_target"
								v-model="item.S_TARGET"
							/>
							<div
								class="input-errors"
								v-for="error of v$.S_TARGET.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<div :class="{ error: v$.S_AMOUNT.$errors.length }">
							<label for="site_amount" class="control-label">Amount</label>
							<input
								type="text"
								class="form-control"
								name="site_amount"
								id="site_amount"
								v-model="item.S_AMOUNT"
							/>
							<div
								class="input-errors"
								v-for="error of v$.S_AMOUNT.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					<div class="col col-3">
						<div :class="{ error: v$.S_CURRENCY.$errors.length }">
							<label for="currency" class="control-label"
								>Currency</label
							>
							<input
								type="text"
								class="form-control"
								name="currency"
								id="currency"
								v-model="item.S_CURRENCY"
							/>
							<div
								class="input-errors"
								v-for="error of v$.S_CURRENCY.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					<div class="col col-3">
						<div :class="{ error: v$.S_PAYMENT_INTERVAL.$errors.length }">
							<label for="site_payment_interval" class="control-label"
								>Interval (months)</label
							>
							<input
								type="text"
								class="form-control"
								name="site_payment_interval"
								id="site_payment_interval"
								v-model="item.S_PAYMENT_INTERVAL"
							/>
							<div
								class="input-errors"
								v-for="error of v$.S_PAYMENT_INTERVAL.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<div :class="{ error: v$.S_EARNINGS.$errors.length }">
							<label for="site_earnings" class="control-label">Earnings</label>
							<input
								type="text"
								class="form-control"
								name="site_earnings"
								id="site_earnings"
								v-model="item.S_EARNINGS"
							/>
							<div
								class="input-errors"
								v-for="error of v$.S_EARNINGS.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					<div class="col col-3">
						<div :class="{ error: v$.S_EARNING_INTERVAL.$errors.length }">
							<label for="site_payment_interval" class="control-label"
								>Interval (months)</label
							>
							<input
								type="text"
								class="form-control"
								name="site_payment_interval"
								id="site_payment_interval"
								v-model="item.S_EARNING_INTERVAL"
							/>
							<div
								class="input-errors"
								v-for="error of v$.S_EARNING_INTERVAL.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					<div class="col col-3">
						<label for="site_payment_interval" class="control-label"
							>Subscription</label
						>
						<br/>
						<Toggle
							v-model="item.S_SUBSCRIPTION"
							class="toggle-red"
							on-label="Active"
							off-label="Inactive"
						/>
					</div>
				</div>
				<div class="row">
					<div class="col col-4 text-center">
						<input
							class="btn btn-primary"
							type="submit"
							name="su"
							:value="submitLabel"
						/>
					</div>
					<div class="col col-4 text-center">
						<button type="button" class="btn btn-danger" @click="deleteServer">
							Delete
						</button>
					</div>
					<div class="col col-4 text-center" v-if="item.S_ID < 1">
						<button type="button" class="btn btn-warning" @click="setDummy">
							Dummy
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script src="./ServerDetails.js"></script>
<style lang="scss">
@import "../../assets/scss/main.scss";

#serverdetails {
	.collapse .container-fluid {
		padding-left: 0;
		.col {
			padding-left: 0;
		}
	}
	.toggle-red {
		.toggle {
			width: 90px !important;
			color: white;
			font-weight: bold;
			&.toggle-off {
				padding-right: 20px;
			}
			&.toggle-on {
				padding-left: 20px;
			}
		}
		--toggle-bg-off: #a00e0e;
		--toggle-border-off: #a00e0e;
		--toggle-bg-on: #{$color-success};
		--toggle-border-on: #{$color-success};
	}
}
</style>

<!--
						<div>
							<label for="P_NEXT_PAYMENT" class="control-label"
								>Next Pay:</label
							>
						</div>
						<Calendar
							id="P_NEXT_PAYMENT"
							v-model="item.P_NEXT_PAYMENT"
							dateFormat="yy-mm-dd"
						/>

						Host: <br /><Dropdown
							v-model="item.M_HID"
							:options="hosts"
							optionLabel="H_NAME"
							optionValue="H_ID"
							placeholder="Select one"
						/>
						
												<label for="M_SYS" class="control-label">System:</label>
						<select
							class="form-control"
							name="M_SYS"
							id="M_SYS"
							v-model="item.M_SYS"
						>
							<option value="">---</option>
							<option value="Vesta">Vesta</option>
							<option value="Hestia">Hestia</option>
							<option value="Webmin">Webmin</option>
						</select>

						
-->