// import InvoicesDetails from "./ClientDetails.vue";
import { VueGoodTable } from 'vue-good-table-next';
import { ref, onBeforeMount } from "vue";
import { useRoute } from 'vue-router'

import { createToaster } from "@meforma/vue-toaster";
import CustomArticleModal from "./CustomArticleModal.vue";
import CustomArticlesSource from '@/js/backend/customarticles';

export default {
	components: {
		VueGoodTable,
		CustomArticleModal
	},

	setup() {
		const toaster = createToaster({ position: 'top' });
		const route = useRoute()
		const caid = ref(0);
		const customarticlemodalwindow = ref();

		const rows = ref([]);
		const columns = ref([
			{ label: 'ID', field: 'CA_ID', type: 'number' },
			{
				label: 'Client',
				field: 'CLIENT',
				filterOptions: {
					styleClass: 'class1', // class to be added to the parent th element
					enabled: true, // enable filter for this column
				}
			},
			{
				label: 'Title',
				field: 'CA_TITLE',
			},
			{
				label: 'Due',
				field: 'CA_DUE',
			},
			{
				label: 'Status',
				field: 'CA_STATUS',
				type: 'number'
			},
			{
				label: 'Recurring',
				field: 'CA_RECURRING',
				type: 'number'
			},
			{
				label: 'Price',
				field: 'CA_PRICE',
				type: 'number'
			},
			{ label: 'Period', field: 'CA_PERIOD', sortable: false	},
			{ label: 'Details', field: 'Details', sortable: false },
			{ label: 'Delete', field: 'Delete', sortable: false },
		]);
		
		
		const totalRecords = ref(0);
		const serverParams = ref ({
			// a map of column filters example: {name: 'john', age: '20'}
			columnFilters: {},
			sort: [
				{
					field: "CA_ID", // example: 'name'
					type: "desc", // 'asc' or 'desc'
				},
			],

			page: 1, // what page I want to show
			perPage: 20, // how many items I'm showing per page
		});

		onBeforeMount(() => {
			loadItems();
		});
		
		function showDetails(data) {
			customarticlemodalwindow.value.setDetails(data)
		}
		
		
		function updateParams(newProps) {
			serverParams.value = Object.assign({}, serverParams.value, newProps);
			console.log("Filter changed", serverParams.value);
		}

		function onPageChange(params) {
			updateParams({ page: params.currentPage });
			loadItems();
		}

		function onPerPageChange(params) {
			updateParams({ perPage: params.currentPerPage });
			loadItems();
		}

		function onSortChange(params) {
			updateParams({
				sort: [
					{
						type: params[0].type,
						field: params[0].field,
					},
				],
				page: 1,
			});
			loadItems();
		}

		function onColumnFilter(params) {
			updateParams(params);
			loadItems();
		}

		// load items is what brings back the rows from server
		function loadItems() {
			rows.value = [];
			CustomArticlesSource.list(serverParams.value).then((response) => {
				rows.value = response.data.rows;
				totalRecords.value = response.data.totalRecords;
				console.log(totalRecords.value + " records");
			});
		}

		function updateList(data) {
			console.log("arrived in the list with ", data)
			for (const obj of rows.value) {
				if (obj.CA_ID === data.CA_ID) {
					console.log("MATCH ", obj.CA_ID)
					obj.CA_DUE = data.CA_DUE;
					obj.CA_TITLE = data.CA_TITLE;
					obj.CA_PRICE = data.CA_PRICE;
					obj.CA_RECURRING = data.CA_RECURRING;
					obj.CA_PERIOD = data.CA_PERIOD;
					break;
				}
			}
			return true;
		}
		
		function deleteArticle(id) {
			if(confirm("SURE??")) {
				CustomArticlesSource.remove(id).then((response) => {
					if (response.data.Error) {
						toaster.error(
							"Could not delete invoice: \n<br/>" + response.data.Error
						);
						console.error(response.data.Error);
					} else {
						toaster.success("Invoice deleted");
						removeFromList(id);
					}
				})
				.catch((error) => {
					toaster.error("Could not delete invoice ", error);
				});
			}
		}
		
		function toggleStatus(idx) {
			console.log(idx, " is ", rows.value[idx]);
			rows.value[idx].CA_STATUS = (rows.value[idx].CA_STATUS == 1) ? 0 : 1;
			CustomArticlesSource.setValue(rows.value[idx].CA_ID,'CA_STATUS',rows.value[idx].CA_STATUS).then((response) => {
				if (response.data.Error) {
					toaster.error(
						"Could not set status: \n<br/>" + response.data.Error
					);
					console.error(response.data.Error);
				} else {
					toaster.success("Status set");
				}
			})
			.catch((error) => {
				toaster.error("Could not set status ", error);
			});
		}
		
		function toggleRecurring(idx) {
			console.log(idx, " is ", rows.value[idx]);
			rows.value[idx].CA_RECURRING = (rows.value[idx].CA_RECURRING == 1) ? 0 : 1;
			CustomArticlesSource.setValue(rows.value[idx].CA_ID,'CA_RECURRING',rows.value[idx].CA_RECURRING).then((response) => {
				if (response.data.Error) {
					toaster.error(
						"Could not set status: \n<br/>" + response.data.Error
					);
					console.error(response.data.Error);
				} else {
					toaster.success("Status set");
				}
			})
			.catch((error) => {
				toaster.error("Could not set status ", error);
			});
		}
		
		function addToList() {
			onSortChange([{"type": "desc", "field": "CA_ID"}]);
			loadItems();
			
			// rows.value.push(data)
		}
		
		function removeFromList(id) {
			rows.value.splice(rows.value.findIndex(v => v.CA_ID === id), 1);
			console.log("removed ID ", id)
		}
		
		function rowStyleClassFn(row) {
			if (row.CA_STATUS == 0) {
				return "status status__inactive";
			} 
			return "status";
		}
		
		
		return {
			rows,
			totalRecords,
			columns,
			customarticlemodalwindow,
			updateList,
			addToList,
			removeFromList,
			showDetails,
			onColumnFilter,
			onSortChange,
			onPerPageChange,
			onPageChange,
			rowStyleClassFn,
			deleteArticle,
			toggleRecurring,
			toggleStatus
		}
		
	}
}

