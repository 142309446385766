<template>
	<div class="container-fluid pbn">
		<div class="row">
			<h2 class="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
				PBN Hosts
			</h2>
			<ul class="nav nav-tabs">
				<li :class="{ 'nav-item': true, 'active' : (active == 'list')}">
					<router-link :class="'nav-link'" aria-current="List" to="/pbn/hosts/list" @click="showTab('list')">List</router-link>
				</li>
				<li :class="{ 'nav-item': true, 'active' : (active == 'details')}">
					<router-link :class="'nav-link '" aria-current="Details" :to="detailurl" @click="showTab('details')"
						>Details</router-link>
				</li>
				<li :class="{ 'nav-item': true, 'active' : (active == 'add')}">
					<router-link :class="'nav-link '" aria-current="Details" :to="baseurl + '?new=1'" @click="showTab('add')"
						>Add</router-link>
				</li>
			</ul>
		</div>
		<div class="row content" id="list" v-show="active == 'details'">
			<div class="col">
				<PBNHostsDetails :itemid="hid"  @updateList="updateListHandler"  @removeFromList="removeFromListHandler"/>
			</div>
		</div>
		<div class="row content" id="list" v-show="active == 'add'">
			<div class="col">
				<PBNHostsDetails :itemid="0"/>
			</div>
		</div>

		<div class="row content goodtable" id="list" v-show="active == 'list'">
			<PBNHostsList ref="pbnhostsList"/>
		</div>
	</div>
</template>

<script src="./Hosts.js"></script>
