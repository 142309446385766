import auth from "./modules/auth";
import misc from "./modules/misc";
import pbn from "./modules/pbn";
import hosting from "./modules/hosting";
import accounting from "./modules/accounting";
import domains from "./modules/domains";

import { createStore } from 'vuex'

export default new createStore({
  modules: {
		auth,
		misc,
		pbn,
		hosting,
		accounting,
		domains
  },
})
