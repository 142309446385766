<template>
	<div
		id="detailsmodal"
		class="
			modal-dialog-scrollable modal-xl modal-fullscreen-lg-down
			transfermodal
			modal-dialog
		"
	>
		<div class="modal-content">
			<div class="container-fluid text-center">
				<h3
					class="
						text-3xl
						font-extrabold
						leading-9
						tracking-tight
						text-gray-900
						sm:text-4xl sm:leading-10
					"
				>
					Incoming Transfer for client <b>{{ client }}</b>
				</h3>
			</div>
			<div class="modal-body">
				<div class="container-fluid text-center" v-if="loading">
					<div class="loader loader__sm">
						<div
							class="spinner-grow text-success"
							style="width: 2rem; height: 2rem"
							role="status"
						></div>
						<p class="text-primary">Loading...</p>
					</div>
				</div>
				<div class="container-fluid" style="min-height:250px" v-else>
					<form @submit.prevent="editTransfer">
					<div class="row">
						<div class="col col-3">
							<div class="title mb-2">Domain</div>
							<span class="success copy" @click="copyValue(details.DT_DOMAIN)"><i class="me-3 pi pi-file-o"></i>{{ details.DT_DOMAIN }}</span>
						</div>
						<div class="col col-3">
							<div class="title mb-2">Auth Code</div>
							<span class="success copy" @click="copyValue(details.DT_AUTH_CODE)"><i class="me-3 pi pi-file-o"></i>{{ details.DT_AUTH_CODE }}</span>
						</div>
						<div class="col col-3">
							<div class="title">Status</div>
								<select class="form-select" aria-label="Default select example" v-model="status" v-on:change="setStatus">
									<option v-for="(option,idx) in statusse" v-bind:key="idx" :value="option">
										{{ option}}
									</option>
								</select>
						</div>
						<div class="col col-3">
							<div class="title">Provider</div>
								{{providername}} {{details.DT_STATUS}}
						</div>
						
						
					</div>
					<div class="row">
						<!-- <div class="col col-3 mt-3">
							<button type="submit" class="btn btn-primary">
								Save
							</button>
						</div> -->
						<div class="col col-9 mt-3 fw-bold">						
							{{copytext}}
						</div>
					</div>

					<div v-if="error" class="row error mt-3">
						<div class="col col-12 mt-3">
							<ul>
								<li v-for="(item, index) in errors" v-bind:key="index">{{item}}</li>
							</ul>
						</div>
					</div>
				</form>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
					Close
				</button>
			</div>
		</div>
	</div>
</template>
		
<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import DomainsSource from "@/js/backend/domains";
import providers from "@/js/providers";
import { Modal } from "bootstrap";
import { useStore } from "vuex"; //eslint-disable-line no-unused-vars
import { createToaster } from "@meforma/vue-toaster";
import { ref, onMounted,onBeforeMount, computed, watch } from "vue";
export default {
	props: {},

	components: {
		vSelect
	},

	setup(props, { emit }) {
		name: "TransferModal";
		const store = useStore()

		const toaster = createToaster({ position: "top-left" });
		const theModal = ref();
		const details = ref({"DT_DOMAIN": "", "DT_AUTHCODE": "", "DT_STATUS": 0});
		const client = ref();
		const status = ref('');
		const providername = ref();
		const message = ref("");
		const error = ref(false);
		const errors = ref([]);
		const loading = ref(false);
		const copytext = ref("");

		const statusse = ref([
			"open", "progress", "done", "failed"
		])
	
		const clients = computed(() => store.state.accounting.clients);
		
		onBeforeMount(() => {
			store.dispatch("CLIENTS", true);
		})

		onMounted(() => {
			theModal.value = new Modal(document.getElementById("detailsModal"));
		});
		
		const setDetails = (data) => {
			details.value = data;
			const cldata = clients.value.find( ({ CL_ID }) => CL_ID ===  data.DT_CLIENTID);
			client.value = cldata.NAME
			const pdata = providers.find( ({ value }) => value ===  data.DT_PROVIDER);
			providername.value = pdata.text
			status.value = data.DT_STATUS
		}

		const setStatus = () => {
			console.log("setting status with", status.value);
			errors.value = []
			error.value = false;
			DomainsSource.setTransferStatus([details.value.DT_ID], status.value)
				.then((response) => {
					if (response.data.Error) {
						toaster.error(
							"Could not set status: \n<br/>" + response.data.Error
						);
						console.error(response.data.Error);
					} else {
						toaster.success("status changed");
						emit('setStatus', details.value);
					}
				})
				.catch((error) => {
					toaster.error("Could not add Server ", error);
				});

		};
	
		const dummy = () => {
			console.log("dummy with ", status.value)
			alert("huhuhuuhuhu");
		};

		const copyValue = (value) => {
			console.log(value);
			if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
				copytext.value = "Copied";
				setTimeout(function(){
					copytext.value = ""; 
				}, 2000);//wait 2 seconds
				return navigator.clipboard.writeText(value);
			}
			
			return Promise.reject('The Clipboard API is not available.');
		};


		return {
			dummy,
			message,
			error,
			errors,
			setDetails,
			loading,
			setStatus,
			copyValue,
			copytext,
			clients,
			client,
			details,
			statusse,
			status,
			providername,
		};
	},
};
</script>

<style lang="scss">
@import "../../assets/scss/main.scss";
#detailsmodal {
	.modal-content {
		font-size:1rem;
		padding: 5px;
	}
	
	
	.row.error {
		border:1px solid $color-error;
		li {
			font-size:16px;
			color: $color-error
		}
	}
	
	.copy {
		border:1px solid $color-success;
		color: $color-success;
		border-radius:.2rem;
		padding:.3rem;
		&:hover {
			cursor: pointer;
			color: $color-success-hover;
			border:1px solid $color-success-hover;
		}
	}
	
}
</style>
