// eslint-disable-next-line
import API from "./axios";
export default {
	// eslint-disable-next-line
	getArticleList(params) {
		return API.get("/admin/articles/list",{params: params});
	},
	getArticleShortList(type) {
		return API.get("/admin/articles/shortlist",{params: {type: type}});
	},
	getDomainPriceList(params) {
		return API.get("/admin/articles/domains/list",{params: params});
	},
	getDomainPrices(tld) {
		return API.get(`/admin/articles/domains/single/${tld}`);
	},
	setDomainPrices(params) {
		return API.post('/admin/articles/domains/edit', params);
	},
	addDomainPrices(params) {
		return API.post('/admin/articles/domains/add', params);
	},
	removeDomain(tld) {
		return API.post('/admin/articles/domains/delete', {DP_TLD: tld});
	},
	getHostingPriceList(params) {
		return API.get("/admin/articles/hosting/list",{params: params});
	},
	getHostingPrices(short) {
		return API.get(`/admin/articles/hosting/single/${short}`);
	},
	setHostingPrices(params) {
		return API.post('/admin/articles/hosting/edit', params);
	},
	addHostingPrices(params) {
		return API.post('/admin/articles/hosting/add', params);
	},
	removeHosting(short) {
		return API.post('/admin/articles/hosting/delete', {HP_SHORT: short});
	},
};
