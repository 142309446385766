<template>
	<div class="pbn">
		<h2 v-if="item.H_ID > 0">Host #{{ item.H_ID }}</h2>
		<h2 v-else>Add Host</h2>
		<div class="container-fluid" v-if="loading">
			<div class="loader">
				<div class="spinner-grow text-success" style="width: 6rem; height: 6rem;" role="status"></div>
				<p class="text-primary">Loading...</p>
			</div>
		</div>
		<div class="container-fluid details" v-else>
			<form @submit.prevent="editHost">
				<div class="row">
					<div class="col col-12">
						<label for="hostname" class="form-label">Name</label>
						<input type="text" class="form-control" id="hostname" v-model="item.H_NAME" />
					</div>
				</div>
				<div class="row">
					<div class="col col-9">
						<label for="H_URL" class="control-label">Login URL</label>
						<input type="text" class="form-control" name="H_URL" id="H_URL" v-model="item.H_URL" />
					</div>
					<div class="col col-3">
						<p/>
						<a class="btn btn-success" :href="item.H_URL" target="new">Login</a>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<label for="H_USERNAME" class="control-label">User</label>
						<input type="text" class="form-control" name="H_USERNAME" id="H_USERNAME" v-model="item.H_USERNAME" />
					</div>
					<div class="col col-6">
						<label for="H_PWD" class="control-label">Password</label>
						<input type="text" class="form-control" name="H_PWD" id="H_PWD" v-model="item.H_PWD" />
					</div>
				</div>
				<div class="row">
					<div class="col col-12">
						<label for="H_REMARKS" class="control-label">Remarks</label>
						<textarea
							class="form-control"
							name="H_REMARKS"
							id="H_REMARKS"
							style="height:60px"
							v-model="item.H_REMARKS"
						></textarea>
					</div>
				</div>
				<div class="row">
					<div class="col col-4 text-center">
						<input class="btn btn-primary" type="submit" name="su" value="Edit" />
					</div>
					<div class="col col-4 text-center">
						<button type="button" class="btn btn-danger" @click="deleteHost">Delete</button>
					</div>
					<div class="col col-4 text-center" v-if="item.H_ID < 1">
						<button type="button" class="btn btn-warning" @click="setDummy">Dummy</button>
					</div>
					<div class="col col-4 text-center" v-else>
					</div>
				</div>
			</form>
		</div>
		
		<div class="container-fluid details">
			<h3>Servers</h3>

			<table class="table">
				<thead>
					<tr>
						<th>IP</th>
						<th>HOSTNAME</th>
						<th>PACKAGES</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(entry, index) in item.SERVERS" v-bind:key="index">
						<th><router-link :to="`/pbn/server/list?id=${entry.M_ID}`">{{ entry.M_BASEIP }}</router-link></th>
						<td><router-link :to="`/pbn/server/list?id=${entry.M_ID}`">{{ entry.M_HOSTNAME }}</router-link></td>
						<td><router-link :to="`/pbn/server/list?id=${entry.M_ID}`">{{ entry.PACKAGES }}</router-link></td>
					</tr>
				</tbody>
			</table>
			
			
		</div>
	</div>
</template>

<script setup>
import PBNSource from "@/js/backend/pbn";
import { createToaster } from "@meforma/vue-toaster";
import { useRouter } from 'vue-router'
import { ref, watch, onMounted } from 'vue';

const router = useRouter()
const toaster = createToaster({ position: 'top' });
const emit = defineEmits(['updateList'])

const matrix = {
	H_ID: 0,
	H_NAME: "",
	H_URL: "",
	H_USERNAME: 0,
	H_PWD: "",
	H_REMARKS: "",
};

const dummy = {
	H_NAME: "Dummyhost",
	H_URL: "https://dummy.io/login",
	H_USERNAME: "Dummer",
	H_PWD: "Test1234!",
	H_REMARKS: "...und jetzt kommst Du!",
};

const props = defineProps({
		itemid: Number,
});

const host = ref(null);
const loading = ref(false);
const item = ref(matrix);

watch(() => props.itemid, (newVal, oldVal) => {
	console.log("itemid is ", props.itemid)
	if(newVal != oldVal) {
		if (newVal != null && newVal != 0) getDetails(newVal);
		else {
			item.value = matrix;
		}
	}
});


function getDetails(id) {
	loading.value = true;
	PBNSource.getHost(id)
		.then((response) => {
			item.value = response.data;
			loading.value = false;
		})
		.catch((error) => {
			toaster.error("Could not fetch data ", error);
			loading.value = false;
		});
}
		
onMounted(() => {
	getDetails(props.itemid)
});

function editHost() {
	console.log("SEND ", item.value);
	if (item.value.H_ID && item.value.H_ID > 0) {
		PBNSource.editHost(item.value)
			.then((response) => {
				console.log(response.data.Error);
				if (response.data.Error) {
					toaster.error("Could not edit Host: \n<br/>" + response.data.Error);
					console.log(response.data.Error);
				} else {
					toaster.success("Host updated");
					emit('updateList', item.value);
					getDetails(item.value.H_ID);
				}
			})
			.catch((error) => {
				toaster.error("Could not edit Host ", error);
			});
	}
	else {
		PBNSource.addHost(item.value)
			.then((response) => {
				console.log(response.data.Error);
				if (response.data.Error) {
					toaster.error("Could not add Host: \n<br/>" + response.data.Error);
					console.log(response.data.Error);
				} else {
					
					toaster.success("Host add");
					router.push({ path: '/pbn/hosts/list?id=' + response.data.H_ID});
				}
			})
			.catch((error) => {
				toaster.error("Could not edit Host ", error);
			});
	}
}
		
function deleteHost() {
	if(confirm("Are you absolutely sure? The world will be a different place")) {
		PBNSource.deleteHost(item.value.H_ID)
			.then((response) => {
				console.log(response.data.Error);
				if (response.data.Error) {
					toaster.error("Could not delete Host: \n<br/>" + response.data.Error);
					console.log(response.data.Error);
				} else {
					toaster.success("Host delete");
					this.$emit('removeFromList', item.value);
					item.value = matrix
					router.push({ path: '/pbn/hosts/list'});
				}
			})
			.catch((error) => {
				toaster.error("Could not delete Host ", error);
			});
	}
}
		
function  setDummy() {
	item.value = dummy;
}
</script>
