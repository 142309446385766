<template>
	<div class="container-fluid site">
		<div class="row">
			<div class="col-sm-12 col-lg-2 h-100 navcarry">
				<VerticalNavigation :menuitems="menuitems" />
			</div>
			<div class="col-sm-12 col-lg-10">
				<router-view/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "PBNLayout",
	data() {
		return {
			menuitems: [
				{
					topic: "Hosting",
					expanded: true,
					entries: [
						{ name: "Todos", url: "/pbn/hosting/todos" },
						{ name: "List", url: "/pbn/hosting/list" },
						{ name: "Add", url: "/pbn/hosting/list?tab=add" },
					],
				},
				{
					topic: "Server",
					expanded: false,
					entries: [
						{ name: "List", url: "/pbn/server/list" },
						{ name: "Add", url: "/pbn/server/list?tab=add" },
						{ name: "Availability", url: "/pbn/server/list?tab=analysis" },
					],
				},
				{
					topic: "DNS",
					expanded: false,
					entries: [
						{ name: "List", url: "/pbn/dns/list" },
						{ name: "Add", url: "/pbn/dns/list?tab=add" },
					],
				},
				{
					topic: "Hosts",
					expanded: false,
					entries: [
						{ name: "List", url: "/pbn/hosts/list" },
						{ name: "Add", url: "/pbn/hosts/add" },
					],
				},
			]
		};
	},
};
</script>
<style lang="scss">
@import "../assets/scss/pbn.scss";
</style>
