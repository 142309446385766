<template>
	<div
		class="
			contactmodal
			modal-dialog modal-dialog-scrollable modal-xl modal-fullscreen-lg-down
		"
	>
		<div class="modal-content">
			<div class="d-flex justify-content-between">
				<div>
					<h2>Add Handle</h2>
				</div>
				<div>
					<button class="btn btn-outline-info btn-sm" @click="reloadItems">Reload List</button>
				</div>
				<div>
					<router-link class="btn btn-outline-success btn-sm" to="/domains/contacts/add" target="_blank">Add Contact</router-link>
				</div>
			</div>

			<div class="modal-body">
				<vue-good-table
					mode="remote"
					:totalRows="totalRecords"
					:columns="columns"
					:rows="handles"
					styleClass="vgt-table condensed"
					:pagination-options="{
						enabled: true,
						mode: 'records',
						perPage: 20,
						position: 'both',
						perPageDropdown: [10, 20, 50, 100],
						dropdownAllowAll: false,
						nextLabel: 'next',
						prevLabel: 'prev',
						rowsPerPageLabel: 'Rows per page',
						ofLabel: 'of',
						pageLabel: 'page', // for 'pages' mode
						allLabel: 'All',
					}"
					v-on:page-change="onPageChange"
					v-on:sort-change="onSortChange"
					v-on:column-filter="onColumnFilter"
					v-on:per-page-change="onPerPageChange"
					:sort-options="{
						enabled: true,
					}"
				>
					styleClass="vgt-table striped" >
					<template #table-row="props">
						<span v-if="props.column.field == 'Action'">
							<button
								class="btn btn-success btn-sm"
								@click="useContact(props.row.CO_ID, props.row.CO_INTERNAL_ID)"
							>
								<span class="pi pi-user-plus"></span>
							</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<router-link
								class="btn btn-primary btn-sm"
								:to="'/domains/contacts/single/' + props.row.CO_ID"
								target="_new"
							>
								<span class="pi pi-external-link"></span>
							</router-link>
							
							<!-- <button class="btn btn-primary btn-sm" @click="showDetails(props.row)">
											Details
										</button> -->
						</span>
						<span v-else>
							{{ props.formattedRow[props.column.field] }}
						</span>
					</template>
				</vue-good-table>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
					Close
				</button>
			</div>
		</div>
	</div>
</template>
		
<script>
import DomainsSource from "@/js/backend/domains";
import { VueGoodTable } from "vue-good-table-next";
import { Modal } from 'bootstrap';
import { createToaster } from "@meforma/vue-toaster";
import { ref, onMounted } from "vue";
export default {
	props: {},

	components: {
		VueGoodTable,
		Modal
	},

	setup(props, { emit }) {
		name: "DomainAdd";

		const columns = [
			{
				label: "ID",
				field: "CO_ID",
				sortable: true,
				width: "14px",
				filterOptions: {
					styleClass: "class1", // class to be added to the parent th element
					enabled: true, // enable filter for this column
				},
			},
			{
				label: "Handle",
				field: "CO_INTERNAL_ID",
				sortable: true,
				filterOptions: {
					styleClass: "class1", // class to be added to the parent th element
					enabled: true, // enable filter for this column
				},
			},
			{
				label: "First name",
				field: "CO_FIRSTNAME",
				sortable: false,
				filterOptions: {
					styleClass: "class1", // class to be added to the parent th element
					enabled: true, // enable filter for this column
				},
			},
			{
				label: "Last name",
				field: "CO_LASTNAME",
				sortable: true,
				filterOptions: {
					styleClass: "class1", // class to be added to the parent th element
					enabled: true, // enable filter for this column
				},
			},
			{
				label: "Company",
				field: "CO_COMPANY",
				sortable: true,
				filterOptions: {
					styleClass: "class1", // class to be added to the parent th element
					enabled: true, // enable filter for this column
				},
			},
			{ label: "Action", field: "Action" },
		];

		const handles = ref([]);
		const totalRecords = ref(0);
		const toaster = createToaster({ position: "top-left" });
		const theModal = ref();
		const domaindata = ref({
			type: "",
			provider: 0,
			field: "",
			clientid: 0
		});
		
		const serverParams = ref ({
			// a map of column filters example: {name: 'john', age: '20'}
			columnFilters: {},
			sort: [
				{
					field: "CO_DISPLAY", // example: 'name'
					type: "asc", // 'asc' or 'desc'
				},
			],
			page: 1, // what page I want to show
			perPage: 20, // how many items I'm showing per page
		});
		
		// const handlebar = computed(() => {
		// 	if(props.type != '' && props.provider > 0 && props.clientid > 0) {
		// 		getHandlesOrOwners()
		// 		return 1;
		// 	}
		// 	return false;
		// });
		
		onMounted(() => {
			theModal.value = new Modal(document.getElementById('handleModal'));
		});
		
		const loadItems = () => {
			DomainsSource.getContactsQueryList(serverParams.value).then((res) => {
				handles.value = res.data.rows;
				totalRecords.value = res.data.totalRecords;
				console.log(totalRecords.value + " records");
			});
		}
		
		const getHandlesOrOwners = (type, field, provider, clientid) => {
			updateParams({CO_PROVIDER:provider, CO_CLID:clientid, type:type})
			domaindata.value = {
				type: type,
				provider: provider,
				field: field,
				clientid: clientid
			}

			
			console.log(
				`getting stuff! with ${provider} and ${type} and ${clientid}`
			);
			loadItems();
			// DomainsSource.getContactsQueryList({CO_PROVIDER:provider, CO_CLID:clientid, type:type})
			// 	.then((res) => {
			// 		if (!res || !res.data.rows) {
			// 			toaster.error("Cannot get contact list for this TLD: ");
			// 		} else if (res.data.Error) {
			// 			toaster.error(`Error: ${res.data.Error}`);
			// 		} else {
			// 			console.log(res.data)
			// 			if (res.data.rows && res.data.rows.length > 0) {
			// 				handles.value = res.data.rows;
			// 				totalRecords.value = res.data.totalRecords;
			// 				console.log(res.data.totalRecords, "!!")
			// 			} else return [];
			// 		}
			// 	})
			// 	.catch((error) => {
			// 		toaster.error(error, "Error getting Contactlist");
			// 	});
		};
		
		const useContact = (coid, coname) => {
			console.log(`Emit: ${coid}, ${coname}, ${domaindata.value.field}`)
			emit('addContact', coid, coname, domaindata.value.field);
			theModal.value.hide();
		}
	
		const reloadItems = () => {
			let {type, field, provider, clientid} = domaindata.value;
			getHandlesOrOwners(type, field, provider, clientid)
		}
	
		function updateParams(newProps) {
			serverParams.value = Object.assign({}, serverParams.value, newProps);
			console.log("Filter changed", serverParams.value);
		}

		function onPageChange(params) {
			updateParams({ page: params.currentPage });
			loadItems();
		}

		function onPerPageChange(params) {
			updateParams({ perPage: params.currentPerPage });
			loadItems();
		}

		function onSortChange(params) {
			updateParams({
				sort: [
					{
						type: params[0].type,
						field: params[0].field,
					},
				],
				page: 1,
			});
			loadItems();
		}

		function onColumnFilter(params) {
			updateParams(params);
			loadItems();
		}
		
		
		return {
			columns,
			getHandlesOrOwners,
			useContact,
			reloadItems,
			onColumnFilter,
			onSortChange,
			onPerPageChange,
			onPageChange,
			handles,
			totalRecords
		};
	},
};
</script>

<style lang="scss">
.contactmodal {
	.vgt-table th {
		padding: 0.1em 1em 0.1em 0.1em;
		&.filter-th {
			padding: 0.1em 0.1em 0.1em 0.1em;
		}
	}
	
	.modal-content {
		padding: 5px;
	}
}
</style>
