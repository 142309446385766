<template>
	<div id="invoiceitem" class="row" :class="getbg()">
		<div class="col col-6">
			<input type="text" class="form-control form-control-sm" id="headline" v-model="subitem.IT_NAME">
			<textarea class="form-control form-control-sm" id="itemtext" rows="3" v-model="subitem.IT_TEXT"></textarea>
		</div>
		<div class="col col-4">
			<input type="text" class="form-control form-control-sm" id="price" v-model="subitem.IT_PRICE">
		</div>
		<div class="col col-1" v-if="subitem.IT_ID">
			<BIconArrowRightSquareFill class="bootbutton booticons medium success" @click="editInvoiceItem(subitem)" />
		</div>
		<div class="col col-1" v-if="subitem.IT_ID">
			<BIconTrashFill class="bootbutton booticons medium alert" @click="removeInvoiceItem(subitem.IT_ID)" />
		</div>
		<div class="col col-1" v-else>
			<BIconPlusSquareFill class="bootbutton booticons medium success" @click="editInvoiceItem(subitem)" />
		</div>
	</div>
</template>

<script>
import { createToaster } from "@meforma/vue-toaster";
import InvoicesSource from '@/js/backend/invoices';
import { ref } from "vue";

export default {
	props: {
		item: Object,
		idx: Number
	},
	setup(props, { emit }) {
		const toaster = createToaster({ position: 'top' });
		const cursor = JSON.parse(JSON.stringify(props.item));
		const subitem = ref(cursor);
		
		const getbg = () => {
			const idplus = props.idx + 1;
			if(idplus % 2) {
				return 'even'
			}
			return 'odd'
		}
		const editInvoiceItem = async (item) => {
			console.log(item)
			if (item.IT_ID && item.IT_ID > 0) {
				console.log(item.IT_ID, " type ", typeof(item.IT_ID));
				InvoicesSource.editItem(item)
					.then((response) => {
						if (response.data.Error) {
							let err = response.data.Error.replace(/\n/g, "\n<br/>");
							toaster.error(
								"Could not edit Item: \n<br/>" + err
							);
							console.log(typeof(response.data.Error));
						} else {
							toaster.success("Item updated");
							emit('getDetails',item.IT_INVOICE);
						}
					})
					.catch((error) => {
						toaster.error("Could not edit Item ", error);
					});
			} else {
				InvoicesSource.addItem(item)
					.then((response) => {
						if (response.data.Error) {
							toaster.error(
								"Could not add Item: \n<br/>" + response.data.Error
							);
							console.error(response.data.Error);
						} else {
							toaster.success("Item added");
							emit('getDetails',item.IT_INVOICE);
							// emit('addToList', item.value);
						}
					})
					.catch((error) => {
						toaster.error("Could not add Item ", error);
					});
			}
		}
		
		const removeInvoiceItem = (id) => {
			InvoicesSource.removeItem(id)
			.then((response) => {
				if (response.data.Error) {
					toaster.error(
						"Could not remove Item: \n<br/>" + response.data.Error
					);
					console.error(response.data.Error);
				} else {
					toaster.success("Item removed");
					emit('getDetails',props.item.IT_INVOICE);
					// emit("removeFromList",id);
				}
			})
			.catch((error) => {
				toaster.error("Could not remove Item ", error);
			});

		}

		
		return {
			subitem,
			editInvoiceItem,
			removeInvoiceItem,
			getbg
		}
	},
}
</script>
<style lang="scss">
@import "../../assets/scss/main.scss";
#invoiceitem {
	padding: 8px 0;
	margin:0;
	&.even {
		background-color: white;
	}
	&.odd {
		background-color: $gray-300;
	}
}
</style>