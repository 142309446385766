<template>
	<div class="pbn" id="subscriptiondetails">
		<h2 v-if="item.H_ID > 0">Hosting #{{ item.H_ID }}</h2>
		<h2 v-else>Add Hosting</h2>
		<div class="container-fluid" v-if="loading">
			<div class="loader">
				<div
					class="spinner-grow text-success"
					style="width: 6rem; height: 6rem"
					role="status"
				></div>
				<p class="text-primary">Loading...</p>
			</div>
		</div>
		<div class="container-fluid details" v-else>
			<form @submit.prevent="editHosting">
				<div class="row">
					<div class="col col-6">
						<div :class="{ error: v$.H_NAME.$errors.length }">
							<label for="hostname" class="control-label">Name</label>
							<input
								type="text"
								class="form-control"
								id="domain"
								v-model="item.H_NAME"
							/>
							<div
								class="input-errors"
								v-for="error of v$.H_NAME.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					<div class="col col-6">
						<div :class="{ error: v$.H_CLIENTID.$errors.length }">
							Client: <br /><Dropdown
								v-model="item.H_CLIENTID"
								:options="clients"
								optionLabel="NAME"
								optionValue="CL_ID"
								placeholder="Select one"
							/>
							<div
								class="input-errors"
								v-for="error of v$.H_CLIENTID.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						Article: <br /><Dropdown
							:filter="true"
							v-model="item.H_ARTICLE"
							:options="articles"
							optionLabel="ART_NAME"
							optionValue="ART_ID"
							placeholder="Select one"
						/>

						<p />

						<div :class="{ error: v$.H_SERVERID.$errors.length }">
							Server: <br /><Dropdown
								:filter="true"
								v-model="item.H_SERVERID"
								:options="serverlist"
								optionLabel="S_NAME"
								optionValue="S_ID"
								placeholder="Select one"
							/>
							<div
								class="input-errors"
								v-for="error of v$.H_SERVERID.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					<div class="col col-6">
						<label for="M_REMARKS" class="control-label">Inc. Domains</label>
						<textarea
							class="form-control"
							style="height: 100px"
							v-model="item.H_INC_DOMAINS"
						></textarea>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<div :class="{ error: v$.H_DUE.$errors.length }">
							<label for="H_DUE" class="control-label">Due:</label>
							<input
								type="text"
								class="form-control"
								name="H_DUE"
								id="H_DUE"
								v-model="item.H_DUE"
							/>
							<div
								class="input-errors"
								v-for="error of v$.H_DUE.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					<div class="col col-6">
						<div :class="{ error: v$.H_INTERVAL.$errors.length }">
							<label for="H_INTERVAL" class="control-label"
								>Interval/months:</label
							>
							<input
								type="text"
								class="form-control"
								name="H_INTERVAL"
								id="H_INTERVAL"
								v-model="item.H_INTERVAL"
							/>
							<div
								class="input-errors"
								v-for="error of v$.H_INTERVAL.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<label for="H_USERNAME" class="control-label">Price:</label>
						<input
							type="text"
							class="form-control"
							name="H_PRICE"
							id="H_PRICE"
							v-model="item.H_PRICE"
						/>
					</div>
					<div class="col col-4">
						Status: <br /><Dropdown
							v-model="item.H_STATUS"
							:options="statuslist"
							optionLabel="STATUS_TEXT"
							optionValue="STATUS_ID"
							placeholder="Select one"
						/>
					</div>
					<div class="col col-2">
						<br />
						<Toggle
							v-model="item.H_CANCELLED"
							class="toggle-red"
							on-label="Cancelled"
							off-label="Active"
						/>
					</div>
				</div>
				<div class="row">
					<div class="col col-4 text-center">
						<input
							class="btn btn-primary"
							type="submit"
							name="su"
							:value="submitLabel"
						/>
					</div>
					<div class="col col-4 text-center">
						<button type="button" class="btn btn-danger" @click="deleteHosting">
							Delete
						</button>
					</div>
					<div class="col col-4 text-center" v-if="item.H_ID < 1">
						<button type="button" class="btn btn-warning" @click="setDummy">
							Dummy
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script src="./SubscriptionsDetails.js"></script>
<style lang="scss">
@import "../../assets/scss/main.scss";

#subscriptiondetails {
	.collapse .container-fluid {
		padding-left: 0;
		.col {
			padding-left: 0;
		}
	}
	.toggle-red {
		.toggle {
			width: 90px !important;
			color: white;
			font-weight: bold;
			&.toggle-off {
				padding-right: 10px;
			}
			&.toggle-on {
				padding-left: 10px;
			}
		}
		--toggle-bg-on: #a00e0e;
		--toggle-border-on: #a00e0e;
		--toggle-bg-off: #{$color-success};
		--toggle-border-off: #{$color-success};
	}
}
</style>