<template>
	<div class="contacts" id="contactadd">
		<h2>Add Contact</h2>
		<div class="container-fluid" v-if="loading">
			<div class="loader">
				<div
					class="spinner-grow text-success"
					style="width: 6rem; height: 6rem"
					role="status"
				></div>
				<p class="text-primary">Loading...</p>
			</div>
		</div>
		<div class="container-fluid details" v-else>
			<form @submit.prevent="addContact">
				<div class="row">
					<div class="col col-4">
						<div :class="{ error: v$.CO_CLID.$errors.length }">
							Client: <br /><Dropdown
								v-model="item.CO_CLID"
								:options="clients"
								optionLabel="NAME"
								optionValue="CL_ID"
								placeholder="Select one"
							/>
							<div
								class="input-errors"
								v-for="error of v$.CO_CLID.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					
					<div class="col col-2">
						Client Default Contact:<br/>
						<Toggle
							v-model="item.CO_CLIENTBASEACCOUNT"
							class="toggle-red"
							on-label="Yes"
							off-label="No"
							:falseValue="0"
							:trueValue="1"
						/>
					</div>
					
					<div class="col col-4">
						<div :class="{ error: v$.CO_PROVIDER.$errors.length }">
							Provider: <br /><Dropdown
								v-model="item.CO_PROVIDER"
								:options="providers"
								optionLabel="text"
								optionValue="value"
								placeholder="Select one"
							/>
							<div
								class="input-errors"
								v-for="error of v$.CO_PROVIDER.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					
					<div class="col col-2">
						Is Owner:<br/>
						<Toggle
							v-model="item.CO_ISOWNER"
							class="toggle-red"
							on-label="Yes"
							off-label="No"
							:falseValue="0"
							:trueValue="1"
						/>
					</div>
					
				</div>
				<div class="row">
					<div class="col col-6">
						<div :class="{ error: v$.CO_FIRSTNAME.$errors.length }">
							<label for="hostname" class="control-label">First Name</label>
							<input
								type="text"
								class="form-control"
								id="domain"
								placeholder="Contact Name"
								v-model="item.CO_FIRSTNAME"
							/>
							<div
								class="input-errors"
								v-for="error of v$.CO_FIRSTNAME.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					<div class="col col-6">
						<div :class="{ error: v$.CO_LASTNAME.$errors.length }">
							<label for="hostname" class="control-label">Last Name</label>
							<input
								type="text"
								class="form-control"
								id="domain"
								placeholder="Contact Last Name"
								v-model="item.CO_LASTNAME"
							/>
							<div
								class="input-errors"
								v-for="error of v$.CO_LASTNAME.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<div :class="{ error: v$.CO_TYPE.$errors.length }">
							Type: <br /><Dropdown
								v-model="item.CO_TYPE"
								:options="types"
								optionLabel="NAME"
								optionValue="ID"
								placeholder="Select one"
							/>
							<div
								class="input-errors"
								v-for="error of v$.CO_TYPE.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					
					<div class="col col-6">
						<div v-if="item.CO_TYPE == 'O'">
							<label for="company" class="control-label">Company</label>
							<input
								type="text"
								class="form-control"
								id="company"
								placeholder="Contact Last Name"
								v-model="item.CO_COMPANY"
							/>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col col-3">
						<div :class="{ error: v$.CO_TEL.$errors.length }">
							<label for="tel" class="control-label">Phone</label>
							<input
								type="text"
								class="form-control"
								id="company"
								placeholder="Contact Last Name"
								v-model="item.CO_TEL"
							/>
							<div
								class="input-errors"
								v-for="error of v$.CO_TEL.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					<div class="col col-3">
						<div :class="{ error: v$.CO_FAX.$errors.length }">
							<label for="tel" class="control-label">Fax</label>
							<input
								type="text"
								class="form-control"
								id="company"
								placeholder="Contact Last Name"
								v-model="item.CO_FAX"
							/>
							<div
								class="input-errors"
								v-for="error of v$.CO_FAX.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					
					<div class="col col-6">
						
						<div :class="{ error: v$.CO_EMAIL.$errors.length }">
							<label for="email" class="control-label">
								Email:
							</label>
							<input
								type="text"
								class="form-control"
								id="email"
								v-model="item.CO_EMAIL"
							/>
							<div
								class="input-errors"
								v-for="error of v$.CO_EMAIL.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<div :class="{ error: v$.CO_STREET.$errors.length }">
							<label for="hostname" class="control-label">Street</label>
							<input
								type="text"
								class="form-control"
								id="domain"
								v-model="item.CO_STREET"
							/>
							<div
								class="input-errors"
								v-for="error of v$.CO_STREET.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					<div class="col col-2">
						<div :class="{ error: v$.CO_ZIP.$errors.length }">
							<label for="hostname" class="control-label">ZIP</label>
							<input
								type="text"
								class="form-control"
								id="domain"
								v-model="item.CO_ZIP"
							/>
							<div
								class="input-errors"
								v-for="error of v$.CO_ZIP.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					<div class="col col-4">
						<div :class="{ error: v$.CO_CITY.$errors.length }">
							<label for="city" class="control-label">
								City:
							
							</label>
							<input
								type="text"
								class="form-control"
								id="city"
								v-model="item.CO_CITY"
							/>
							<div
								class="input-errors"
								v-for="error of v$.CO_CITY.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<label for="state" class="control-label">
							State:
						</label>
						<input
							type="text"
							class="form-control"
							id="state"
							v-model="item.CO_STATE"
						/>
					</div>
					<div class="col col-6">
						<div :class="{ error: v$.CO_COUNTRY.$errors.length }">
							<label for="hostname" class="control-label">Country</label>
							<br /><Dropdown
								v-model="item.CO_COUNTRY"
								:options="countries"
								optionLabel="text"
								optionValue="id"
								placeholder="Select one"
							/>
							<div
								class="input-errors"
								v-for="error of v$.CO_COUNTRY.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col col-12 fw-bold border-top border-dark">
						These items are not mandatory for all TLDs but needed for some. It does not hurt to fill them in!
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<div :class="{ error: v$.CO_BIRTHDAY.$errors.length }">
							<label for="site_first_payment" class="control-label">Birthday</label>
							<br/>
							<DatePicker 
								:attributes='datePickerAttributes'  
								:masks="{ title: 'MMM YYYY', L: 'YYYY-MM-DD'  }" 
								:model-config="datePickerModelConfig"
								v-model="item.CO_BIRTHDAY">
								<template v-slot="{ inputValue, inputEvents }">
									<input
										class="form-control"
										
										:value="inputValue"
										v-on="inputEvents"
									/>
								</template>
							</DatePicker>
							<div
								class="input-errors"
								v-for="error of v$.CO_BIRTHDAY.$errors"
								:key="error.$uid"
							>
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					<div class="col col-6">
						<label for="hostname" class="control-label">
							Pers. ID No:
						</label>
						<input
							type="text"
							class="form-control"
							id="domain"
							v-model="item.CO_PERSID"
						/>
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<label for="hostname" class="control-label">
							ID Issued on:
						</label>
						<input
							type="text"
							class="form-control"
							id="domain"
							v-model="item.CO_PERS_ISSUED"
						/>
					</div>
					<div class="col col-6">
						<label for="hostname" class="control-label">
							VAT ID:
						</label>
						<input
							type="text"
							class="form-control"
							id="domain"
							v-model="item.CO_VATID"
						/>
					</div>
				</div>

				<div class="row">
					<div class="col col-6 text-center">
						<input
							class="btn btn-primary"
							type="submit"
							name="su"
							:value="submitLabel"
						/>
					</div>
					<div class="col col-6 text-center">
						<button type="button" class="btn btn-warning" @click="setDummy">
							Dummy
						</button>
						<button type="button" class="btn btn-warning" @click="addToListTest">
							addToList
						</button>
					</div>
				</div>
			</form>
			<pre>
				{{item}}
			</pre>
		</div>
	</div>
</template>

<script src="./ContactAdd.js"></script>
<style lang="scss">
@import "../../assets/scss/main.scss";

#contactadd {
	.collapse .container-fluid {
		padding-left: 0;
		.col {
			padding-left: 0;
		}
	}
	.toggle-red {
		.toggle {
			width: 90px !important;
			color: white;
			font-weight: bold;
			&.toggle-off {
				padding-right: 10px;
			}
			&.toggle-on {
				padding-left: 10px;
			}
		}
		--toggle-bg-on: #{$color-success};
		--toggle-border-on: #{$color-success};
		--toggle-bg-off: #a00e0e;
		--toggle-border-off: #a00e0e;
	}
	
	.errortext {
		color: $color-error;
	}
	
	.handle {
		.error .input-errors {
			display:block;
		}
	}
}
</style>