// import Home from './views/Home.vue'
import PBN from './Layouts/PBNLayout.vue'
import PBNHosting from './components/PBN/Hosting.vue'
import PBNServer from './components/PBN/ServerList.vue'
import PBNHosts from './components/PBN/Hosts.vue'
import PBNDns from './components/PBN/DNS.vue'
import Hosting from './Layouts/HostingLayout.vue'
import HostingSubscriptions from './components/Hosting/Subscriptions.vue'
import HostingServerList from './components/Hosting/ServerList.vue'
import Domains from './Layouts/DomainsLayout.vue'
import Accounting from './Layouts/AccountingLayout.vue'
import DomainsDomains from './components/Domains/Domains.vue'
import DomainsTransfers from './components/Domains/DomainsTransfers.vue'
import DomainsContacts from './components/Domains/Contacts.vue'
import Clients from './components/Accounting/Clients.vue'
import Invoices from './components/Accounting/Invoices.vue'
import CustomArticles from './components/Accounting/CustomArticles.vue'
import PricesDomains from './components/Accounting/PricesDomains.vue'
import PricesHosting from './components/Accounting/PricesHosting.vue'
import Statistics from './Layouts/StatisticsLayout.vue'
import Bilance from './components/Statistics/Bilance.vue'

// import DomainsContacts from './components/Domains/Contacts.vue'
import Login from './views/Login.vue'
import NotFound from './views/NotFound.vue'
import VueJwtDecode from "vue-jwt-decode";
import store from '@/store'

import dayjs from 'dayjs'
import { createWebHistory, createRouter } from "vue-router";

const defaultTitle = 'Samedia Office';

const getLogin = (to, from, next) => {
	if (
		!localStorage.getItem("adminToken") ||
		localStorage.getItem("adminToken") == ""
	) {
		console.log("log me in")
		next("/login");
		return;
	}

	// console.log("log me not in " + localStorage.getItem("adminToken"))
	const decodedToken = VueJwtDecode.decode(localStorage.getItem("adminToken"));
	if (!decodedToken || !decodedToken.exp || !decodedToken.context) {
		next("/login");
		return;
	}
	let jetzt = Math.floor(Date.now() / 1000);
	if (jetzt > parseInt(decodedToken.exp)) {
		next("/login");
		return;
	}
	localStorage.setItem("userData", JSON.stringify(decodedToken.context.user));
	const exp = parseInt(decodedToken.exp);
	let now = dayjs().unix()
	if (exp < now) {
		store.dispatch('AUTH_REFRESH', {})
			.then((res) => {
				console.log(res)
			})
			.catch(err => {
				console.error('ERRRR ', err)
			})
	}
	next();
	return;
};


// this.$router.isReady().then(() => {
// 	this.getActiveTab();
// });


/** @type {import('vue-router').RouterOptions['routes']} */
const routes = [
	// { path: '/', component: Home, meta: { title: 'Home' } },
	{ 
		path: '/', redirect: '/pbn/hosting/list', 
		meta: { title: 'Samedia Office' }
	},

	{
		path: '/pbn',
		meta: { title: 'PBN', navtitle: 'PBN' },
		name: "PBN",
		component: PBN,
		beforeEnter: getLogin,
		redirect: '/pbn/hosting/list',
		// example of route level code-splitting
		// this generates a separate chunk (About.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		// component: () => import('./views/About.vue')
		children: [
			{
				meta: { title: 'PBN Hosting List' },
				path: 'hosting/list',
				name: "PBN Hosting List",
				component: PBNHosting,
				// beforeEnter: getLogin,
			},
			{
				path: 'hosting/todos',
				name: "PBN Hosting Todos",
				component: PBNHosting,
				meta: { title: 'PBN Hosting Todos' },
				// beforeEnter: getLogin,
			},
			{
				path: 'hosting/single/:id',
				name: "PBN Hosting Details",
				meta: { title: 'PBN Hosting Details' },
				component: PBNHosting,
				// beforeEnter: getLogin,
			},
			{
				path: 'hosting/add',
				name: "PBN Hosting Add",
				component: PBNHosting,
				meta: { title: 'Add PBN Hosting' },
				// beforeEnter: getLogin,
			},
			{
				path: 'server/list',
				name: "PBN Server List",
				parentid: "pbn",
				component: PBNServer,
				meta: { title: 'PBN Server List' },
				// beforeEnter: getLogin,
			},
			{
				path: 'server/todos',
				name: "PBN Server Todos",
				component: PBNServer,
				meta: { title: 'PBN Server Todos' },
				// beforeEnter: getLogin,
			},
			{
				path: 'server/single/:id',
				name: "PBN Server SIngle",
				component: PBNServer,
				meta: { title: 'PBN Server Details' },
				// beforeEnter: getLogin,
			},
			{
				path: 'server/add',
				name: "PBN Server Add",
				component: PBNServer,
				meta: { title: 'Add PBN Server' },

				// beforeEnter: getLogin,
			},
			{
				path: 'hosts/list',
				name: "PBN Host List",
				component: PBNHosts,
				meta: { title: 'PBN Host List' },
				// beforeEnter: getLogin,
			},
			{
				path: 'hosts/single/:id',
				name: "PBN Host Single",
				component: PBNHosts,
				meta: { title: 'PBN Host Details' },
				// beforeEnter: getLogin,
			},
			{
				path: 'hosts/add',
				name: "PBN Server Add",
				component: PBNHosts,
				meta: { title: 'Add PBN Host' },

				// beforeEnter: getLogin,
			},
			{
				path: 'dns/list',
				name: "PBN DNS List",
				component: PBNDns,
				meta: { title: 'PBN DNS List' },
				// beforeEnter: getLogin,
			},
			{
				path: 'dns/single/:id',
				name: "PBN DNS Single",
				component: PBNDns,
				meta: { title: 'PBN DNS Details' },
				// beforeEnter: getLogin,
			},
			{
				path: 'dns/add',
				name: "PBN DNS Add",
				component: PBNDns,
				meta: { title: 'Add PBN DNS' },
				// beforeEnter: getLogin,
			}
					]
	},
	{
		path: '/hosting',
		meta: { title: 'Hosting', navtitle: 'Hosting' },
		name: "Hosting",
		component: Hosting,
		beforeEnter: getLogin,
		redirect: '/hosting/subscriptions/list',
		// example of route level code-splitting
		// this generates a separate chunk (About.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		// component: () => import('./views/About.vue')
		children: [
			{
				path: 'subscriptions/list',
				name: "Hosting Subscriptions List",
				component: HostingSubscriptions,
				meta: { title: 'Hosting Subscriptions List' },
				// beforeEnter: getLogin,
			},
			{
				path: 'subscriptions/single/:id',
				name: "Hosting Hosting SIngle",
				component: HostingSubscriptions,
				meta: { title: 'Hosting Subscriptions Details' },
				// beforeEnter: getLogin,
			},
			{
				path: 'subscriptions/add',
				name: "Hosting Hosting Add",
				component: HostingSubscriptions,
				meta: { title: 'Add Hosting Subscriptions' },
				// beforeEnter: getLogin,
			},
			{
				path: 'server/list',
				name: "Hosting Server List",
				component: HostingServerList,
				meta: { title: 'Hosting Server List' },
				// beforeEnter: getLogin,
			},
			{
				path: 'server/single/:id',
				name: "Hosting Server SIngle",
				component: HostingServerList,
				meta: { title: 'Hosting Server Details' },
				// beforeEnter: getLogin,
			},
			{
				path: 'server/add',
				name: "Hosting Server Add",
				component: HostingServerList,
				meta: { title: 'Add Hosting Server' },
				// beforeEnter: getLogin,
			},
		],
	},
	{
		path: '/domains',
		meta: { title: 'Domains', navtitle: 'Domains' },
		name: "Domains",
		component: Domains,
		beforeEnter: getLogin,
		redirect: '/domains/domains/list',
		// example of route level code-splitting
		// this generates a separate chunk (About.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		// component: () => import('./views/About.vue')
		children: [
			{
				path: 'domains/list',
				name: "Domains List",
				component: DomainsDomains,
				meta: { title: 'Domains List' },
				// beforeEnter: getLogin,
			},
			{
				path: 'domains/single/:id',
				name: "Domains Single",
				component: DomainsDomains,
				meta: { title: 'Domain Details' },
				// beforeEnter: getLogin,
			},
			{
				path: 'domains/add',
				name: "Domain Add",
				component: DomainsDomains,
				meta: { title: 'Add Domain' },
				// beforeEnter: getLogin,
			},
			{
				path: 'domains/transfers',
				name: "Domain Transfers",
				component: DomainsTransfers,
				meta: { title: 'Domain Transfers' },
				// beforeEnter: getLogin,
			},
			{
				path: 'contacts/list',
				name: "Domains Contacts List",
				component: DomainsContacts,
				meta: { title: 'Domains Contacts List' },
				// beforeEnter: getLogin,
			},
			{
				path: 'contacts/single/:id',
				name: "Domains Contacts Single",
				component: DomainsContacts,
				meta: { title: 'Domains Contacts Details' },
				// beforeEnter: getLogin,
			},
			{
				path: 'contacts/add',
				name: "Domains Contact Add",
				component: DomainsContacts,
				meta: { title: 'Add Domains Contact' },
				// beforeEnter: getLogin,
			},
		],
	},
	{
		path: '/accounting',
		meta: { title: 'Accounting', navtitle: 'Accounting' },
		name: "Accounting",
		component: Accounting,
		beforeEnter: getLogin,
		redirect: '/accounting/invoices/list',
		// example of route level code-splitting
		// this generates a separate chunk (About.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		// component: () => import('./views/About.vue')
		children: [
			{
				path: 'clients/list',
				name: "Client List",
				component: Clients,
				meta: { title: 'Clients' },
				// beforeEnter: getLogin,
			},
			{
				path: 'clients/single/:id',
				name: "Client Single",
				component: Clients,
				meta: { title: 'Client Details' },
				// beforeEnter: getLogin,
			},
			{
				path: 'clients/add',
				name: "Clients Add",
				component: Clients,
				meta: { title: 'Add Client' },
				// beforeEnter: getLogin,
			},
			{
				path: 'invoices/list',
				name: "Invoices List",
				component: Invoices,
				meta: { title: 'Invoices' },
				// beforeEnter: getLogin,
			},
			{
				path: 'invoices/single/:id',
				name: "Invoices Single",
				component: Invoices,
				meta: { title: 'Invoices Details' },
				// beforeEnter: getLogin,
			},
			{
				path: 'invoices/add',
				name: "invoices Add",
				component: Invoices,
				meta: { title: 'Add Invoice' },
				// beforeEnter: getLogin,
			},
			{
				path: 'clients/invoices/due',
				name: "Due Invoices",
				component: Invoices,
				meta: { title: 'Due Invoices' },
				// beforeEnter: getLogin,
			},
			{
				path: 'prices/domains/list',
				name: "Domain Prices",
				component: PricesDomains,
				meta: { title: 'Domain Prices' },
				// beforeEnter: getLogin,
			},
			{
				path: 'prices/hosting/list',
				name: "Hosting Prices",
				component: PricesHosting,
				meta: { title: 'Hosting Prices' },
				// beforeEnter: getLogin,
			},
			{
				path: 'articles/custom/list',
				name: "Custom Articles",
				component: CustomArticles,
				meta: { title: 'Custom Articles' },
				// beforeEnter: getLogin,
			},
			{
				path: 'articles/custom/add',
				name: "Add Custom Article",
				component: CustomArticles,
				meta: { title: 'Add Custom Article' },
				// beforeEnter: getLogin,
			},
		],
	},
	{
		path: '/statistics',
		meta: { title: 'Statistics', navtitle: 'Statistics' },
		name: "Statistics",
		component: Statistics,
		beforeEnter: getLogin,
		redirect: '/statistics/bilance',
		// example of route level code-splitting
		// this generates a separate chunk (About.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		// component: () => import('./views/About.vue')
		children: [
			{
				path: 'bilance',
				name: "Bilance",
				component: Bilance,
				meta: { title: 'Bilance' },
				// beforeEnter: getLogin,
			},
		]
	},
	{
		path: "/login",
		name: "Login",
		component: Login
	},
	{ path: '/:path(.*)', component: NotFound },
];

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
});

router.beforeEach((to, from, next) => {
	document.title = to.meta.title || defaultTitle;
	store.dispatch('MISC_SETLASTPAGE', from.path)
	.then((res) => {
		if(res == 'hh') {
			console.log(res)
		}
	})
	.catch(err => {
		console.error('ERRRR ', err)
	});
	next();
});

router.afterEach((to, from) => {
	// if(from === "x") console.log( from);
	store.dispatch('MISC_SETACTIVENAV', to.meta.navtitle)
	.then((res) => {
		if(res == 'hh') {
			console.log(res)
		}
	})
	.catch(err => {
		console.error('ERRRR ', err)
	});
});

export default router;