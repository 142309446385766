<template>
	<div class="container-fluid domains">
		<div class="row">
			<h2
				class="
					text-3xl
					font-extrabold
					leading-9
					tracking-tight
					text-gray-900
					sm:text-4xl sm:leading-10
				"
			>
				Domains
			</h2>
			<ul class="nav nav-tabs">
				<li :class="{ 'nav-item': true, active: active == 'list' }">
					<router-link
						:class="'nav-link'"
						aria-current="List"
						to="/domains/domains/list"
						@click="showTab('list')"
						>List</router-link
					>
				</li>
				<li :class="{ 'nav-item': true, active: active == 'details' }">
					<router-link
						:class="'nav-link '"
						aria-current="Details"
						:to="detailurl"
						@click="showTab('details')"
						>Details</router-link
					>
				</li>
				<li :class="{ 'nav-item': true, active: active == 'add' }">
					<router-link
						:class="'nav-link '"
						aria-current="Details"
						to="/domains/domains/add"
						@click="prepareAdd"
						>Add</router-link
					>
				</li>
			</ul>
		</div>
		<div class="row content" id="list" v-show="active == 'details'">
			<div class="col">
				<DomainsDetails add="0" @updateList="updateListHandler"  />
			</div>
		</div>
		<div class="row content" id="list" v-show="active == 'add'">
			<div class="col">
				<DomainAdd add="1" ref="domainsadd"  @addToList="addListHandler" />
			</div>
		</div>

		<div class="row content" id="list" v-show="active == 'list'">
			<div class="col">
				<div class="row content justify-content-end">
					<div class="col-2 text-center">
						<button type="button" class="btn btn-outline-info" @click="toggleCancelled">
							<span v-if="showCancelled">Hide</span><span v-else>Show</span> Cancelled
						</button>
					</div>
				</div>
				<div class="row content goodtable">
					<div class="col">
						<DomainsList ref="domainsList" :showCancelled="showCancelled"/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script src="./Domains.js"></script>
