// import InvoicesDetails from "./ClientDetails.vue";
import { VueGoodTable } from 'vue-good-table-next';
import { ref, onBeforeMount } from "vue";
import providers from "../../js/providers";

import { createToaster } from "@meforma/vue-toaster";
import PricesDomainsModal from "./PricesDomainsModal.vue";
import ArticlesSource from '@/js/backend/articles';

export default {
	components: {
		VueGoodTable,
		PricesDomainsModal
		// ArticleModal
	},

	setup() {
		const toaster = createToaster({ position: 'top' });
		const pricesdomainsmodalwindow = ref();
		const providerByNum = ref({});
		const rows = ref([]);
		const columns = ref([
			{ 
				label: 'TLD', 
				field: 'DP_TLD', 
				filterOptions: {
					enabled: true, 
				}
			},
			{
				label: "Registry",
				field: "DP_REGISTRY",
				sortable: true,
				filterOptions: {
					styleClass: "class1", // class to be added to the parent th element
					enabled: true, 
					filterDropdownItems: providers
				},
			},
			{
				label: 'Base Price',
				field: 'DP_T1_REG',
			},
			{
				label: 'Cost',
				field: 'DP_COST_REG',
			},
			{ label: 'Period', field: 'DP_YEARS', sortable: false	},
			{ label: 'Details', field: 'Details', sortable: false },
			{ label: 'Delete', field: 'Delete', sortable: false },
		]);
		
		
		const totalRecords = ref(0);
		const serverParams = ref ({
			// a map of column filters example: {name: 'john', age: '20'}
			columnFilters: {},
			sort: [
				{
					field: "DP_TLD", // example: 'name'
					type: "asc", // 'asc' or 'desc'
				},
			],

			page: 1, // what page I want to show
			perPage: 20, // how many items I'm showing per page
		});

		onBeforeMount(() => {
			for(let i = 0; i < providers.length;i++) {
				providerByNum.value[providers[i].value] = providers[i].text
			}
			loadItems();
		});
		
		function showDetails(data) {
			pricesdomainsmodalwindow.value.showDetails(data)
		}
		
		function showNew() {
			pricesdomainsmodalwindow.value.showNew()
		}
		
		
		function updateParams(newProps) {
			serverParams.value = Object.assign({}, serverParams.value, newProps);
			console.log("Filter changed", serverParams.value);
		}

		function onPageChange(params) {
			updateParams({ page: params.currentPage });
			loadItems();
		}

		function onPerPageChange(params) {
			updateParams({ perPage: params.currentPerPage });
			loadItems();
		}

		function onSortChange(params) {
			updateParams({
				sort: [
					{
						type: params[0].type,
						field: params[0].field,
					},
				],
				page: 1,
			});
			loadItems();
		}

		function onColumnFilter(params) {
			updateParams(params);
			loadItems();
		}

		// load items is what brings back the rows from server
		function loadItems() {
			rows.value = [];
			ArticlesSource.getDomainPriceList(serverParams.value).then((response) => {
				rows.value = response.data.rows;
				totalRecords.value = response.data.totalRecords;
				console.log(totalRecords.value + " records");
			});
		}

		function updateList(data) {
			console.log("arrived in the list with ", data)
			for (const obj of rows.value) {
				if (obj.DP_TLD === data.DP_TLD) {
					console.log("MATCH ", obj.DP_TLD)
					obj.DP_COST_REG = data.DP_COST_REG;
					obj.DP_T1_REG = data.DP_T1_REG;
					break;
				}
			}
			return true;
		}
		
		function removeDomain(tld) {
			if(confirm("SURE??")) {
				ArticlesSource.removeDomain(tld).then((response) => {
					if (response.data.Error) {
						toaster.error(
							"Could not delete Domain: \n<br/>" + response.data.Error
						);
						console.error(response.data.Error);
					} else {
						toaster.success("Domain deleted");
						removeFromList(tld);
					}
				})
				.catch((error) => {
					toaster.error("Could not delete Domain ", error);
				});
			}
		}
		

		function addToList() {
			onSortChange([{"type": "asc", "field": "DP_TLD"}]);
			loadItems();
			
			// rows.value.push(data)
		}
		
		function removeFromList(tld) {
			rows.value.splice(rows.value.findIndex(v => v.DP_TLD === tld), 1);
			console.log("removed TLD ", tld)
		}
		
		return {
			rows,
			totalRecords,
			columns,
			pricesdomainsmodalwindow,
			providers,
			providerByNum,
			updateList,
			addToList,
			removeDomain,
			removeFromList,
			showDetails,
			showNew,
			onColumnFilter,
			onSortChange,
			onPerPageChange,
			onPageChange,
		}
		
	}
}

