<template>
	<div class="col">
		<vue-good-table
			:columns="columns"
			:rows="rows"
			:pagination-options="{
				enabled: true,
				mode: 'records',
				perPage: 20,
				position: 'both',
				perPageDropdown: [3, 7, 9],
				dropdownAllowAll: false,
				setCurrentPage: 1,
				nextLabel: 'next',
				prevLabel: 'prev',
				rowsPerPageLabel: 'Rows per page',
				ofLabel: 'of',
				pageLabel: 'page', // for 'pages' mode
				allLabel: 'All',
			}"
			styleClass="vgt-table striped"
		>
			>
			<template #table-row="props">
				<span v-if="props.column.field == 'Action'">
					<router-link class="btn btn-primary btn-sm" :to='"/pbn/hosts/single/" + props.row.H_ID'>
						Details
					</router-link>
					<!-- <button class="btn btn-primary btn-sm" @click="showDetails(props.row)">
						Details
					</button> -->
				</span>
				<span v-else>
					{{ props.formattedRow[props.column.field] }}
				</span>
			</template>
		</vue-good-table>
	</div>
</template>

<script>
import { VueGoodTable } from "vue-good-table-next";
import { ref, onBeforeMount } from "vue";
import PBNSource from "@/js/backend/pbn";

export default {
	components: {
		VueGoodTable,
	},

	setup() {

		const rows = ref([]);
		const columns = ref([
				{ label: 'ID', field: 'H_ID', type: 'number' },
				{
					label: 'Host',
					field: 'H_NAME', 
					filterOptions: {
						styleClass: 'class1', // class to be added to the parent th element
						enabled: true, // enable filter for this column
					}
				},
				{
					label: 'Servers',
					field: 'Servers',
					type: 'number',
				},
				{ label: 'Action', field: 'Action' }
		]);
		const totalRecords = ref(0);
		const serverParams = ref ({
			// a map of column filters example: {name: 'john', age: '20'}
			columnFilters: {},
			sort: [
				{
					field: "HO_DOMAIN", // example: 'name'
					type: "asc", // 'asc' or 'desc'
				},
			],

			page: 1, // what page I want to show
			perPage: 20, // how many items I'm showing per page
		});
		
		onBeforeMount(() => {
			loadItems();
		});


		function updateParams(newProps) {
			serverParams.value = Object.assign({}, serverParams.value, newProps);
			console.log("Filter changed", serverParams.value);
		}

		function onPageChange(params) {
			updateParams({ page: params.currentPage });
			loadItems();
		}

		function onPerPageChange(params) {
			updateParams({ perPage: params.currentPerPage });
			loadItems();
		}

		function onSortChange(params) {
			updateParams({
				sort: [
					{
						type: params[0].type,
						field: params[0].field,
					},
				],
				page: 1,
			});
			loadItems();
		}

		function onColumnFilter(params) {
			updateParams(params);
			loadItems();
		}

		// load items is what brings back the rows from server
		function loadItems() {
			PBNSource.getHostsList(serverParams.value).then((response) => {
				rows.value = response.data;
				totalRecords.value = response.data.totalRecords;
				console.log(totalRecords.value + " records");
			});
		}

		function statusClassFunc(row) {
			if (row.HO_STATUS == "1") {
				return "status status__success";
			} 
			else if (row.HO_STATUS == "2") {
				return "status status__progress";
			}
			return "status status__error";
		}

		function updateList(data) {
			console.log("arrived in the list with ", data)
			for (const obj of rows.value) {
				if (obj.H_ID === data.H_ID) {
					console.log("MATCH ", obj.H_ID)
					obj.H_NAME = data.H_NAME
					break;
				}
			}
			return true;
		}
		
		return {
			rows,
			totalRecords,
			columns,
			updateList,
			statusClassFunc,
			onColumnFilter,
			onSortChange,
			onPerPageChange,
			onPageChange
		}
		
	}
}

</script>