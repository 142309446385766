<template>
	<div class="container-fluid articles">
		<div class="row">
			<div class="col col-8">
				<h1
					class="text-center text-3xl font-extrabold leading-9 tracking-tight text-gray-900 sm:text-4xl sm:leading-10"
				>
					Hosting Prices
				</h1>
			</div>
			<div class="col col-4 text-end pt-2">
				<button class="btn btn-warning"
					@click="showNew"
					data-bs-toggle="modal"
					data-bs-target="#PricesHostingModal">Add TLD</button>
			</div>
		</div>
		<div class="row content goodtable" id="list">
			<div class="col">
				<vue-good-table
					:totalRows="totalRecords"
					:columns="columns"
					:rows="rows"
					:pagination-options="{
						enabled: true,
						mode: 'records',
						perPage: 20,
						position: 'both',
						perPageDropdown: [10, 20, 50],
						dropdownAllowAll: false,
						setCurrentPage: 1,
						nextLabel: 'next',
						prevLabel: 'prev',
						rowsPerPageLabel: 'Rows per page',
						ofLabel: 'of',
						pageLabel: 'page', // for 'pages' mode
						allLabel: 'All',
					}"
					v-on:page-change="onPageChange"
					v-on:sort-change="onSortChange"
					v-on:column-filter="onColumnFilter"
					v-on:per-page-change="onPerPageChange"
					styleClass="vgt-table striped"
				>
					>
					<template #table-row="props">
						<span v-if="props.column.field == 'HP_T1'">
							<i18n-n tag="p" :value="props.row.HP_T1" format="currency" locale="de-DE" scope="global"></i18n-n>
						</span>

						<span v-else-if="props.column.field == 'HP_COST'">
							<i18n-n tag="p" :value="props.row.HP_COST" format="currency" locale="de-DE" scope="global"></i18n-n>
						</span>

						<span v-else-if="props.column.field == 'Details'">
							<BIconInfoSquareFill 
								class="bootbutton booticons medium primary" 
								@click="showDetails(props.row.HP_SHORT)"
								data-bs-toggle="modal"
								data-bs-target="#PricesHostingModal"/>
						</span>
						
						<span v-else-if="props.column.field == 'Delete'">
							<BIconTrashFill class="bootbutton booticons medium alert" @click="removeHosting(props.row.HP_SHORT)" />
						</span>

						<span v-else>
							{{ props.formattedRow[props.column.field] }}
						</span>
					</template>
				</vue-good-table>
			</div>
		</div>
		<div class="modal fade" id="PricesHostingModal" tabindex="1" aria-labelledby="PricesHostingModal" aria-hidden="true">
			<PricesHostingModal
				@addToList="addToList"
				@updateList="updateList"
				ref="priceshostingmodalwindow"
			/>
		</div>
	</div>
</template>

<script src="./PricesHosting.js"></script>
