<template>
	<div class="client" id="invoicedetails">
		<h2 v-if="invoice.IN_ID > 0">Invoice #{{ invoice.IN_ID }}</h2>
		<h2 v-else>Add Invoice</h2>
		<div class="container-fluid" v-if="loading">
			<div class="loader">
				<div
					class="spinner-grow text-success"
					style="width: 6rem; height: 6rem"
					role="status"
				></div>
				<p class="text-primary">Loading...</p>
			</div>
		</div>
		<div class="container-fluid details" v-else>
			<div class="row">
				<div class="col col-5">
					<div class="fw-bold">{{invoice.CLIENT}}</div>
					
					<div class="">{{invoice.CL_ADDRESS}}
						<br/>
						{{invoice.CL_ZIP}} {{invoice.CL_CITY}}
						<br/>
						{{invoice.CL_COUNTRY}}
						<br><b>Email to: </b> {{invoice.CL_EMAIL}}
					</div>
				</div>
				<div class="col col-4">
					<span class="fw-bold">Due: </span> {{ invoice.IN_DUE }}
					<br/>
					<span class="fw-bold">Amount: </span> {{ invoice.IN_AMOUNT }}
					<br/>
					<span class="fw-bold">VAT: </span> {{ invoice.IN_VAT }}
					<br/>
					<span class="fw-bold">Total: </span> {{ invoice.IN_TOTAL }}
				</div>
				<div class="col col-3">
					<label for="innumber" class="visually-hidden">Invoice No.</label>
					<input type="text" class="form-control" id="innumber" v-model="invoice.IN_NUMBER" placeholder="Invoice no.">
					<input
						class="btn btn-success btn-sm"
						type="button"
						name="su"
						value="Set Number"
						@click="setInvoiceNumber"
					/>

				</div>
			</div>
			
			<div class="row">
				<div class="col-6">
					<label for="customheader" class="visually-hidden">Header</label>
					<input type="text" class="form-control" id="customheader" v-model="invoice.IN_CUSTOM_HEADER" placeholder="Custom Header">
				</div>
				<div class="col-2">
					<label for="overdue" class="visually-hidden">Overdue</label>
					<select class="form-select" aria-label="Choose" v-model="invoice.IN_OVERDUE" v-on:change="setOverdue">
						<option value="0">No</option>
						<option value="1">Yes</option>
					</select>
				</div>
				<div class="col-1">
					<br/>
					<button type="button" class="btn btn-primary mb-3" @click="setCustomHeader">Set</button>
				</div>	
				<div class="col col-3 pt-4">
					<button 
						id="sender"
						type="button" 
						class="btn btn-primary btn-xl" 
						style="padding-left:30px;padding-right: 30px;"
						@click="sendInvoice"
					>send
						<BIconEnvelopeOpen class="booticons medium "/>
					</button>
				</div>
			</div>
			
			<div class="row" v-if="invoice.IN_PAYMENT_CODE == '' || invoice.hasvalidcode == 0">
				<div class="col-2">
					<span class="text-danger fw-bolder">
						No Valid Code
					</span>
				</div>
				<div class="col-10">
					<button 
						id="sender"
						type="button" 
						class="btn btn-warning btn-xl" 
						style="padding-left:30px;padding-right: 30px;"
						@click="getPaymentCode"
					>Get Code
						<BIconCodeSlash class="booticons medium "/>
					</button>
				</div>
			</div>
			<div class="row" v-else>
				<div class="col-2">
					<span class="text-success fw-bolder">
						Valid Code:
					</span>
				</div>
				<div class="col-10 fs-6">
					{{ invoice.IN_PAYMENT_CODE }}
				</div>
			</div>
			
			
			<div class="row">
				<div class="col col-12 text-center">
					<h3>Items</h3>
				</div>
			</div>
			<div v-for="(item, idx) in invoice.items" v-bind:key="idx">
				<InvoiceItem :item="item" :idx="idx" @getDetails="getDetailsAndUpdateList" />
			</div>

			<div class="row">
				<div class="col col-6 text-center">
					<input
						class="btn btn-success"
						type="button"
						name="su"
						value="Add Item"
						@click="showAddItem"
					/>
				</div>
				<div class="col col-6 text-center">
					<input
						class="btn btn-primary"
						type="submit"
						name="su"
						:value="submitLabel"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script src="./InvoiceDetails.js"></script>
<style lang="scss">
@import "../../assets/scss/main.scss";

#clientdetails {
	.collapse .container-fluid {
		padding-left: 0;
		.col {
			padding-left: 0;
		}
	}
	.toggle-red {
		.toggle {
			width: 90px !important;
			color: white;
			font-weight: bold;
			&.toggle-off {
				padding-right: 20px;
			}
			&.toggle-on {
				padding-left: 20px;
			}
		}
		--toggle-bg-off: #a00e0e;
		--toggle-border-off: #a00e0e;
		--toggle-bg-on: #{$color-success};
		--toggle-border-on: #{$color-success};
	}
}
</style>