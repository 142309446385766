<template>
	<div class="client" id="invoiceadd">
		<h2>Add Invoice</h2>
		<form @submit.prevent="addInvoice">
			<div class="container-fluid" v-if="loading">
				<div class="loader">
					<div
						class="spinner-grow text-success"
						style="width: 6rem; height: 6rem"
						role="status"
					></div>
					<p class="text-primary">Loading...</p>
				</div>
			</div>
			<div class="container-fluid details" v-else>
				<div class="row">
					<div class="col col-6">
						Client: <br />
						<v-select label="NAME" :options="clients" v-model="invoice.IN_CLID"></v-select>
					</div>
					<div class="col col-6">
						<label for="site_first_payment" class="control-label">Due</label>
						<br />
						<DatePicker
							:attributes="datePickerAttributes"
							:masks="{ title: 'MMM YYYY', L: 'YYYY-MM-DD' }"
							:model-config="dateConfig"
							v-model="invoice.IN_DUE"
						>
							<template v-slot="{ inputValue, inputEvents }">
								<input class="form-control" :value="inputValue" v-on="inputEvents" />
							</template>
						</DatePicker>
					</div>
				</div>
				
				<div class="row">
					<div class="col-7">
						<label for="customheader" class="visually-hidden">Header</label>
						<input type="text" class="form-control" id="customheader" v-model="invoice.IN_CUSTOM_HEADER" placeholder="Custom Header">
					</div>
					<div class="col-auto">
						<label for="overdue" class="visually-hidden">Overdue</label>
						<select class="form-select" aria-label="Choose" v-model="invoice.IN_OVERDUE" v-on:change="setOverdue">
							<option value="0" selected>No</option>
							<option value="1">Yes</option>
						</select>
					</div>
					<div class="col-1">
						<br/>
						<button type="button" class="btn btn-primary mb-3" @click="setCustomHeader">Set</button>
					</div>	
				</div>
				<div class="row">
					<div class="col col-12 text-center">
						<h3>Items</h3>
					</div>
				</div>
				<div v-for="(item, idx) in invoice.items" v-bind:key="idx">
					<InvoiceItem :item="item" :idx="idx" @getDetails="getDetailsAndUpdateList" />
				</div>

				<div class="row">
					<div class="col col-6 text-center">
						<input
							class="btn btn-success"
							type="button"
							name="su"
							value="Add Item"
							@click="showAddItem"
						/>
					</div>
					<div class="col col-6 text-center">
						<input
							class="btn btn-primary"
							type="submit"
							name="su"
							:value="submitLabel"
						/>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script src="./InvoiceAdd.js"></script>
<style lang="scss">
@import "../../assets/scss/main.scss";

#invoicedetails {
	.collapse .container-fluid {
		padding-left: 0;
		.col {
			padding-left: 0;
		}
	}
	.toggle-red {
		.toggle {
			width: 90px !important;
			color: white;
			font-weight: bold;
			&.toggle-off {
				padding-right: 20px;
			}
			&.toggle-on {
				padding-left: 20px;
			}
		}
		--toggle-bg-off: #a00e0e;
		--toggle-border-off: #a00e0e;
		--toggle-bg-on: #{$color-success};
		--toggle-border-on: #{$color-success};
	}
}
</style>

<!--
						<div>
							<label for="P_NEXT_PAYMENT" class="control-label"
								>Next Pay:</label
							>
						</div>
						<Calendar
							id="P_NEXT_PAYMENT"
							v-model="item.P_NEXT_PAYMENT"
							dateFormat="yy-mm-dd"
						/>

						Host: <br /><Dropdown
							v-model="item.M_HID"
							:options="hosts"
							optionLabel="H_NAME"
							optionValue="H_ID"
							placeholder="Select one"
						/>
						
												<label for="M_SYS" class="control-label">System:</label>
						<select
							class="form-control"
							name="M_SYS"
							id="M_SYS"
							v-model="item.M_SYS"
						>
							<option value="">---</option>
							<option value="Vesta">Vesta</option>
							<option value="Hestia">Hestia</option>
							<option value="Webmin">Webmin</option>
						</select>

						
-->