<template>
	<div
		id="transfermodal"
		class="
			modal-dialog-scrollable modal-xl modal-fullscreen-lg-down
			transfermodal
			modal-dialog
		"
	>
		<div class="modal-content">
			<div class="container-fluid text-center">
				<div class="row">
					<div class="col col-6 text-right">
						<h2
							class="
								text-3xl
								font-extrabold
								leading-9
								tracking-tight
								text-gray-900
								sm:text-4xl sm:leading-10
							"
						>
							Incoming Transfer for client
						</h2>
					</div>
					<div class="col col-6 text-left">
						<v-select label="NAME" :options="clients" v-model="clientid"></v-select>
					</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="container-fluid text-center" v-if="loading">
					<div class="loader loader__sm">
						<div
							class="spinner-grow text-success"
							style="width: 2rem; height: 2rem"
							role="status"
						></div>
						<p class="text-primary">Loading...</p>
					</div>
				</div>
				<div class="container-fluid" v-else>
					<form @submit.prevent="addTransfer">
					<div class="row">
						<div class="col col-6">
							<div :class="{ error: v$.domain.$errors.length }">
								<label for="domain" class="control-label">Domain</label>
								<input
									type="text"
									class="form-control"
									id="domain"
									v-model="transferitem.domain"
								/>
								<div
									class="input-errors"
									v-for="error of v$.domain.$errors"
									:key="error.$uid"
								>
									<div class="error-msg">{{ error.$message }}</div>
								</div>
							</div>
						</div>
						<div class="col col-6">
							<div :class="{ error: v$.authcode.$errors.length }">
								<label for="authcode" class="control-label">Auth Code</label>
								<input
									type="text"
									class="form-control"
									id="authcode"
									v-model="transferitem.authcode"
								/>
								<div
									class="input-errors"
									v-for="error of v$.authcode.$errors"
									:key="error.$uid"
								>
									<div class="error-msg">{{ error.$message }}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col col-12 mt-3">
							<button type="submit" class="btn btn-primary">
								Save
							</button>
						</div>
					</div>
					<div class="row">
						<div class="col col-12">
							<label for="trlist" class="control-label">Transfer list</label><br />
							<textarea 
								class="form-control"
								v-model="transferlist"
								rows="8"
							>
							</textarea>
							<div class="footnote">
								One pair of domain/authcode per line, separated by a colon <b>:</b>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col col-12 mt-3">
							<button type="submit" class="btn btn-primary">
								Save
							</button>
						</div>
					</div>
					<div v-if="error" class="row error mt-3">
						<div class="col col-12 mt-3">
							<ul>
								<li v-for="(item, index) in errors" v-bind:key="index">{{item}}</li>
							</ul>
						</div>
					</div>
				</form>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
					Close
				</button>
			</div>
		</div>
	</div>
</template>
		
<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import DomainsSource from "@/js/backend/domains";
import { Modal } from "bootstrap";
import useVuelidate from '@vuelidate/core'
import { required, integer, helpers } from '@vuelidate/validators'
import { useStore } from "vuex"; //eslint-disable-line no-unused-vars
import { createToaster } from "@meforma/vue-toaster";
import { ref, onMounted,onBeforeMount, computed } from "vue";
export default {
	props: {},

	components: {
		vSelect
	},

	setup(props, { emit }) {
		name: "TransferModal";
		const store = useStore()

		const toaster = createToaster({ position: "top-left" });
		const theModal = ref();
		const transferitem = ref({"domain": "", "authcode": ""});
		const transferlist = ref();
		const clientid = ref(0);
		const message = ref("");
		const error = ref(false);
		const errors = ref([]);
		const loading = ref(false);
		const copytext = ref("Copy");
		const items = ref([]);

		const isDomainName = (value) => /^[^\.]+\.[a-z0-9]+(\.[a-z0-9]+)?/.test(value)
		const rules = {
			authcode: { required, $lazy: true },
			domain: { required, isDomainName: helpers.withMessage("Must be a domain name", isDomainName) },
		}
		const v$ = useVuelidate(rules, transferitem, { $lazy: true })
		const clients = computed(() => store.state.accounting.clients);

		onBeforeMount(() => {
			store.dispatch("CLIENTS", true);
		})

		onMounted(() => {
			theModal.value = new Modal(document.getElementById("transferModal"));
		});
		
		const resetData = () => {
			transferlist.value = "";
			transferitem.value = {};
		}

		const addTransfer = async () => {
			errors.value = []
			error.value = false;
			items.value = [];
			message.value = "";
			if(!clientid.value || clientid.value == 0) {
				toaster.error('Error: Pass a client!');
				return false;
			}
			if(transferlist.value != "") {
				transferlist.value = transferlist.value.trim();
				// console.log("RAW value: ", transferlist.value)
				const all = transferlist.value.split("\n");
				// console.log("ALL value: ", all)
				if(!all || all.length < 1) {
					toaster.error(`Error: Transfer list invalid`);
				}
				let errl = 0;
				for(let line = 0;line < all.length;line++) {
					errl = line + 1;
					// console.log("RAW LINE: ", line, ": ", all[line])
					let [domain, authcode] = all[line].split(":");
					if(!isDomainName(domain)) {
						errors.value.push(`#${errl}: Not a domain name`) 
						error.value = true
					}
					// console.log(domain, "=", authcode);
					items.value.push({"domain": domain, "authcode": authcode});
				}
				if(error.value == true) {
					return false;
				}
			}
			else {
				const result = await(v$.value.$validate());
				if(result == false) {
					return false;
				}
				items.value.push(transferitem.value);
			}
			if(!items.value || items.value.length < 1) {
				toaster.error("Nothing to set ");
				return false
			}
			DomainsSource.addTransferDomains(clientid.value.CL_ID, items.value)
			.then((res) => {
				if (!res || !res.data) {
					toaster.error("Cannot Set items: ");
				} 
				else if (res.data.Error) {
					toaster.error(`Error: ${res.data.Error}`);
				} 
				else {
					console.log(res.data)
					toaster.success("Domain transfers added");
					console.log(`Emit: ${res.data}`)
					emit('addTransfers', res.data);
				}
			})
			.catch((error) => {
				toaster.error(error, "Error setting Transfers");
			})
			.finally(() => {
				loading.value = false;
			});	

		};


		return {
			message,
			error,
			errors,
			transferlist,
			transferitem,
			loading,
			copytext,
			resetData,
			addTransfer,
			clients,
			clientid,
			v$
		};
	},
};
</script>

<style lang="scss">
@import "../../assets/scss/main.scss";
#transfermodal {
	.modal-content {
		font-size:1rem;
		padding: 5px;
	}
	
	.control-label {
		font-size:1rem;
	}
	
	.row.error {
		border:1px solid $color-error;
		li {
			font-size:16px;
			color: $color-error
		}
	}
	
	.footnote {
		font-size:12px;
	}
	
	.copy {
		border:1px solid $color-success;
		color: $color-success;
		border-radius:.2rem;
		padding:.3rem;
		&:hover {
			cursor: pointer;
			color: $color-success-hover;
			border:1px solid $color-success-hover;
		}
	}
	
}
</style>
