<template>
	<div class="container-fluid pbn">
		<div class="row">
			<h2
				class="
					text-3xl
					font-extrabold
					leading-9
					tracking-tight
					text-gray-900
					sm:text-4xl sm:leading-10
				"
			>
				Contacts
			</h2>
			<ul class="nav nav-tabs">
				<li :class="{ 'nav-item': true, active: active == 'list' }">
					<router-link
						:class="'nav-link'"
						aria-current="List"
						to="/domains/contacts/list"
						@click="showTab('list')"
						>List</router-link
					>
				</li>
				<li :class="{ 'nav-item': true, active: active == 'details' }">
					<router-link
						:class="'nav-link '"
						aria-current="Details"
						:to="detailurl"
						@click="showTab('details')"
						>Details</router-link
					>
				</li>
				<li :class="{ 'nav-item': true, active: active == 'add' }">
					<router-link
						:class="'nav-link '"
						aria-current="Details"
						to="/domains/contacts/add"
						@click="prepareAdd"
						>Add</router-link
					>
				</li>
			</ul>
		</div>
		<div class="row content" id="list" v-show="active == 'details'">
			<div class="col">
				<ContactsDetails add="0" @updateList="updateListHandler"  />
			</div>
		</div>
		<div class="row content" id="list" v-show="active == 'add'">
			<div class="col">
				<ContactAdd add="1" ref="contactsadd"  @addToList="addListHandler" />
			</div>
		</div>
		<div class="row content goodtable" v-show="active == 'list'">
			<div class="col">
				<ContactsList ref="contactsList" :showCancelled="showCancelled"/>
			</div>
		</div>

	</div>
</template>

<script src="./Contacts.js"></script>
