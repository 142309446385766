import { createToaster } from "@meforma/vue-toaster";
import Dropdown from "primevue/dropdown";
import Toggle from '@vueform/toggle'
// import Calendar from "primevue/calendar";
import { Calendar, DatePicker } from 'v-calendar';
import HostingSource from "@/js/backend/hosting";
import useVuelidate from '@vuelidate/core'
import * as dayjs from 'dayjs'
import { required, integer, helpers } from '@vuelidate/validators'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from "vuex"; //eslint-disable-line no-unused-vars
import { ref, reactive, computed, watch, onBeforeMount, onMounted, defineProps } from "vue";
import 'v-calendar/dist/style.css';

export default {

	props: {
		add: String,
	},

	components: {
		Dropdown,
		Toggle,
		DatePicker,
		Calendar
	},

	setup(props, { emit }) {

		const matrix = {
			S_ID: 0,
			S_UD: "",
			"S_NAME": "",
			"S_PROVIDER": "",
			"S_IP": "",
			"S_SSH_USER": "",
			"S_SSH_PWD": "",
			"S_PANEL_URL": "",
			"S_PANEL_USER": "",
			"S_PANEL_PWD": "",
			"S_SYS": null,
			"S_COUNTRY": null,
			"S_RAM": 32,
			"S_MAXDOMAINS": 40,
			"S_REMARKS": "",
			"S_OTHER": "",
			"S_TARGET": "",
			"S_USED": null,
			"S_AMOUNT": null,
			"S_CURRENCY": "EUR",
			"S_PAYMENT_INTERVAL": null,
			"S_EARNINGS": null,
			"S_EARNING_INTERVAL": null,
			"S_FIRST_PAYMENT": "",
			"S_NEXT_PAYMENT": "",
			"S_SUBSCRIPTION": 0
		};

		const dummy = {
			"S_UD": "2021-03-12 16:41:54",
			"S_NAME": "hetzner-fallera--2",
			"S_PROVIDER": "Hetzner",
			"S_IP": "168.119.99.344",
			"S_SSH_USER": "root",
			"S_SSH_PWD": "",
			"S_PANEL_URL": "",
			"S_PANEL_USER": "",
			"S_PANEL_PWD": "",
			"S_SYS": null,
			"S_COUNTRY": null,
			"S_RAM": 32,
			"S_MAXDOMAINS": 40,
			"S_REMARKS": "",
			"S_OTHER": "",
			"S_TARGET": "13bit",
			"S_USED": 5,
			"S_AMOUNT": 1892,
			"S_CURRENCY": "EUR",
			"S_PAYMENT_INTERVAL": 1,
			"S_EARNINGS": 6400,
			"S_EARNING_INTERVAL": 1,
			"S_FIRST_PAYMENT": "2020-09-21",
			"S_NEXT_PAYMENT": "2021-03-21",
			"S_SUBSCRIPTION": 1
		};
		
		const store = useStore()
		const toaster = createToaster({ position: 'top' });
		const router = useRouter()
		const route = useRoute()
		const loading = ref(false);
		const item = ref(matrix);
		const itemid = ref(null);
		let submitLabel = ref("Add");
		const datePickerAttributes = ref([{ 
			title: "MM YYYY" 
		}]);
		const clients = computed(() => store.state.accounting.clients);
		const articles = computed(() => store.state.hosting.hosting_articles);
		const mustLargerThanZero = (value) => value != '0';
		const isDate = (value) => /\d{4}-\d{2}-\d{2}/.test(value)

		const rules = {
			S_NAME: { required, $lazy: true },
			S_TARGET: { required, $lazy: true },
			S_PROVIDER: { required },
			S_AMOUNT: { required, mustLargerThanZero },
			S_EARNINGS: { required, mustLargerThanZero },
			S_CURRENCY: { required },
			S_PAYMENT_INTERVAL: { required, integer },
			S_EARNING_INTERVAL: { required, integer },
			
		}
		const v$ = useVuelidate(rules, item, { $lazy: true })

		watch(route, (first, to) => {
			if (to.params?.id && to.params?.id != itemid.value) {
				itemid.value = to.params.id
				getDetails()
				submitLabel.value = "Edit"
			}
			else {
				itemid.value = matrix;
				submitLabel.value = "Add"
			}
		})

		onBeforeMount(() => {
			store.dispatch("CLIENTS", false);
			store.dispatch("HOSTING_ARTICLES", false);
		})
		
		onMounted(() => {
			if (route.params?.id) {
				itemid.value = route.params.id
				submitLabel.value = "Edit"
				getDetails()
			}
			else {
				submitLabel.value = "Add"
			}
		})

		const resetData = () => {
			item.value = matrix;
			console.log("itemn has been reset to " , item.value)
			submitLabel.value = "Add"
		}

		const getDetails = () => {
			if (props.add == 1) {
				console.log("no data to fetch")
				return false;
			}
			loading.value = true;
			console.log("fetch data from hosting endpoint for id ", itemid.value)
			HostingSource.getServer(itemid.value)
				.then((response) => {
					item.value = response.data;
				})
				.catch((error) => {
					toaster.error("Could not fetch data ", error);
				})
				.finally(() => {
					loading.value = false;
				});
		}
		
		const editServer = async () => {
			item.value.S_FIRST_PAYMENT = dayjs(item.value.S_FIRST_PAYMENT).format(
				"YYYY-MM-DD"
			);
	
			console.log(item.value.S_FIRST_PAYMENT , "is it");
			const result = await(v$.value.$validate());
			if(result == false) {
				console.log("fasl;e")
				return false;
			}
			if (item.value.S_ID && item.value.S_ID > 0) {
				HostingSource.editServer(item.value)
					.then((response) => {
						if (response.data.Error) {
							let err = response.data.Error.replace(/\n/g, "\n<br/>");
							toaster.error(
								"Could not edit Server: \n<br/>" + err
							);
							console.log(typeof(response.data.Error));
						} else {
							emit('updateList', item.value);
							toaster.success("Server updated");
							// getDetails(item.value.H_ID);
						}
					})
					.catch((error) => {
						toaster.error("Could not edit Server ", error);
					});
			} else {
				HostingSource.addServer(item.value)
					.then((response) => {
						if (response.data.Error) {
							toaster.error(
								"Could not add Server: \n<br/>" + response.data.Error
							);
							console.error(response.data.Error);
						} else {
							toaster.success("Server added");
							if(response.data.S_ID) {
								item.value['S_ID'] = response.data.S_ID;
							}
							emit('addToList', item.value);
							router.push({
								path: "/hosting/server/list",
							});
						}
					})
					.catch((error) => {
						toaster.error("Could not add Server ", error);
					});
			}
		}
		
		const deleteServer = () => {
			if (confirm("Are you absolutely sure? The world will be a different place")) {
				HostingSource.deleteServer(item.value.S_ID)
					.then((response) => {
						console.error(response.data.Error);
						if (response.data.Error) {
							toaster.error(
								"Could not delete Server: \n<br/>" + response.data.Error
							);
							console.error(response.data.Error);
						} else {
							emit('removeFromList', item.value);
							toaster.success("Server deleted");
							item.value = matrix;
							router.push({ path: "/hosting/server/list" });
						}
					})
					.catch((error) => {
						toaster.error("Could not delete Server ", error);
					});
			}
		}
		
		const setDummy = () => {
			item.value = dummy;
		}
		
		return {
			item,
			submitLabel,
			loading,
			clients,
			articles,
			datePickerAttributes,
			editServer,
			deleteServer,
			setDummy,
			resetData,
			v$
		}
	}
};
