<template>
	<div class="container-fluid customarticles">
		<div class="row">
			<h1
				class="
					text-center text-3xl
					font-extrabold
					leading-9
					tracking-tight
					text-gray-900
					sm:text-4xl sm:leading-10
				"
			>
				Custom Articles
			</h1>
		</div>
		<div class="row content goodtable" id="list">
			<div class="col">
				<vue-good-table
					mode="remote"
					:totalRows="totalRecords"
					:columns="columns"
					:rows="rows"
					:row-style-class="rowStyleClassFn"
					:pagination-options="{
						enabled: true,
						mode: 'records',
						perPage: 20,
						position: 'both',
						perPageDropdown: [10, 20, 50],
						dropdownAllowAll: false,
						setCurrentPage: 1,
						nextLabel: 'next',
						prevLabel: 'prev',
						rowsPerPageLabel: 'Rows per page',
						ofLabel: 'of',
						pageLabel: 'page', // for 'pages' mode
						allLabel: 'All',
					}"
					v-on:page-change="onPageChange"
					v-on:sort-change="onSortChange"
					v-on:column-filter="onColumnFilter"
					v-on:per-page-change="onPerPageChange"
					styleClass="vgt-table striped"
				>
					>
					<template #table-row="props">
						<span v-if="props.column.field == 'CA_RECURRING'">
							<span v-if="props.row.CA_RECURRING == 1">
								<BIconArrowRepeat class="booticons medium success" @click="toggleRecurring(props.row.originalIndex)"/>
							</span>
							<span v-else>
								<BIconXCircleFill class="booticons medium alert" @click="toggleRecurring(props.row.originalIndex)"/>
							</span>
						</span>
						<span v-else-if="props.column.field == 'CA_STATUS'">
							<span v-if="props.row.CA_STATUS == 1">
								<BIconCheckCircleFill class="booticons medium success" @click="toggleStatus(props.row.originalIndex)"/>
							</span>
							<span v-else>
								<BIconDashCircleFill class="booticons medium alert" @click="toggleStatus(props.row.originalIndex)"/>
							</span>
						</span>
						
						<span v-else-if="props.column.field == 'CA_PRICE'">
							<i18n-n
								tag="p"
								:value="props.row.CA_PRICE"
								format="currency"
								locale="de-DE"
								scope="global"
							></i18n-n>

						</span>
						
						<span v-else-if="props.column.field == 'Details'">
							<BIconInfoSquareFill 
								class="bootbutton booticons medium primary" 
								@click="showDetails(props.row)"
								data-bs-toggle="modal"
								data-bs-target="#customArticleModal"/>
						</span>
						<span v-else-if="props.column.field == 'Delete'">
							<BIconTrashFill class="bootbutton booticons medium alert" @click="deleteInvoice(props.row.IN_ID)" />
						</span>
						<span v-else>
							{{ props.formattedRow[props.column.field] }}
						</span>
					</template>
				</vue-good-table>
			</div>
		</div>
		<div class="modal fade" id="customArticleModal" tabindex="1" aria-labelledby="detailsModal" aria-hidden="true">
			<CustomArticleModal 
				:masks="{ title: 'MMM YYYY', L: 'YYYY-MM-DD'  }"
				@setStatus="updateList"
				ref="customarticlemodalwindow" 
			/>
		</div>
	</div>
</template>

<script src="./CustomArticles.js"></script>
