import Dropdown from "primevue/dropdown";
import { createToaster } from "@meforma/vue-toaster";
import Toggle from '@vueform/toggle'
import HostingSource from "@/js/backend/hosting";
import { useStore } from "vuex"; //eslint-disable-line no-unused-vars
// import { defineRule } from 'vee-validate';
import useVuelidate from '@vuelidate/core'
import { required, integer, helpers } from '@vuelidate/validators'
import { useRouter, useRoute } from 'vue-router'
import { ref, reactive, computed, watch, onBeforeMount, onMounted, defineProps } from "vue";

export default {
	props: {
		add: String,
	},

	components: {
		Dropdown,
		Toggle
	},

	setup(props, { emit }) {
		name: "SubscriptionDetails";

		const store = useStore()
		const toaster = createToaster({ position: 'top' });
		const router = useRouter()
		const route = useRoute()

		const matrix = {
			H_ID: 0,
			H_CLIENTID: 0,
			H_SERVERID: 0,
			H_ARTICLE: 56,
			H_NAME: "",
			H_INC_DOMAINS: "",
			H_PRICE: "",
			H_DUE: "",
			H_INTERVAL: 12,
			H_STATUS: 1,
			H_CANCELLED: 0,
			ART_NAME: ""
		};

		const dummy = {
			H_CLIENTID: "365",
			H_SERVERID: "2",
			H_ARTICLE: "56",
			H_NAME: "blah-hotel.gr",
			H_INC_DOMAINS: "",
			H_PRICE: "78.00",
			H_UD: "2021-11-01 09:24:43",
			H_CD: "2006-11-30",
			H_EXPIRED: "2017-11-30",
			H_DUE: "2022-11-30",
			H_INTERVAL: "12",
			H_STATUS: "1",
			H_CANCELLED: "0",
			ART_NAME: "Web Hosting Entry"
		};

		const itemid = ref(null)
		const loading = ref(false);
		const item = ref(matrix);
		const installerstring = ref('');
		const statuslist = ref([
			{ 'STATUS_TEXT': 'Not ready', 'STATUS_ID': 0 },
			{ 'STATUS_TEXT': 'In progress', 'STATUS_ID': 2 },
			{ 'STATUS_TEXT': 'Ready', 'STATUS_ID': 1 },
		]);

		const mustLargerThanZero = (value) => value != '0';
		const isDate = (value) => /\d{4}-\d{2}-\d{2}/.test(value)
		let submitLabel = ref("Add");

		const rules = {
			H_NAME: { required, $lazy: true },
			H_PRICE: { required },
			H_DUE: { required, isDate: helpers.withMessage("Must be a valid date", isDate) },
			H_CLIENTID: { required, mustLargerThanZero },
			H_SERVERID: { required, mustLargerThanZero: helpers.withMessage("Must be > 0", mustLargerThanZero) },
			H_INTERVAL: { required, integer }
			
		}

		const v$ = useVuelidate(rules, item, { $lazy: true })
		
		watch(route, (first, to) => {
			if (to.params?.id && to.params?.id != itemid.value) {
				itemid.value = to.params.id
				getDetails()
				submitLabel.value = "Edit"
			}
		})

		const serverlist = computed(() => store.state.hosting.servers);
		const clients = computed(() => store.state.accounting.clients);
		const articles = computed(() => store.state.hosting.hosting_articles);

		onBeforeMount(() => {
			store.dispatch("CLIENTS", true);
			store.dispatch("HOSTING_ARTICLES",  true);
			store.dispatch("HOSTING_SERVERLIST", true);
		})

		onMounted(() => {
			if (route.params?.id) {
				itemid.value = route.params.id
				submitLabel.value = "Edit"
				getDetails()
			}
			else {
				submitLabel.value = "Add"
			}
		})

		const resetData = () => {
			item.value = matrix;
			console.log("itemn has been reset to " , item.value)
			submitLabel.value = "Add"
		}
				
		
		const getDetails = () => {
			if (props.add == 1) {
				return false;
			}
			loading.value = true;
			HostingSource.getSubscription(itemid.value)
				.then((response) => {
					item.value = response.data;
					setInstallerString()
				})
				.catch((error) => {
					toaster.error("Could not fetch data ", error);
				})
				.finally(() => {
					loading.value = false;
				});
		}

		const editHosting = async () => {
			const result = await(v$.value.$validate());
			if(result == false) {
				return false;
			}
			if (item.value.H_ID && item.value.H_ID > 0) {
				HostingSource.editSubscription(item.value)
					.then((response) => {
						if (response.data.Error) {
							toaster.error(
								"Could not edit Hosting: \n<br/>" + response.data.Error
							);
							console.log(response.data.Error);
						} else {
							emit('updateList', item.value);
							toaster.success("Hosting updated");
							// getDetails(item.value.H_ID);
						}
					})
					.catch((error) => {
						toaster.error("Could not edit Hosting ", error);
					});
			} else {
				HostingSource.addSubscription(item.value)
					.then((response) => {
						if (response.data.Error) {
							toaster.error(
								"Could not add Hosting: \n<br/>" + response.data.Error
							);
							console.error(response.data.Error);
						} else {
							toaster.success("Hosting added");
							emit('addToList', item.value);
							router.push({
								path: "/hosting/subscriptions/list",
							});
						}
					})
					.catch((error) => {
						toaster.error("Could not add Server ", error);
					});
			}
		}

		const deleteHosting = () => {
			if (confirm("Are you absolutely sure? The world will be a different place")) {
				HostingSource.deleteSubscription(item.value.H_ID)
					.then((response) => {
						console.error(response.data.Error);
						if (response.data.Error) {
							toaster.error(
								"Could not delete Hosting: \n<br/>" + response.data.Error
							);
							console.error(response.data.Error);
						} else {
							toaster.success("Hosting deleted");
							item.value = matrix;
							router.push({ path: "/hosting/subscriptions/list" });
						}
					})
					.catch((error) => {
						toaster.error("Could not delete Server ", error);
					});
			}
		}

		const setInstallerString = () => {
			installerstring.value =
				`$domain = "${item.value.H_DOMAIN}";
	$ip = "${item.value.H_IP}";
	$user = "${item.value.H_USERNAME}";
	$wpuser = "${item.value.H_WP_USER}";
	$wppwd = "${item.value.H_WP_PASSWD}";
	/*
	rsync -ua installer root@${item.value.H_IP}:~/
	ssh root@${item.value.H_IP}
	./installer/install.php --todo domain
	*/
	`;
		}

		const setDummy = () => {
			item.value = dummy;
		}

		return {
			item,
			submitLabel,
			loading,
			clients,
			serverlist,
			articles,
			statuslist,
			installerstring,
			editHosting,
			deleteHosting,
			setDummy,
			resetData,
			v$
		}
	}

};
