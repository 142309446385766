import { createToaster } from "@meforma/vue-toaster";
import Dropdown from "primevue/dropdown";
import vSelect from 'vue-select'
import Toggle from '@vueform/toggle'
// import Calendar from "primevue/calendar";
import { Calendar, DatePicker } from 'v-calendar';
import * as dayjs from 'dayjs'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from "vuex"; //eslint-disable-line no-unused-vars
import InvoicesSource from '@/js/backend/invoices';
import countries from "../../js/countries";
import InvoiceItem from "./InvoiceItem.vue";
import { ref, watch, onBeforeMount, onMounted, computed } from "vue";
import 'v-calendar/dist/style.css';

export default {

	props: {
		add: String,
	},

	components: {
		Dropdown,
		vSelect,
		Toggle,
		DatePicker,
		Calendar,
		InvoiceItem
	},

	setup(props, { emit }) {

		const store = useStore();

		const clientstatusselect = ref([
			{"id" : 0, "text": "Unconfirmed/Inactive"},
			{"id" : 1, "text" : "Billed"},
			{"id" : 2, "text" : "Prepaid"}
		])
		
		const matrix = {
			"IT_ID": 0,
			"IT_NUMBER": "",
			"IT_INVOICE": 0,
			"IT_CLID": 0,
			"IT_PRODUCTID": 0,
			"IT_TYPE": "",
			"IT_NAME": "",
			"IT_TEXT": "",
			"IT_PRICE": 0,
			"IT_FROM": null,
			"IT_TO": null,
			"IT_CD":null
		}

		const invoice = ref({});
		const toaster = createToaster({ position: 'top' });
		const route = useRoute()
		const router = useRouter()
		const loading = ref(false);
		const itemid = ref(null);
		let submitLabel = ref("Add");
		const datePickerAttributes = ref([{ 
			title: "MM YYYY" 
		}]);
		const dateConfig = {
			type: "string",
			mask: "YYYY-MM-DD", // Uses 'iso' if missing
		};
		const clients = computed(() => store.state.accounting.clients);
		
		watch(route, (first, to) => {
			if (to.params?.id && to.params?.id != itemid.value) {
				itemid.value = to.params.id
				getDetails()
				submitLabel.value = "Edit"
			}
			else {
				submitLabel.value = "Add"
			}
		})

		onBeforeMount(() => {
			store.dispatch("CLIENTS", true);
		});
		
		onMounted(() => {
			if (route.params?.id) {
				itemid.value = route.params.id
				submitLabel.value = "Edit"
				getDetails()
			}
			else {
				submitLabel.value = "Add";
				resetData();
			}
		})

		const resetData = () => {
			invoice.value = {
				IN_OVERDUE: 0,
				IN_CLID: 0,
				IN_CUSTOM_HEADER: "",
				IN_TOTAL: 0,
				IN_DUE: dayjs().format("YYYY-MM-DD"),
				items: []
			}
			console.log("items have been reset to " , invoice.value)
			submitLabel.value = "Add"
		}
		
		const getDetailsAndUpdateList = () => {
			getDetails(true);
		}
		
		const getDetails = (updateList = false) => {
			if (props.add == 1) {
				console.log("no data to fetch")
				return false;
			}
			loading.value = true;
			console.log("fetch data from invoice endpoint for id ", itemid.value)
			InvoicesSource.getInvoice(itemid.value)
				.then((response) => {
					invoice.value = response.data;
					if(updateList == true) {
						emit('updateList', invoice.value);
					}
				})
				.catch((error) => {
					toaster.error("Could not fetch data ", error);
				})
				.finally(() => {
					loading.value = false;
				});
		};
		
		
		const showAddItem = () => {
			let newentry = JSON.parse(JSON.stringify(matrix));
			newentry.IT_INVOICE = invoice.value.IN_ID
			newentry.IT_CLID = invoice.value.IN_CLID
			newentry.IT_CD = dayjs().format("YYYY-MM-DD")
			console.log(newentry);
			if(!invoice.value.items) {
				invoice.value.items = [newentry]
			}
			else {
				invoice.value.items.push(newentry)
			}
		};
		
		const addInvoice = () => {
			console.log(invoice.value);
			
			invoice.value.IN_CLID = invoice.value.IN_CLID.CL_ID
			InvoicesSource.addInvoice(invoice.value).then((response) => {
				if (response.data.Error) {
					toaster.error(
						"Could not add invoice: \n<br/>" + response.data.Error
					);
					console.error(response.data.Error);
				} else {
					toaster.success("Added Invoice");
					emit('addToList')
					resetData();
					console.log(`Now let's go to /accounting/invoices/single/${response.data.IN_ID}`);
					router.push({
						path: `/accounting/invoices/single/${response.data.IN_ID}`,
					});
					window.location.href=  `/accounting/invoices/single/${response.data.IN_ID}`;
				}
			})
			.catch((error) => {
				toaster.error("Could not send ", error);
			});
			
		}
		
		const setCustomHeader = () => {
			InvoicesSource.setInvoiceValue(invoice.value.IN_ID,'IN_CUSTOM_HEADER',invoice.value.IN_CUSTOM_HEADER).then((response) => {
				if (response.data.Error) {
					toaster.error(
						"Could not set Header: \n<br/>" + response.data.Error
					);
					console.error(response.data.Error);
				} else {
					toaster.success("Header set");
				}
			})
			.catch((error) => {
				toaster.error("Could not set Header ", error);
			});

		}
		
		const sendInvoice = () => {
			InvoicesSource.sendInvoice(invoice.value.IN_ID).then((response) => {
				if (response.data.Error) {
					toaster.error(
						"Could not set Header: \n<br/>" + response.data.Error
					);
					console.error(response.data.Error);
				} else {
					toaster.success("Invoice sent");
				}
			})
			.catch((error) => {
				toaster.error("Could not send Invoice ", error);
			});

		}
		
		const setOverdue = () => {
			InvoicesSource.setInvoiceValue(invoice.value.IN_ID,'IN_OVERDUE',invoice.value.IN_OVERDUE).then((response) => {
				if (response.data.Error) {
					toaster.error(
						"Could not set Header: \n<br/>" + response.data.Error
					);
					console.error(response.data.Error);
				} else {
					toaster.success("Overdue set");
				}
			})
			.catch((error) => {
				toaster.error("Could not set Overdue ", error);
			});

		}
		
		const removeFromList = (id) => {
			invoice.value.items.value.splice(invoice.value.items.findIndex(v => v.IT_ID === id), 1);
			console.log("removed ID ", id)
		}
		
		return {
			invoice,
			submitLabel,
			loading,
			countries,
			clientstatusselect,
			datePickerAttributes,
			dateConfig,
			resetData,
			setCustomHeader,
			sendInvoice,
			removeFromList,
			getDetails,
			getDetailsAndUpdateList,
			showAddItem,
			setOverdue,
			addInvoice,
			clients
		}
	}
};
