import Dropdown from "primevue/dropdown";
import { createToaster } from "@meforma/vue-toaster";
import Toggle from '@vueform/toggle'
import ContactsSource from "@/js/backend/domains";
import { Calendar, DatePicker } from 'v-calendar';
import { useStore } from "vuex"; //eslint-disable-line no-unused-vars
// import { defineRule } from 'vee-validate';
import useVuelidate from '@vuelidate/core'
import { required, email, integer, helpers } from '@vuelidate/validators'
import { useRouter, useRoute } from 'vue-router'
import * as dayjs from 'dayjs'
import handleModal from "./handleModal.vue"
import countries from "../../js/countries";
import providers from "../../js/providers";
import { ref, reactive, computed, watch, onBeforeMount, onMounted, defineProps } from "vue";
import 'v-calendar/dist/style.css';

export default {
	props: {
		add: String,
	},

	components: {
		Dropdown,
		DatePicker,
		Calendar,
		handleModal,
		Toggle
	},

	setup(props, { emit }) {
		name: "ContactEdit";
		
		const store = useStore()
		const toaster = createToaster({ position: 'top' });
		const router = useRouter()
		const route = useRoute()

		const matrix = {
			CO_CLID:0,
			CO_PROVIDER:0,
			CO_HANDLE: "",
			CO_INTERNAL_ID:"0",
			CO_TYPE:"P",
			CO_FIRSTNAME:"",
			CO_LASTNAME:"",
			CO_COMPANY:"",
			CO_TEL:"",
			CO_FAX:"",
			CO_EMAIL:"",
			CO_STREET:"",
			CO_ZIP:"",
			CO_CITY:"",
			CO_STATE:"",
			CO_COUNTRY:"",
			CO_BIRTHDAY:"",
			CO_AUTH_CODE:"",
			CO_PERSID:"",
			CO_PERS_ISSUED:"",
			CO_VATID:"",
			CO_ISOWNER:1,
			CO_CLIENTBASEACCOUNT: 0
		};
		
		const parsedValues = ref({
			clientname: "",
			clientbaseaccount: "",
			provider: "",
			type: "",
		})
		
		const types = ref([
			{"NAME": "Person", "ID": "P"},
			{"NAME": "Organisation", "ID": "O"}
		])
		
		const itemid = ref(null)
		const loading = ref(false);
		const item = ref();
		const registryDetails = ref();
		const registryLoading = ref(false);
		const statuslist = ref([
			{ 'STATUS_TEXT': 'Inactive', 'STATUS_ID': 0 },
			{ 'STATUS_TEXT': 'Active', 'STATUS_ID': 1 },
		]);
		const datePickerAttributes = ref([{ 
			title: "MM YYYY" 
		}]);
		
		const datePickerModelConfig = ref([{
			type: 'string',
			mask: 'YYYY-MM-DD'
		}]);


		const isOptionalDate = (value) => !helpers.req(value) || /\d{4}-\d{2}-\d{2}/.test(value);
		const isPhoneNumber = (value) => /^\+\d{1,4}-\d{1,5}-\d{3,12}$/.test(value)
		const isFaxNumber = (value) => !helpers.req(value) || /^\+\d{1,4}-\d{1,5}-\d{3,12}$/.test(value)
		const IdCardDate = (value) => !helpers.req(value) || /^\+\d{1,4}-\d{1,5}-\d{3,12}$/.test(value)

		const rules = {
			CO_TEL: { required, isPhoneNumber: helpers.withMessage("Must be in the format +XX-XXX-XXXXX", isPhoneNumber) },
			CO_FAX: { isFaxNumber: helpers.withMessage("Must be in the format +XX-XXX-XXXXX", isFaxNumber) },
			CO_EMAIL: { required, email },
			CO_STREET: { required },
			CO_ZIP: { required },
			CO_CITY: { required },
			CO_COUNTRY: { required },
			CO_BIRTHDAY: { isOptionalDate: helpers.withMessage("Must be a valid date", isOptionalDate) },
			CO_PERS_ISSUED: { IdCardDate: helpers.withMessage("Must be a valid date", IdCardDate) },
			CO_CLIENTBASEACCOUNT: { required, integer },	
		}

		const v$ = useVuelidate(rules, item, { $lazy: true })
		
		watch(route, (first, to) => {
			if (to.params?.id && to.params?.id != itemid.value) {
				itemid.value = to.params.id
				getDetails()
			}
		})

		const clients = computed(() => store.state.accounting.clients);

		onBeforeMount(() => {
			store.dispatch("CLIENTS", true);
			store.dispatch("DOMAINS_DEFAULT_NAMESERVERS", false);
			item.value =JSON.parse(JSON.stringify(matrix));
		})

		onMounted(() => {
			if (route.params?.id) {
				itemid.value = route.params.id
				getDetails()
			}
		})
		
		const resetData = () => {
			item.value = matrix;
			registryDetails.value = false;
			console.log("item has been reset to " , item.value)
			v$.value.$reset();
		}

		// const formattedBirthday = computed(() => {
    //   return (dayjs(item.value.CO_BIRTHDAY).format(
		// 		"YYYY-MM-DD"
		// 	));
    // });

		const getDetails = () => {
			console.log("getting details for ", itemid.value)
			if (props.add == 1) {
				return false;
			}
			resetData();
			// console.log("get details for ", itemid.value)
			loading.value = true;
			ContactsSource.getContact(itemid.value)
				.then((response) => {
					item.value = response.data.contact;
					const cldata = clients.value.find( ({ CL_ID }) => CL_ID ===  item.value.CO_CLID);
					parsedValues.value.clientname = cldata.NAME
					parsedValues.value.clientbaseaccount = (item.value.CO_CLIENTBASEACCOUNT == 0) ? "NO" : "YES";
					parsedValues.value.isowner = (item.value.CO_ISOWNER == 0) ? "NO" : "YES";
					const provdata = providers.find( ({ value }) => value ===  item.value.CO_PROVIDER);
					parsedValues.value.provider = provdata.text
					// parsedValues.value.type = types.value.find( ({ ID }) => ID ===  item.value.CO_TYPE).NAME
					const typedata = types.value.find( ({ ID }) => ID ===  item.value.CO_TYPE)
					parsedValues.value.type = typedata.NAME

					// console.log("GOt for id ", itemid.value, " ", item.value);
				})
				.catch((error) => {
					toaster.error("Could not fetch data ", error);
				})
				.finally(() => {
					loading.value = false;
				});
		}

		const editContact = async () => {
			// console.log("Adding Contact")
			const result = await(v$.value.$validate());
			// console.log("Test result: ", result)
			if(result == false) {
				console.error(v$.value.$errors);
				return false;
			}
			ContactsSource.editContact(item.value)
				.then((response) => {
					if (response.data.Error) {
						toaster.error(
							"Could not edit Contact: \n<br/>" + response.data.Error
						);
						console.error(response.data.Error);
					} else {
						if(response.data.CO_HANDLE) {
							item.value.CO_HANDLE = response.data.CO_HANDLE;
							item.value.CO_INTERNAL_ID = response.data.CO_HANDLE;
						}
						if(response.data.CO_ID) {
							item.value.CO_ID = response.data.CO_ID;
						}
						item.value.CO_DISPLAY = (item.value.CO_COMPANY != '' && item.value.CO_COMPANY != '0') ? `${item.value.CO_COMPANY}, ` : "";
						item.value.CO_DISPLAY += `${item.value.CO_FIRSTNAME} ${item.value.CO_LASTNAME}`;
						
						const cldata = clients.value.find( ({ CL_ID }) => CL_ID ===  item.value.CO_CL_ID);
						item.value.CLIENT = cldata.NAME
						
						
						if(item.value.CO_PROVIDER == 3) {
							let newlist = JSON.parse(JSON.stringify(item.value));
							newlist.CO_ISOWNER = (newlist.CO_ISOWNER == 1) ? 0 : 1;
						}
						emit('updateList', item.value);
						toaster.success("Contact changed");
					}
				})
				.catch((error) => {
					toaster.error("Could not add Server ", error);
				});
		}
		
		const getFromRegistry = () => {
			registryLoading.value = true;
			ContactsSource.getContactInfo(item.value.CO_ID)
				.then((response) => {
					registryDetails.value = response.data.data
				})
				.catch((error) => {
					toaster.error("Could not get Info from registry ", error);
				})
				.finally(() => {
					registryLoading.value = false;
				})
			
			return true;
		}
		
		const setValue = (item_name,item_value) => {
			ContactsSource.setContactValue(item.value.CO_ID, item_name, item_value)
				.then((response) => {
					item.value[item_name] = item_value
					toaster.success("Value set in DB ");
				})
				.catch((error) => {
					toaster.error("Could not get Info from registry ", error);
				})
			return true;
		}
		
		return {
			item,
			registryDetails,
			registryLoading,
			loading,
			clients,
			parsedValues,
			types,
			statuslist,
			editContact,
			getFromRegistry,
			Calendar, 
			DatePicker,
			setValue,
			providers,
			datePickerAttributes,
			datePickerModelConfig,
			countries,
			v$
		}
	}

};
