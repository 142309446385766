import { createApp } from 'vue'
import { createI18n,useI18n } from 'vue-i18n'
import App from './App.vue'
import router from './router.js'
import store from './store'
// import { createRouter, createWebHistory } from 'vue-router'
import Toaster from '@meforma/vue-toaster';
import PrimeVue from 'primevue/config';

import VerticalNavigation from "@/components/VerticalNavigation.vue";
import { 
	BIconPersonXFill, BIconCheckCircleFill, 
	BIconCodeSlash,
	BIconTrashFill, BIconDashCircleFill, 
	BIconInfoSquareFill, BIconCoin,
	BIconFilePdfFill, BIconCollectionFill,
	BIconArrowRightSquareFill, BIconPlusSquareFill,
	BIconArrowRepeat, BIconXCircleFill, BIconEnvelopeOpen } from 'bootstrap-icons-vue';
// import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
// import dayjs from 'dayjs'
import * as dayjs from 'dayjs'
import API from "./js/backend/axios";

import duration from 'dayjs/plugin/duration'
dayjs.extend(duration);

const numberFormats = {
	'en-US': {
		currency: {
			style: 'currency', currency: 'USD', notation: 'standard'
		},
		decimal: {
			style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
		},
		percent: {
			style: 'percent', useGrouping: false
		}
	},
	'de-DE': {
		currency: {
			style: 'currency', currency: 'EUR', useGrouping: true, currencyDisplay: 'symbol'
		},
		decimal: {
			style: 'decimal', minimumSignificantDigits: 3, maximumSignificantDigits: 5
		},
		percent: {
			style: 'percent', useGrouping: false
		}
	}
}
const i18n = createI18n({
	legacy: false, // you must set `false`, to use Composition API
	locale: 'ja', // set locale
	fallbackLocale: 'en', // set fallback locale
	scope: 'global',
	numberFormats,
	globalInjection: true
})

const app = createApp(App)

app.component('VerticalNavigation', VerticalNavigation) // global registration - can be used anywhere
app.component('BIconPersonXFill', BIconPersonXFill) // global registration - can be used anywhere
app.component('BIconTrashFill', BIconTrashFill) // global registration - can be used anywhere
app.component('BIconCheckCircleFill', BIconCheckCircleFill) // global registration - can be used anywhere
app.component('BIconCodeSlash', BIconCodeSlash) // global registration - can be used anywhere
app.component('BIconCollectionFill', BIconCollectionFill) // global registration - can be used anywhere
app.component('BIconDashCircleFill', BIconDashCircleFill) // global registration - can be used anywhere
app.component('BIconInfoSquareFill', BIconInfoSquareFill) // global registration - can be used anywhere
app.component('BIconArrowRightSquareFill', BIconArrowRightSquareFill) // global registration - can be used anywhere
app.component('BIconPlusSquareFill', BIconPlusSquareFill) // global registration - can be used anywhere
app.component('BIconCoin', BIconCoin) // global registration - can be used anywhere
app.component('BIconFilePdfFill', BIconFilePdfFill) // global registration - can be used anywhere
app.component('BIconArrowRepeat', BIconArrowRepeat) // global registration - can be used anywhere
app.component('BIconXCircleFill', BIconXCircleFill) // global registration - can be used anywhere
app.component('BIconEnvelopeOpen', BIconEnvelopeOpen) // global registration - can be used anywhere
app.config.globalProperties.$dayjs = dayjs
app.config.globalProperties.$API = API

// const router = createRouter({
//   history: createWebHistory(),
//   router,
// })

// app.use(dayjs);

app.use(Toaster, {
  // One of the options
  position: 'top'
})
app.use(i18n)
app.use(router)
app.use(PrimeVue)
app.use(store)
app.mount('#app')
