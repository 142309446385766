<template>
	<div
		id="pricesdomainsmodal"
		class="modal-dialog-scrollable modal-xl modal-fullscreen-lg-down transfermodal modal-dialog"
	>
		<div class="modal-content">
			<div class="container-fluid text-center">
				<div class="row mt-1">
					<div class="col">
						<h3 class="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
							Hosting Prices for <b>{{ details.HP_SHORT }}</b>
						</h3>
					</div>
					<div class="col" v-if="add">
						<input type="text" class="form-control" v-model="details.HP_SHORT" />
					</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="container-fluid text-center" v-if="loading">
					<div class="loader loader__sm">
						<div class="spinner-grow text-success" style="width: 2rem; height: 2rem" role="status"></div>
						<p class="text-primary">Loading...</p>
					</div>
				</div>
				<div class="container-fluid" style="min-height: 250px" v-else>
					<form @submit.prevent="editPrices">
						<div class="row">
							<div class="col col-4">
								<div class="title mb-2">Name</div>
								<input type="text" class="form-control" v-model="details.HP_NAME" />
							</div>
							<div class="col col-4">
								<div class="title mb-2">Months</div>
								<input type="text" class="form-control" v-model="details.HP_MONTHS" />
							</div>
							<div class="col col-4">
								<div class="title mb-2">Cost</div>
								<input
									type="number"
									step="0.01"
									class="form-control"
									v-model="details.HP_COST"
								/>
							</div>
						</div>
						<div class="row">
							<div class="col col-4">
								<div class="title mb-2">Tier 1</div>
								<input
									type="number"
									step="0.01"
									class="form-control"
									v-model="details.HP_T1"
								/>
							</div>
							<div class="col col-4">
								<div class="title mb-2">Tier 2</div>
								<input
									type="number"
									step="0.01"
									class="form-control"
									v-model="details.HP_T2"
								/>
							</div>
							<div class="col col-4">
								<div class="title mb-2">Tier 3</div>
								<input
									type="number"
									step="0.01"
									class="form-control"
									v-model="details.HP_T3"
								/>
							</div>
						</div>
						<div class="row">
							<div class="col text-center pt-3">
								<button type="submit" class="btn btn-success">Save</button>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</template>

<script>
import vSelect from "vue-select";
import Toggle from "@vueform/toggle";
import "vue-select/dist/vue-select.css";
import ArticlesSource from "@/js/backend/articles";
import { Modal } from "bootstrap";
import { createToaster } from "@meforma/vue-toaster";
import { ref, onMounted } from "vue";
export default {
	props: {},

	components: {
		Toggle,
		vSelect,
	},

	setup(props, { emit }) {
		name: "PricesHostingModal";
		const toaster = createToaster({ position: "top-left" });
		const theModal = ref();
		const theschema = ref({
			HP_SHORT: "",
			HP_NAME: "",
			HP_T1: 0,
			HP_T2: 0,
			HP_T3: 0,
			HP_MONTHS: 0,
			HP_COST: 0,
		});
		const details = ref(JSON.parse(JSON.stringify(theschema.value)));
		const details_orig = ref(JSON.parse(JSON.stringify(theschema.value)));
		const error = ref(false);
		const errors = ref([]);
		const loading = ref(false);
		const add = ref(false);

		onMounted(() => {
			theModal.value = new Modal(document.getElementById("PricesHostingModal"));
		});

		const showNew = () => {
			add.value = true;
			details.value = JSON.parse(JSON.stringify(theschema.value));
		};

		const showDetails = tld => {
			add.value = false;
			ArticlesSource.getHostingPrices(tld)
				.then(res => {
					if (!res || !res.data) {
						toaster.error("Cannot get Hosting Info: ");
					} else if (res.data.Error) {
						toaster.error(`Error: ${res.data.Error}`);
					} else {
						details.value = res.data;
						details_orig.value = JSON.parse(JSON.stringify(details.value));
					}
				})
				.catch(error => {
					toaster.error(error, "Error getting Hosting");
				});
		};



		const editPrices = () => {
			if (add.value == true) {
				ArticlesSource.addHostingPrices(details.value)
					.then(response => {
						if (response.data.Error) {
							let err = response.data.Error.replace(/\n/g, "\n<br/>");
							toaster.error("Could not add Prices: \n<br/>" + err);
							console.log(typeof response.data.Error);
						} else {
							emit("addToList", details.value);
							toaster.success("Prices added");
							// getDetails(item.value.H_ID);
						}
					})
					.catch(error => {
						toaster.error("Could not edit Prices ", error);
					});
			} else {
				ArticlesSource.setHostingPrices(details.value)
					.then(response => {
						if (response.data.Error) {
							let err = response.data.Error.replace(/\n/g, "\n<br/>");
							toaster.error("Could not edit Prices: \n<br/>" + err);
							console.log(typeof response.data.Error);
						} else {
							emit("updateList", details.value);
							toaster.success("Prices updated");
							// getDetails(item.value.H_ID);
						}
					})
					.catch(error => {
						toaster.error("Could not edit Prices ", error);
					});
			}
		};

		return {
			add,
			error,
			errors,
			showDetails,
			showNew,
			editPrices,
			loading,
			details,
			details_orig,
		};
	},
};
</script>

<style lang="scss">
@import "../../assets/scss/main.scss";
#detailsmodal {
	.modal-content {
		font-size: 1rem;
		padding: 5px;
	}

	.row.error {
		border: 1px solid $color-error;
		li {
			font-size: 16px;
			color: $color-error;
		}
	}

	.copy {
		border: 1px solid $color-success;
		color: $color-success;
		border-radius: 0.2rem;
		padding: 0.3rem;
		&:hover {
			cursor: pointer;
			color: $color-success-hover;
			border: 1px solid $color-success-hover;
		}
	}
}
</style>
