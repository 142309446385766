<template>
	<div class="domains" id="domaindetails">
		<h2>Add Domain</h2>
		<div class="container-fluid" v-if="loading">
			<div class="loader">
				<div class="spinner-grow text-success" style="width: 6rem; height: 6rem" role="status"></div>
				<p class="text-primary">Loading...</p>
			</div>
		</div>
		<div class="container-fluid details" v-else>
			<form @submit.prevent="addDomain">
				<div class="row">
					<div class="col col-4">
						<div :class="{ error: v$.DOM_NAME.$errors.length }">
							<label for="hostname" class="control-label">Name</label>
							<input type="text" class="form-control" id="domain" @change="checkAvailable" :class="unAvailableClass"
								placeholder="Domain Name" v-model="item.DOM_NAME" />
							<div class="input-errors" v-for="error of v$.DOM_NAME.$errors" :key="error.$uid">
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>

					<div class="col col-2">
						<div class="d-flex justify-content-center align-items-center" style="margin-top:24px">
							<div>
								<i v-if="isChecking" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
							</div>
							<div>
								<span v-if="isAvailable == 2" class="unavailable">Unavailable</span>
								<span v-if="isAvailable == 1" class="available">Available</span>
								<span v-if="isAvailable == 3" class="available">Manual</span>
							</div>
						</div>
					</div>

					<div class="col col-6">
						<label for="hostname" class="control-label">IP</label>
						<input type="text" class="form-control" id="domain" v-model="item.DOM_IP" />
					</div>

				</div>
				<div class="row">
					<div class="col col-6">
						<div :class="{ error: v$.DOM_CLIENTID.$errors.length }">
							Client: <br />
							<Dropdown v-model="item.DOM_CLIENTID" :options="clients" optionLabel="NAME" optionValue="CL_ID"
								placeholder="Select one" />
							<div class="input-errors" v-for="error of v$.DOM_CLIENTID.$errors" :key="error.$uid">
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>

					<div class="col col-6">

						<div :class="{ error: v$.NS1.$errors.length }">
							<label for="hostname" class="control-label">
								Nameservers: <button type="button" class="btn btn-secondary btn-sm" @click="setDefaultNs">Set
									Default</button>
							</label>
							<input type="text" class="form-control" id="domain" v-model="item.NS1" @change="updateDNS" />
							<div class="input-errors" v-for="error of v$.NS1.$errors" :key="error.$uid">
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col col-2">
						<div :class="{ error: v$.DOM_TLD.$errors.length }">
							<label for="hostname" class="control-label">TLD</label>
							<input type="text" class="form-control" id="domain" v-model="item.DOM_TLD" />
							<div class="input-errors" v-for="error of v$.DOM_TLD.$errors" :key="error.$uid">
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					<div class="col col-2">
						<div :class="{ error: v$.DOM_PROVIDER.$errors.length }">
							Provider: {{ item.DOM_PROVIDER}}<br />
							<Dropdown v-model="item.DOM_PROVIDER" :options="providers" optionLabel="text" optionValue="value"
								placeholder="Select one" />
							<div class="input-errors" v-for="error of v$.DOM_PROVIDER.$errors" :key="error.$uid">
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					<div class="col col-2">
						<label for="hostname" class="control-label">Manual</label><br />
						<Toggle v-model="item.DOM_MANUAL" class="toggle-red" on-label="Manual" off-label="Auto" :falseValue="0"
							:trueValue="1" />
					</div>
					<div class="col col-6">
						<div :class="{ error: v$.NS2.$errors.length }">
							<label for="hostname" class="control-label">
								NS2:

							</label>
							<input type="text" class="form-control" id="domain" v-model="item.NS2" @change="updateDNS" />
							<div class="input-errors" v-for="error of v$.NS2.$errors" :key="error.$uid">
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col col-3 handle">
						<div :class="{ error: v$.DOM_OWNER_ID.$errors.length }">
							<button v-if="item.DOM_PROVIDER > 0 && item.DOM_CLIENTID > 0" type="button" class="btn btn-outline-info"
								data-bs-toggle="modal" data-bs-target="#handleModal"
								@click="showHandles('owner','DOM_OWNER_ID',item.DOM_PROVIDER, item.DOM_CLIENTID)">
								Set Owner
							</button>
							<div class="errortext" v-else>Please choose provider and client</div>
							<input type="hidden" name="DOM_OWNER_ID" v-model="item.DOM_OWNER_ID" />
							{{item.DOM_OWNER_HANDLE}}
							<div class="input-errors" v-for="error of v$.DOM_OWNER_ID.$errors" :key="error.$uid">
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
					<div class="col col-3">
						<button v-if="item.DOM_PROVIDER > 0 && item.DOM_CLIENTID > 0" type="button" class="btn btn-outline-info"
							data-bs-toggle="modal" data-bs-target="#handleModal"
							@click="showHandles('contact','DOM_ADMINC_ID',item.DOM_PROVIDER, item.DOM_CLIENTID)">
							Set AdminC
						</button>
						<input type="hidden" name="DOM_ADMINC_ID" v-model="item.DOM_ADMINC_ID" />
						{{item.DOM_ADMIN_HANDLE}}
					</div>
					<div class="col col-6">
						<label for="hostname" class="control-label">
							NS3:
						</label>
						<input type="text" class="form-control" id="domain" v-model="item.NS3" @change="updateDNS" />
					</div>
				</div>
				<div class="row">
					<div class="col col-3">
						<button v-if="item.DOM_PROVIDER > 0 && item.DOM_CLIENTID > 0" type="button" class="btn btn-outline-info"
							data-bs-toggle="modal" data-bs-target="#handleModal"
							@click="showHandles('contact','DOM_TECHC_ID',item.DOM_PROVIDER, item.DOM_CLIENTID)">
							Set TechC
						</button>
						<input type="hidden" name="DOM_TECHC_ID" v-model="item.DOM_TECHC_ID" />
						{{item.DOM_TECH_HANDLE}}
					</div>
					<div class="col col-3">
						<button v-if="item.DOM_PROVIDER > 0 && item.DOM_CLIENTID > 0" type="button" class="btn btn-outline-info"
							data-bs-toggle="modal" data-bs-target="#handleModal"
							@click="showHandles('contact','DOM_ZONEC_ID',item.DOM_PROVIDER, item.DOM_CLIENTID)">
							Set ZoneC
						</button>
						<input type="hidden" name="DOM_ZONEC_ID" v-model="item.DOM_ZONEC_ID" />
						{{item.DOM_ZONE_HANDLE}}
					</div>
					<div class="col col-6">
						<label for="hostname" class="control-label">
							NS4:
						</label>
						<input type="text" class="form-control" id="domain" v-model="item.NS4" @change="updateDNS" />
					</div>
				</div>
				<div class="row">
					<div class="col col-6">
						<label for="site_first_payment" class="control-label">Due</label>
						<br />
						<DatePicker :attributes='datePickerAttributes' :masks="{ title: 'MMM YYYY', L: 'YYYY-MM-DD'  }"
							v-model="item.DOM_DUE">
							<template v-slot="{ inputValue, inputEvents }">
								<input class="form-control" :value="inputValue" v-on="inputEvents" />
							</template>
						</DatePicker>
					</div>
					<div class="col col-6">
						<label for="hostname" class="control-label">
							NS5:
						</label>
						<input type="text" class="form-control" id="domain" v-model="item.NS5" @change="updateDNS" />
					</div>
				</div>
				<div class="row">
					<div class="col col-3">
						Status
						<br />
						<Toggle v-model="item.DOM_CANCEL" class="toggle-red" on-label="Cancelled" off-label="Active" :falseValue="0"
							:trueValue="1" />
					</div>
					<div class="col col-3">
						<div v-if="showPrivacy">
							Whois Privacy
							<br />
							<Toggle v-model="item.DOM_PRIVACY" class="toggle-green" on-label="On" off-label="Off" :falseValue="0"
								:trueValue="1" />
						</div>
					</div>
					<div class="col col-6">
						<label for="hostname" class="control-label">
							NS6:
						</label>
						<input type="text" class="form-control" id="domain" v-model="item.NS6" @change="updateDNS" />
					</div>
				</div>

				<div class="row">
					<div class="col col-6 text-center">
						<input class="btn btn-primary" type="submit" name="su" :value="submitLabel" />
					</div>
					<div class="col col-6 text-center">
						<button type="button" class="btn btn-warning" @click="setDummy">
							Dummy
						</button>

						<button type="button" class="btn btn-warning" @click="changeValueTest">
							addToList
						</button>
					</div>
				</div>
			</form>
			<pre>
				{{item}}
			</pre>
		</div>
		<div class="modal fade" id="handleModal" tabindex="-1" aria-labelledby="handleModal" aria-hidden="true">
			<handleModal @addContact="addContactHandler" ref="handlemodalwindow" />
		</div>
	</div>
</template>

<script src="./DomainAdd.js"></script>
<style lang="scss">
@import "../../assets/scss/main.scss";

#domaindetails {
	.collapse .container-fluid {
		padding-left: 0;

		.col {
			padding-left: 0;
		}
	}

	.toggle-red {
		.toggle {
			width: 90px !important;
			color: white;
			font-weight: bold;

			&.toggle-off {
				padding-right: 10px;
			}

			&.toggle-on {
				padding-left: 10px;
			}
		}

		--toggle-bg-on: #a00e0e;
		--toggle-border-on: #a00e0e;
		--toggle-bg-off: #{$color-success};
		--toggle-border-off: #{$color-success};
	}

	.toggle-green {
		.toggle {
			width: 90px !important;
			color: white;
			font-weight: bold;

			&.toggle-off {
				padding-right: 10px;
			}

			&.toggle-on {
				padding-left: 10px;
			}
		}

		--toggle-bg-off: #a00e0e;
		--toggle-border-off: #a00e0e;
		--toggle-bg-on: #{$color-success};
		--toggle-border-on: #{$color-success};
	}

	.errortext {
		color: $color-error;
	}

	.handle {
		.error .input-errors {
			display: block;
		}
	}
}
</style>