import ClientDetails from "./ClientDetails.vue";
import { VueGoodTable } from 'vue-good-table-next';
import { ref, onBeforeMount } from "vue";
import { onBeforeRouteUpdate, useRoute } from 'vue-router'
import { createToaster } from "@meforma/vue-toaster";
import ClientSource from '@/js/backend/client';

export default {
	components: {
		VueGoodTable,
		ClientDetails
	},

	setup() {
		const toaster = createToaster({ position: 'top' });
		const	detailurl = ref('');
		const active = ref('list');
		const clid = ref(0);
		const route = useRoute()

		const rows = ref([]);
		const columns = ref([
			{ label: 'ID', field: 'CL_ID', type: 'number' },
			{
				label: 'Last Name',
				field: 'CL_LASTNAME',
				filterOptions: {
					styleClass: 'class1', // class to be added to the parent th element
					enabled: true, // enable filter for this column
				}
			},
			{
				label: 'First Name',
				field: 'CL_FIRSTNAME',
				filterOptions: {
					styleClass: 'class1', // class to be added to the parent th element
					enabled: true, // enable filter for this column
				}
			},
			{
				label: 'Company',
				field: 'CL_COMPANY',
				filterOptions: {
					styleClass: 'class1', // class to be added to the parent th element
					enabled: true, // enable filter for this column
				}
			},
			{ label: 'Action', field: 'Action' }
		]);
		const totalRecords = ref(0);
		const serverParams = ref ({
			// a map of column filters example: {name: 'john', age: '20'}
			columnFilters: {},
			sort: [
				{
					field: "CL_LASTNAME", // example: 'name'
					type: "asc", // 'asc' or 'desc'
				},
			],

			page: 1, // what page I want to show
			perPage: 20, // how many items I'm showing per page
		});

		onBeforeMount(() => {
			getTab(route);
			loadItems();
		});
		
		onBeforeRouteUpdate ((to, from, next) => {
			next(vm => {
				vm.getTab(to)
			})
		});
	
		
		function showTab(which) {
			active.value = which;
			return which;
		}
		
		function showDetails(id) {
			clid.value = parseInt(id);
			console.log("set id to ", clid.value);
			showTab('details');
		}
		
		function getTab(addr) {
			console.log("Get tab called for ", addr)
			if(!addr) {
				showTab('list');
			}
			else if(addr.params?.id) {
				console.log("show details for ", addr.params.id)
				detailurl.value = addr.fullPath
				showDetails(addr.params.id)
			}
			else if(addr.tab) {
				if(addr.tab == "add") {
					showTab('add');
				}
			}
			else {
				showTab('list');
			}
		}
		
		function updateParams(newProps) {
			serverParams.value = Object.assign({}, serverParams.value, newProps);
			console.log("Filter changed", serverParams.value);
		}

		function onPageChange(params) {
			updateParams({ page: params.currentPage });
			loadItems();
		}

		function onPerPageChange(params) {
			updateParams({ perPage: params.currentPerPage });
			loadItems();
		}

		function onSortChange(params) {
			updateParams({
				sort: [
					{
						type: params[0].type,
						field: params[0].field,
					},
				],
				page: 1,
			});
			loadItems();
		}

		function onColumnFilter(params) {
			updateParams(params);
			loadItems();
		}
		
		
		
		function prepareAdd() {
				showTab('add');
		}

		// load items is what brings back the rows from server
		function loadItems() {
			ClientSource.getClientList(serverParams.value).then((response) => {
				rows.value = response.data.rows;
				totalRecords.value = response.data.totalRecords;
				console.log(totalRecords.value + " records");
			});
		}


		function updateList(data) {
			console.log("arrived in the list with ", data)
			for (const obj of rows.value) {
				if (obj.CL_ID === data.CL_ID) {
					console.log("MATCH ", obj.CL_ID)
					obj.CL_FIRSTNAME = data.CL_FIRSTNAME,
					obj.CL_LASTNAME = data.CL_LASTNAME,
					obj.CL_COMPANY = data.CL_COMPANY
					break;
				}
			}
			return true;
		}
		
		function addToList() {
			onSortChange([{"type": "desc", "field": "CL_ID"}]);
			loadItems();
			
			// rows.value.push(data)
		}
		
		function removeFromList(id) {
			rows.value.splice(rows.value.findIndex(v => v.CL_ID === id), 1);
			console.log("removed ID ", id)
		}
		
		return {
			rows,
			totalRecords,
			columns,
			detailurl,
			active,
			updateList,
			addToList,
			removeFromList,
			prepareAdd,
			getTab,
			showTab,
			showDetails,
			onColumnFilter,
			onSortChange,
			onPerPageChange,
			onPageChange
		}
		
	}
}

