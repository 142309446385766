/* eslint-disable no-unused-vars */
import {
	DOMAINS_DEFAULT_NAMESERVERS,
	DOMAINS_SET_DEFAULT_NAMESERVERS,
	DOMAINS_WHOIS_PRIVACY_ENABLED,
	DOMAINS_SET_WHOIS_PRIVACY_ENABLED
} from "../actions/domains";
// eslint-disable-next-line no-unused-vars
import DomainsSource from "@/js/backend/domains";

const state = {
	default_nameservers: JSON.parse(localStorage.getItem("default_nameservers")) || {},
	whois_privacy_enabled: JSON.parse(localStorage.getItem("whois_privacy_enabled")) || {},
};


const actions = {
	[DOMAINS_DEFAULT_NAMESERVERS]: ({ commit, dispatch }, force) => {

		if (localStorage.getItem("default_nameservers") && !force ) {
			return JSON.parse(localStorage.getItem("default_nameservers"));
		} else {
			DomainsSource
				.getDefaultNameservers()
				.then(resp => {
					let in_ns = JSON.parse(JSON.stringify(resp.data));
					localStorage.setItem("default_nameservers", JSON.stringify(in_ns));
					commit(DOMAINS_SET_DEFAULT_NAMESERVERS);
					return in_ns;
				})
				.catch(error => {
					localStorage.removeItem("default_nameservers"); // if the request fails, remove any possible user token if possible
					return error;
				});
		}
	},
	[DOMAINS_WHOIS_PRIVACY_ENABLED]: ({ commit, dispatch }, force) => {

		if (localStorage.getItem("whois_privacy_enabled") && !force ) {
			return JSON.parse(localStorage.getItem("whois_privacy_enabled"));
		} else {
			DomainsSource
				.getWhoisPrivacyTlds()
				.then(resp => {
					let in_tlds = JSON.parse(JSON.stringify(resp.data));
					localStorage.setItem("whois_privacy_enabled", JSON.stringify(in_tlds));
					commit(DOMAINS_SET_WHOIS_PRIVACY_ENABLED);
					return in_tlds;
				})
				.catch(error => {
					localStorage.removeItem("whois_privacy_enabled"); // if the request fails, remove any possible user token if possible
					return error;
				});
		}
	}

}

const mutations = {
	[DOMAINS_SET_DEFAULT_NAMESERVERS]: (state, resp) => {
		state.default_nameservers = JSON.parse(localStorage.getItem("default_nameservers"));
	},
	[DOMAINS_SET_WHOIS_PRIVACY_ENABLED]: (state, resp) => {
		state.whois_privacy_enabled = JSON.parse(localStorage.getItem("whois_privacy_enabled"));
	},
};

export default {
	state,
	actions,
	mutations
};
