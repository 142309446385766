/* eslint-disable no-unused-vars */
import {
	HOSTING_CLIENTS,
	HOSTING_SETCLIENTS,
	HOSTING_SERVERLIST,
	HOSTING_SETSERVERLIST,
	HOSTING_ARTICLES,
	HOSTING_SETARTICLES
} from "../actions/hosting";
// eslint-disable-next-line no-unused-vars
import HostingSource from "@/js/backend/hosting";
import ArticleSource from "@/js/backend/articles";

const state = {
	clients: JSON.parse(localStorage.getItem("clients")) || {},
	servers: JSON.parse(localStorage.getItem("hosting_servers")) || {},
	hosting_articles: JSON.parse(localStorage.getItem("hosting_articles")) || {},
};


const actions = {
	[HOSTING_CLIENTS]: ({ commit, dispatch }, user) => {

		// if (localStorage.getItem("clients")) {
		// 	return JSON.parse(localStorage.getItem("clients"));
		// } else {
			HostingSource
				.getClientShortList()
				.then(resp => {
					let in_clients = JSON.parse(JSON.stringify(resp.data));
					in_clients.unshift({ text: "---", value: 0 });
					localStorage.setItem("clients", JSON.stringify(in_clients));
					commit(HOSTING_SETCLIENTS);
					return in_clients;
				})
				.catch(error => {
					localStorage.removeItem("clients"); // if the request fails, remove any possible user token if possible
					return error;
				});
		// }
	},
	[HOSTING_SERVERLIST]: ({ commit, dispatch }, force) => {

		if (localStorage.getItem("hosting_servers") && !force) {
			return JSON.parse(localStorage.getItem("hosting_servers"));
		} else {
			HostingSource
				.getServerShortList()
				.then(resp => {
					let in_servers = JSON.parse(JSON.stringify(resp.data));
					in_servers.unshift({ text: "---", value: 0 });
					localStorage.setItem("hosting_servers", JSON.stringify(in_servers));
					commit(HOSTING_SETSERVERLIST);
					return in_servers;
				})
				.catch(error => {
					localStorage.removeItem("hosting_servers"); // if the request fails, remove any possible user token if possible
					return error;
				});
		}
	},
	[HOSTING_ARTICLES]: ({ commit, dispatch }, force) => {

		if (!force && localStorage.getItem("hosting_articles")) {
			return JSON.parse(localStorage.getItem("hosting_articles"));
		} else {
			ArticleSource
				.getArticleShortList('hosting')
				.then(resp => {
					let in_hosting_articles = JSON.parse(JSON.stringify(resp.data));
					in_hosting_articles.unshift({ text: "---", value: 0 });
					localStorage.setItem("hosting_articles", JSON.stringify(in_hosting_articles));
					commit(HOSTING_SETARTICLES);
					return in_hosting_articles;
				})
				.catch(error => {
					localStorage.removeItem("hosting_articles"); // if the request fails, remove any possible user token if possible
					return error;
				});
		}
	},
}

const mutations = {
	[HOSTING_SETSERVERLIST]: (state, resp) => {
		state.hosting_servers = JSON.parse(localStorage.getItem("hosting_servers"));
	},
	[HOSTING_SETCLIENTS]: (state, resp) => {
		state.clients = JSON.parse(localStorage.getItem("clients"));
	},
	[HOSTING_SETARTICLES]: (state, resp) => {
		state.hosting_articles = JSON.parse(localStorage.getItem("hosting_articles"));
	},
};

export default {
	state,
	actions,
	mutations
};
