// eslint-disable-next-line
import API from "./axios";
export default {
	// eslint-disable-next-line
	getBilance(params) {
		return API.get("/admin/statistics/bilance/list",{params: params});
	},
	getBilanceDetails(params) {
		return API.get("/admin/statistics/bilance/details",{params: params});
	},
};
