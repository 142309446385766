<template>
	<vue-good-table
		mode="remote"
		:totalRows="totalRecords"
		:columns="columns"
		:rows="rows"
		:pagination-options="{
			enabled: true,
			mode: 'records',
			perPage: 20,
			position: 'both',
			perPageDropdown: [10, 20, 50],
			dropdownAllowAll: false,
			setCurrentPage: 1,
			nextLabel: 'next',
			prevLabel: 'prev',
			rowsPerPageLabel: 'Rows per page',
			ofLabel: 'of',
			pageLabel: 'page', // for 'pages' mode
			allLabel: 'All',
		}"
		v-on:page-change="onPageChange"
		v-on:sort-change="onSortChange"
		v-on:column-filter="onColumnFilter"
		v-on:per-page-change="onPerPageChange"
		styleClass="vgt-table striped"
	>
		>
		<template #table-row="props">
			<span v-if="props.column.field == 'Action'">
				<router-link
					class="btn btn-primary btn-sm"
					:to="'/domains/contacts/single/' + props.row.CO_ID"
				>
					Details
				</router-link>
				
			</span>
			<span v-else>
				{{ props.formattedRow[props.column.field] }}
			</span>
		</template>
	</vue-good-table>
</template>

<script>
import { VueGoodTable } from "vue-good-table-next";
import { ref, onBeforeMount } from "vue";
import DomainsSource from "@/js/backend/domains";
import providers from "@/js/providers";

export default {
	components: {
		VueGoodTable,
	},

	setup() {
		providers.push({"text": "all", "value": ""});

		const rows = ref([]);
		const columns = ref([
			{
				label: "Handle",
				field: "CO_INTERNAL_ID",
				sortable: true,
				width: "14px",
			},
			{ label: "ID", field: "CO_ID", type: "number" },
			{
				label: "Name",
				field: "CO_DISPLAY",
				sortable: true,
				filterOptions: {
					styleClass: "class1", // class to be added to the parent th element
					enabled: true, // enable filter for this column
				},
			},
			{
				label: "Provider",
				field: "CO_PROVIDER",
				sortable: true,
				filterOptions: {
					styleClass: "class1",
					enabled: true,
					filterDropdownItems: providers
				},
			},
			{
				label: "Owner",
				field: "CO_ISOWNER",
				sortable: true,
				filterOptions: {
					styleClass: "class1", // class to be added to the parent th element
					enabled: true, 
					filterDropdownItems: [
						{"text": "no", "value": 0},
						{"text": "yes", "value": 1},
						{"text": "all", "value": ""}
					]
				},
			},
			{ label: "Client", field: "CLIENT" },
			{ label: "Action", field: "Action" },
		]);
		const totalRecords = ref(0);
		const serverParams = ref ({
			// a map of column filters example: {name: 'john', age: '20'}
			columnFilters: {},
			sort: [
				{
					field: "CO_DISPLAY", // example: 'name'
					type: "asc", // 'asc' or 'desc'
				},
			],

			page: 1, // what page I want to show
			perPage: 20, // how many items I'm showing per page
		});

		onBeforeMount(() => {
			loadItems();
		});


		function updateParams(newProps) {
			serverParams.value = Object.assign({}, serverParams.value, newProps);
			console.log("Filter changed", serverParams.value);
		}

		function onPageChange(params) {
			updateParams({ page: params.currentPage });
			loadItems();
		}

		function onPerPageChange(params) {
			updateParams({ perPage: params.currentPerPage });
			loadItems();
		}

		function onSortChange(params) {
			updateParams({
				sort: [
					{
						type: params[0].type,
						field: params[0].field,
					},
				],
				page: 1,
			});
			loadItems();
		}

		function onColumnFilter(params) {
			updateParams(params);
			loadItems();
		}

		// load items is what brings back the rows from server
		function loadItems() {
			DomainsSource.getContactsQueryList(serverParams.value).then((response) => {
				rows.value = response.data.rows;
				totalRecords.value = response.data.totalRecords;
				// console.log(totalRecords.value + " records");
			});
		}

		function cancelledClassFunc(row) {
			if (row.CO_CANCELLED == "0") {
				return "cancelled cancelled__success";
			} 
			return "cancelled cancelled__error";
		}

		function updateList(data) {
			for (const obj of rows.value) {
				if (obj.CO_ID === data.CO_ID) {
					obj.CO_INTERNAL_ID = data.CO_INTERNAL_ID,
					obj.CO_FIRSTNAME = data.CO_FIRSTNAME,
					obj.CO_LASTNAME = data.CO_LASTNAME,
					obj.CO_COMPANY = data.CO_COMPANY
					break;
				}
			}
			return true;
		}
		
		function addToList() {
			onSortChange([{"type": "desc", "field": "CO_ID"}]);
			loadItems();
		}
		
		function removeFromList(id) {
			rows.value.splice(rows.value.findIndex(v => v.CO_ID === id), 1);
		}
		
		return {
			rows,
			totalRecords,
			columns,
			updateList,
			addToList,
			removeFromList,
			cancelledClassFunc,
			onColumnFilter,
			onSortChange,
			onPerPageChange,
			onPageChange
		}
		
	}
}

</script>